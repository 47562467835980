import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import Multiselect from 'multiselect-react-dropdown';
import { ajaxFail, showConfirmMessage, showErrorMessage, showSuccessMessage,removeTurkcLow } from '../GlobalState.js';

export default function BasvuruFormuSetting(props) {
    var postdata = {};
    const [listData, setListData] = useState(null);
    const [basvuruTurleri, setbasvuruTurleri] = useState([]);
    const [roles, setRoles] = useState([]);

    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (target.checkValidity() == false) {
            showErrorMessage("Sadece harf veya rakam girilebilir");
            target.value = rec[name];
            return;
        }
        rec[name] = value;
        if (target.name == "name") {
            document.getElementById("saveallbutton").disabled = true;
        }
    }

    const multiselectRefNew = React.createRef();
    const multiselectRefNewRole = React.createRef();


    const fetchRoles = () => {
        fetch("getrolelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setRoles(value)
                })
            }
        });

    }



    const removeRecord = (id) => {
        showConfirmMessage(id + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removeAppField", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response);
                }
                else {
                    response.json().then(value => {
                        setListData(value);
                    })
                }
            }));
    }



    const submitUpdateRecord = (rec) => {

        fetch("updateAppField", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                showSuccessMessage("Güncelleme Başarılı")
                document.getElementById("saveallbutton").disabled = false;
            }
        });
    }

    const fetchList = () => {
        fetch("appfieldlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }

    const saveAll = () => {

        fetch("saveallappsetting", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(listData)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                showSuccessMessage("Güncenlleme Başarılı");
            }
        });

    }

    const Add = () => {


        fetch("addAppField", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                document.getElementById("name").value = "";
                document.getElementById("type").value = "";
                document.getElementById("initValue").value = "";
                document.getElementById("gizli").checked = false;
                document.getElementById("printable").checked = false;
                document.getElementById("reqired").checked = false;
                document.getElementById("active").checked = false;
                multiselectRefNew.current.resetSelectedValues();
                multiselectRefNewRole.current.resetSelectedValues();
                fetchList();

            }
        });

    }

    const getAppTypes = () => {
        fetch("denetimlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {

                    setbasvuruTurleri(value);
                    //var i = 0;
                    //var c = document.getElementById("basvurutipleri");
                    //c.innerHTML = "";

                    //for (i = 0; i < value.length; i++) {
                    //    if (value[i].active == true) {
                    //        var opt = document.createElement('option');
                    //        opt.value = value[i].name;
                    //        opt.innerHTML = value[i].name;
                    //        if (applicationdata.basvuruTipi == value[i].name)
                    //            opt.selected = true;
                    //        c.appendChild(opt);

                    //    }
                    //    if (applicationdata.basvuruTipi == null) c.selectedIndex = -1;

                    fetchList();

                })
            }
        });
    }



    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        if (target.checkValidity() == false) {
            showErrorMessage("Sadece harf veya rakam girilebilir");

            target.value = "";
            return;
        }
        postdata[target.name] = value;



    }

    const onSelect = (selectedList, selectedItem) => {
        var clist = selectedList.join();
        postdata["basvuruTipi"] = clist;
    }
    const onRemove = (selectedList, removedItem) => {
        var clist = selectedList.join();
        postdata["basvuruTipi"] = clist;
    }

    const filterTable = (event) => {
        var textbox = document.getElementById("mySearchInput")
        if (textbox == null)
            return;
        var value = textbox.value;

        $("#myTable tr").filter(function () {
            var rowtext = $(this).children().eq(0).text();
            var t1 = removeTurkcLow(rowtext);
            var t2 = removeTurkcLow(value);
            if (t1.indexOf(t2) == -1)
                return $(this).toggle(false);

            return $(this).toggle(true);
        });
        
    }


    const renderTable = (tabledata) => {
        var mytable =
            <div className="table-responsive mainpagescroll" style={{ "height": "85vh" }}>
                <table className='table table-vdstriped  table-sm' aria-labelledby="tabelLabel">
                    <thead className="text-center" style={{ "position": "sticky", "top": 0, "z-index": "1" }} className="bg-light ">
                        <tr>
                            <th width="2%">ID</th>
                            <th width="23%">Başvuru Türü </th>
                            <th width="20%">Soru</th>

                            <th width="15%">Soru Türü</th>

                            <th width="15%">Varsayılan Değer</th>
                            <th width="7%">Sıra</th>

                            <th width="2%">Zorunlu</th>
                            <th width="2%">Aktif</th>
                            <th width="2%">Çıktı</th>
                            <th width="2%">Gizli</th>
                            <th width="5%">Edit Yet</th>
                            <th width="5%"></th>
                            {/*<th>Ekleyen</th>*/}

                        </tr>
                    </thead>
                    <tbody id="myTable">
                        {tabledata.map(apprec =>
                            <tr key={apprec.id}>
                                <td hidden>
                                    {apprec.basvuruTipi} {apprec.name}
                                </td>
                                <td >{apprec.id}</td>
                                <td >
                                    <Multiselect className="p-0"
                                        options={basvuruTurleri.map(b => b.name)}
                                        emptyRecordMsg="Başka Başvuru Tipi Yok"

                                        isObject={false}
                                        onSelect={(selectedList, selectedItem) => { apprec.basvuruTipi = selectedList.join() }}
                                        onRemove={(selectedList, selectedItem) => { apprec.basvuruTipi = selectedList.join() }}
                                        placeholder=""
                                        selectedValues={apprec.basvuruTipi != null ? apprec.basvuruTipi.split(',') : []}
                                    />
                                </td>
                                <td>
                                    <input type="text" className="form-control form-control-sm" pattern="[a-zA-Z0-9ÜüŞşÇçÖöĞğİı\(\)_\- ]+" placeholder="Soru Adı" name="name" defaultValue={apprec.name} onChange={(e) => updateRecord(apprec, e)} />
                                </td>
                                <td>
                                    <select style={{ "background-color": "transparent" }} className="form-select form-select-sm" name="type" placeholder="Soru Türü" defaultValue={apprec.type} onChange={(e) => updateRecord(apprec, e)} >
                                        <option value="text">Yazı</option>

                                        <option value="select">Çoktan Seçmeli</option>
                                        <option value="checkbox">Checkbox</option>
                                        <option value="number">Sayı</option>
                                        <option value="select multiple">Çoklu Seçim</option>
                                        <option value="date">Tarih</option>
                                        <option value="textarea">Buyuk Yazı</option>
                                    </select>
                                </td>

                                <td>
                                    <input type="text" style={{ "background-color": "transparent" }} className="form-control form-control-sm" placeholder="Varsayılan Değer" name="initValue" defaultValue={apprec.initValue} onChange={(e) => updateRecord(apprec, e)} />
                                </td>
                                <td>
                                    <input type="number" style={{ "background-color": "transparent" }} className="form-control form-control-sm" placeholder="Sıra" name="sira" defaultValue={apprec.sira} onChange={(e) => updateRecord(apprec, e)} />
                                </td>
                                <td>
                                    <input type="checkbox" className="form-check-input" name="reqired" defaultChecked={apprec.reqired} onChange={(e) => updateRecord(apprec, e)} />
                                </td>
                                <td>
                                    <input type="checkbox" className="form-check-input" name="active" defaultChecked={apprec.active} onChange={(e) => updateRecord(apprec, e)} />
                                </td>
                                <td>
                                    <input type="checkbox" className="form-check-input" name="printable" defaultChecked={apprec.printable} onChange={(e) => updateRecord(apprec, e)} />
                                </td>
                                <td>
                                    <input type="checkbox" className="form-check-input" name="gizli" defaultChecked={apprec.gizli} onChange={(e) => updateRecord(apprec, e)} />
                                </td>
                                <td>
                                    <Multiselect className="p-0"
                                        options={roles}
                                        placeholder="Rol"
                                        emptyRecordMsg="Başka Kayıt Yok"
                                        displayValue="name"
                                        isObject={true}
                                        selectedValues={apprec.role != null ? apprec.role.split(',').filter(r => roles.find(x => x.id == r) != null).map(r => roles.find(x => x.id == r)) : []}
                                        onSelect={(selectedList, removedItem) => { apprec.role = selectedList.length == 0 ? null : selectedList.map(rec => rec.id).join() }} // Function will trigger on select event
                                        onRemove={(selectedList, removedItem) => { apprec.role = selectedList.length == 0 ? null : selectedList.map(rec => rec.id).join() }} // Function will trigger on remove event
                                    />
                                </td>
                                {/* <td>{apprec.crerateTime}</td>*/}

                                <td>
                                    <button onClick={() => submitUpdateRecord(apprec)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Güncelle</button>&nbsp;
                                    <button onClick={() => removeRecord(apprec.id)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Sil</button>
                                </td>
                            </tr>
                        )}
                        <tr className="bg-white">
                            <td> </td>
                            <td >

                                <Multiselect className="p-0 form-control-sm bg-white"
                                    options={basvuruTurleri.map(b => b.name)}
                                    emptyRecordMsg="Başka Yetki Yok"
                                    ref={multiselectRefNew}
                                    isObject={false}
                                    onSelect={onSelect}
                                    onRemove={onRemove}
                                    placeholder="Başvuru Tipleri"
                                    selectedValues={[]}
                                /></td>
                            <td>
                                <input type="text" style={{ "background-color": "transparent" }} className="form-control form-control-sm" id="name" pattern="[a-zA-Z0-9ÜüŞşÇçÖöĞğİı\(\)_ ]+" placeholder="Soru" name="name" onChange={handleInputChange} defaultValue="" />
                            </td>
                            <td>
                                <select className="form-select form-select-sm" id="type" name="type" placeholder="Soru Türü" defaultValue="" onChange={handleInputChange} >
                                    <option value="text">Yazı</option>

                                    <option value="select">Çoktan Seçmeli</option>
                                    <option value="checkbox">Checkbox</option>
                                    <option value="number">Sayı</option>
                                    <option value="select multiple">Çoklu Seçim</option>
                                    <option value="date">Tarih</option>
                                    <option value="textarea">Buyuk Yazı</option>
                                </select>

                            </td>
                            <td>
                                <input type="text" style={{ "background-color": "transparent" }} className="form-control form-control-sm" placeholder="Varsayılan Değer" name="initValue" id="initValue" defaultValue="" onChange={handleInputChange} />
                            </td>
                            <td>
                                <input type="text" style={{ "background-color": "transparent" }} className="form-control form-control-sm" placeholder="Sıra" name="sira" id="sira" defaultValue="" onChange={handleInputChange} />
                            </td>
                            <td>
                                <input type="checkbox" className="form-check-input" id="reqired" name="reqired" onChange={handleInputChange} />
                            </td>
                            <td>
                                <input type="checkbox" className="form-check-input" id="active" name="active" onChange={handleInputChange} />
                            </td>
                            <td> <input type="checkbox" className="form-check-input" id="printable" name="printable" onChange={handleInputChange} /></td>
                            <td> <input type="checkbox" className="form-check-input" id="gizli" name="gizli" onChange={handleInputChange} /></td>

                            <td>
                                <Multiselect className=" p-0 form-control form-control-sm "
                                    options={roles}
                                    placeholder="Rol"
                                    emptyRecordMsg="Başka Kayıt Yok"
                                    displayValue="name"
                                    isObject={true}
                                    ref={multiselectRefNewRole}
                                    selectedValues={[]}
                                    onSelect={(selectedList, removedItem) => { postdata.role = selectedList.length == 0 ? null : selectedList.map(rec => rec.id).join() }} // Function will trigger on select event
                                    onRemove={(selectedList, removedItem) => { postdata.role = selectedList.length == 0 ? null : selectedList.map(rec => rec.id).join() }} // Function will trigger on remove event
                                />
                            </td>
                            {/*  <td></td>*/}

                            <td>  <button onClick={Add} className="btn btn-sm btn-link text-decoration-none m-0 p-0">Ekle</button></td>
                        </tr>
                        <tr>
                            <td></td> <td></td> <td></td> <td></td> <td></td> <td></td><td></td>
                            <td colspan="5" className="text-end"> <button onClick={() => saveAll()} className="btn btn-primary btn-sm m-1" id="saveallbutton">Tümünü Kaydet</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        return (mytable);
    }





    useEffect(() => {
        fetchRoles();
        getAppTypes();
    }, [])


    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }
    else
        listTable = renderTable([]);

    return (
        <div >
            <div className="row mb-2">
                <div className="col-4">
                    <h5>Başvuru Formu</h5>
                </div>
                <div className="col-4">
                    <input className="form-control form-control-sm" id="mySearchInput" type="text" placeholder="Arama" onKeyUp={filterTable} />
                </div>
            </div>



            <div className="row">
                <div className="col-12">
                    {listTable}
                </div>
            </div>


        </div>

    );


}