
import React, { useState, useEffect } from 'react';
import UserLabel from '../User/UserLabel.js';
import Multiselect from 'multiselect-react-dropdown';
import * as UserPermConstants from '../User/UserPermConstants';
import { ajaxFail, showConfirmMessage, showSuccessMessage, removeTurkcLow, isEmpty, showErrorMessage } from '../GlobalState.js';
import $ from 'jquery';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'

export default function SettingRoles(props) {


    const [listData, setListData] = useState(null);
    const [aciklama, setAciklamaData] = useState([]);

    const [postdata, setpostdata] = useState({});

    const [popover, setpopover] = useState({});



    useEffect(() => {
        fetchList();
        getyetkiaciklamalist();


    }, [])





    const multiselectRefNew = React.createRef();

    const multiselectRef = React.createRef();

    const removeRecord = (id) => {
        showConfirmMessage(id + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removerole", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(id)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response)
                }
                else {
                    response.json().then(value => {
                        setListData(value);
                    })
                }
            }))
    }



    const submitUpdateRecord = (rec) => {
        if (isEmpty(postdata.name)) {
            showErrorMessage("Rol ismi boş olamaz.");
            return;
        }
        fetch("updaterole", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                setpostdata({})
                fetchList();
                showSuccessMessage("Güncelleme Başarıyla Tamamlanmştır")
            }
        });
    }

    const getyetkiaciklamalist = () => {

        fetch("getyetkiaciklamalist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setAciklamaData(value);
                    setTimeout(() => {
                        var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
                        var popoverList = popoverTriggerList.filter(x => isEmpty(x.dataset.bsContent)==false).map(function (popoverTriggerEl) {
                            return new bootstrap.Popover(popoverTriggerEl)
                        })
                    }, 300);

                })
            }
        });
    }


    const getAciklama = (yetki) => {
        var a = aciklama.find(x => x.yetki == yetki);
        if (a != null)
            return a.aciklama;
        return "";
    }

    const fetchList = () => {

        fetch("getrolelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }

    const Add = (rec) => {
      
        fetch("addrole", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                fetchList();

            }
        });

    }

    const scrollToBottom = () => {
        const element = document.getElementById("activepaneldiv");
        // element.scrollTo(0, element.scrollHeight);
        element.scrollTop = element.scrollHeight

    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' || target.type === 'radio' ? target.checked : target.value;
        const name = target.name;
        postdata[name] = value;
    }

    const handleCheckBoxChange = (event) => {
        var perms = [];
        $('#checkboxarea').find(':checkbox:checked').each(function () {
            if ($(this).is(':checked') == true)
                perms.push($(this).attr('name'));

        });

        setpostdata({ ...postdata, perms: perms.join(",") })
    }

    const onSelect = (selectedList, selectedItem) => {
        var clist = selectedList.join();
        postdata["perms"] = clist;
    }
    const onRemove = (selectedList, removedItem) => {
        var clist = selectedList.join();
        postdata["perms"] = clist;
    }
    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' || target.type === 'radio' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }
    const hasperm = (p) => {
        if (postdata == null || postdata.perms == null)
            return false;
        if (postdata.perms == p)
            return true;
        if (postdata.perms.includes("," + p + ",") || postdata.perms.endsWith("," + p) || postdata.perms.startsWith(p + ","))
            return true;

        return false;
    }
    const checkboxlist = () => {
        return <div className="row p-2  align-middle" id="checkboxarea" key={aciklama.length}>

        

            {/*--------------------------------------------------------------------------------------------*/}

            <div className="col-md-4 col-12 align-middle m-0 " >
                <div className="fw-bold text-center pb-1" >
                    Başvuru Yetkileri
                </div>

                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1 " name={UserPermConstants.Basvuru_Liste} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Basvuru_Liste)} />
                    <label className={"form-check-label pt-1 d-inline " + (hasperm(UserPermConstants.Basvuru_Liste) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Basvuru_Liste")} key={aciklama.length}>
                        {UserPermConstants.Basvuru_Liste}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Gorebildigi_Basvurulari_Editleme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Gorebildigi_Basvurulari_Editleme)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Gorebildigi_Basvurulari_Editleme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Gorebildigi_Basvurulari_Editleme")} key={aciklama.length}>
                        {UserPermConstants.Gorebildigi_Basvurulari_Editleme}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1 " name={UserPermConstants.Tum_Geciken_Basvurular} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Tum_Geciken_Basvurular)} />
                    <label className={"form-check-label pt-1 d-inline " + (hasperm(UserPermConstants.Tum_Geciken_Basvurular) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Tum_Geciken_Basvurular")} key={aciklama.length}>
                        {UserPermConstants.Tum_Geciken_Basvurular}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Gizli_Basvuru_Gorme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Gizli_Basvuru_Gorme)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Gizli_Basvuru_Gorme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Gizli_Basvuru_Gorme")} key={aciklama.length}>
                        {UserPermConstants.Gizli_Basvuru_Gorme}
                    </label>
                </div>

                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Ilgili_Basvurulari_Gorme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Ilgili_Basvurulari_Gorme)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Ilgili_Basvurulari_Gorme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Ilgili_Basvurulari_Gorme")} key={aciklama.length}>
                        {UserPermConstants.Ilgili_Basvurulari_Gorme}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Kalan_Sure_Gorme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Kalan_Sure_Gorme)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Kalan_Sure_Gorme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Gizli_Alanlari_Gorme")} key={aciklama.length}>
                        {UserPermConstants.Kalan_Sure_Gorme}
                    </label>
                </div>
                <div className="pt-1 checkbox" >

                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Gizli_Alanlari_Gorme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Gizli_Alanlari_Gorme)} />
                    <label className={"form-check-label pt-1 d-inline " + (hasperm(UserPermConstants.Gizli_Alanlari_Gorme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Gizli_Alanlari_Gorme")} key={aciklama.length}>
                        {UserPermConstants.Gizli_Alanlari_Gorme}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Basvurudan_Sonra_Dinamik_Editleme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Basvurudan_Sonra_Dinamik_Editleme)} />
                    <label className={"form-check-label pt-1 d-inline " + (hasperm(UserPermConstants.Basvurudan_Sonra_Dinamik_Editleme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Basvurudan_Sonra_Dinamik_Editleme")} key={aciklama.length}>
                        {UserPermConstants.Basvurudan_Sonra_Dinamik_Editleme}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Basvurudan_Sonra_Sabit_Editleme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Basvurudan_Sonra_Sabit_Editleme)} />
                    <label className={"form-check-label pt-1 d-inline " + (hasperm(UserPermConstants.Basvurudan_Sonra_Sabit_Editleme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Basvurudan_Sonra_Sabit_Editleme")} key={aciklama.length}>
                        {UserPermConstants.Basvurudan_Sonra_Sabit_Editleme}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Eksiklik_Girme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Eksiklik_Girme)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Eksiklik_Girme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Eksiklik_Girme")} key={aciklama.length}>
                        {UserPermConstants.Eksiklik_Girme}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Basvuru_Vekili_Update} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Basvuru_Vekili_Update)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Basvuru_Vekili_Update) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Basvuru_Vekili_Update")} key={aciklama.length}>
                        {UserPermConstants.Basvuru_Vekili_Update}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Is_Yuku} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Is_Yuku)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Is_Yuku) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Is_Yuku")} key={aciklama.length}>
                        {UserPermConstants.Is_Yuku}
                    </label>
                </div>

                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.BASKA_BASVURU_GONDERME} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.BASKA_BASVURU_GONDERME)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.BASKA_BASVURU_GONDERME) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("BASKA_BASVURU_GONDERME")} key={aciklama.length}>
                        Başkasına Ait Başvuruyu Gönderme
                    </label>
                </div>

                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Basvuru_Arsiv} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Basvuru_Arsiv)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Basvuru_Arsiv) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Basvuru_Arsiv")} key={aciklama.length}>
                        {UserPermConstants.Basvuru_Arsiv}
                    </label>
                </div>

                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Basvuru_Arsiv_Cikar} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Basvuru_Arsiv_Cikar)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Basvuru_Arsiv_Cikar) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Basvuru_Arsiv_Cikar")} key={aciklama.length}>
                        {UserPermConstants.Basvuru_Arsiv_Cikar}
                    </label>
                </div>

            </div>



            {/*--------------------------------------------------------------------------------------------*/}

            <div className="col-md-4 col-12 align-middle m-0" >
                <div className="fw-bold text-center pb-1" >
                    İşlem Yetkileri
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1 " name={UserPermConstants.Yeni_Basvuru_Yapabilme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Yeni_Basvuru_Yapabilme)} />
                    <label className={"form-check-label pt-1 d-inline " + (hasperm(UserPermConstants.Yeni_Basvuru_Yapabilme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Yeni_Basvuru_Yapabilme")} key={aciklama.length}>
                        {UserPermConstants.Yeni_Basvuru_Yapabilme}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1 " name={UserPermConstants.Yeni_Basvuruda_Zorunlu_Alanlari_Bos_Birakabilme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Yeni_Basvuruda_Zorunlu_Alanlari_Bos_Birakabilme)} />
                    <label className={"form-check-label pt-1 d-inline " + (hasperm(UserPermConstants.Yeni_Basvuruda_Zorunlu_Alanlari_Bos_Birakabilme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Yeni_Basvuruda_Zorunlu_Alanlari_Bos_Birakabilme")} key={aciklama.length}>
                        {UserPermConstants.Yeni_Basvuruda_Zorunlu_Alanlari_Bos_Birakabilme}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Edevletsiz_Basvuru} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Edevletsiz_Basvuru)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Edevletsiz_Basvuru) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Edevletsiz_Basvuru")} key={aciklama.length}>
                        {UserPermConstants.Edevletsiz_Basvuru}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.EImzali_Gonderme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.EImzali_Gonderme)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.EImzali_Gonderme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("EImzali_Gonderme")} key={aciklama.length}>
                        {UserPermConstants.EImzali_Gonderme}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Iade_Basvuruyu_Gonderme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Iade_Basvuruyu_Gonderme)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Iade_Basvuruyu_Gonderme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Iade_Basvuruyu_Gonderme")} key={aciklama.length}>
                        {UserPermConstants.Iade_Basvuruyu_Gonderme}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Manuel_Bildirim_Gonderme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Manuel_Bildirim_Gonderme)} />
                    <label className={"form-check-label pt-1  " + (hasperm(UserPermConstants.Manuel_Bildirim_Gonderme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Manuel_Bildirim_Gonderme")} key={aciklama.length}>
                        {UserPermConstants.Manuel_Bildirim_Gonderme}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Kullaniciyla_Kart_Paylasimi} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Kullaniciyla_Kart_Paylasimi)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Kullaniciyla_Kart_Paylasimi) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Kullaniciyla_Kart_Paylasimi")} key={aciklama.length}>
                        {UserPermConstants.Kullaniciyla_Kart_Paylasimi}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Link_ile_Kart_Paylasimi} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Link_ile_Kart_Paylasimi)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Link_ile_Kart_Paylasimi) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Link_ile_Kart_Paylasimi")} key={aciklama.length}>
                        {UserPermConstants.Link_ile_Kart_Paylasimi}
                    </label>
                </div>

                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Basvuru_Silme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Basvuru_Silme)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Basvuru_Silme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Basvuru_Silme")} key={aciklama.length}>
                        {UserPermConstants.Basvuru_Silme}
                    </label>
                </div>
                <div className="pt-1 checkbox" >

                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Basvuru_Onaylama} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Basvuru_Onaylama)} />
                    <label className={"form-check-label pt-1 d-inline " + (hasperm(UserPermConstants.Basvuru_Onaylama) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Basvuru_Onaylama")} key={aciklama.length}>
                        {UserPermConstants.Basvuru_Onaylama}
                    </label>
                </div>

                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Basvuru_Onaylama_Kontrolorden_Sonraki_Biti_Onayi} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Basvuru_Onaylama_Kontrolorden_Sonraki_Biti_Onayi)} />
                    <label className={"form-check-label pt-1 d-inline " + (hasperm(UserPermConstants.Basvuru_Onaylama_Kontrolorden_Sonraki_Biti_Onayi) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Basvuru_Onaylama_Kontrolorden_Sonraki_Biti_Onayi")} key={aciklama.length}>
                        {UserPermConstants.Basvuru_Onaylama_Kontrolorden_Sonraki_Biti_Onayi}
                    </label>
                </div>
                <div className="pt-1 checkbox" >

                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Havale_Yapma} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Havale_Yapma)} />
                    <label className={"form-check-label pt-1  " + (hasperm(UserPermConstants.Havale_Yapma) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Havale_Yapma")} key={aciklama.length}>
                        {UserPermConstants.Havale_Yapma}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Ruhsat_Kesme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Ruhsat_Kesme)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Ruhsat_Kesme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Ruhsat_Kesme")} key={aciklama.length}>
                        {UserPermConstants.Ruhsat_Kesme}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Ruhsat_Onaylama} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Ruhsat_Onaylama)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Ruhsat_Onaylama) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Ruhsat_Onaylama")} key={aciklama.length}>
                        {UserPermConstants.Ruhsat_Onaylama}
                    </label>
                </div>

                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Adres_Defterini_Duzenleme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Adres_Defterini_Duzenleme)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Adres_Defterini_Duzenleme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Adres_Defterini_Duzenleme")} key={aciklama.length}>
                        {UserPermConstants.Adres_Defterini_Duzenleme}
                    </label>
                </div>
                <div className="pt-1 checkbox" >

                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Adres_Defterini_Gorme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Adres_Defterini_Gorme)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Adres_Defterini_Gorme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Adres_Defterini_Gorme")} key={aciklama.length}>
                        {UserPermConstants.Adres_Defterini_Gorme}
                    </label>
                </div>

                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.MAKS_Veri_Girisi} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.MAKS_Veri_Girisi)} />
                    <label className={"form-check-label pt-1  " + (hasperm(UserPermConstants.MAKS_Veri_Girisi) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("MAKS_Veri_Girisi")} key={aciklama.length}>
                        {UserPermConstants.MAKS_Veri_Girisi}
                    </label>
                </div>

                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Gizli_Aktiviteleri_Gorme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Gizli_Aktiviteleri_Gorme)} />
                    <label className={"form-check-label pt-1  " + (hasperm(UserPermConstants.Gizli_Aktiviteleri_Gorme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Gizli_Aktiviteleri_Gorme")} key={aciklama.length}>
                        {UserPermConstants.Gizli_Aktiviteleri_Gorme}
                    </label>
                </div>
            </div>

            {/*--------------------------------------------------------------------------------------------*/}

            <div className="col-md-4 col-12 align-middle m-0" >
                <div className="fw-bold text-center pb-1" >
                    Dosya ve İmza Yetkileri
                </div>
                <div className="pt-1 checkbox" >

                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Dosya_Yukleme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Dosya_Yukleme)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Dosya_Yukleme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Dosya_Yukleme")} key={aciklama.length}>
                        {UserPermConstants.Dosya_Yukleme}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Dosya_Gizleme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Dosya_Gizleme)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Dosya_Gizleme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Dosya_Gizleme")} key={aciklama.length}>
                        {UserPermConstants.Dosya_Gizleme}
                    </label>
                </div>

                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Dosya_Degistirme_Silme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Dosya_Degistirme_Silme)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Dosya_Degistirme_Silme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Dosya_Degistirme_Silme")} key={aciklama.length}>
                        {UserPermConstants.Dosya_Degistirme_Silme}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Kontrolor_Dosyasi_Yukleme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Kontrolor_Dosyasi_Yukleme)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Kontrolor_Dosyasi_Yukleme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Kontrolor_Dosyasi_Yukleme")} key={aciklama.length}>
                        {UserPermConstants.Kontrolor_Dosyasi_Yukleme}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Imzaya_Açma} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Imzaya_Açma)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Imzaya_Açma) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Imzaya_Açma")} key={aciklama.length}>
                        {UserPermConstants.Imzaya_Açma}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1 " name={UserPermConstants.Dosyalari_Eimzaya_Acima} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Dosyalari_Eimzaya_Acima)} />
                    <label className={"form-check-label pt-1 d-inline " + (hasperm(UserPermConstants.Dosyalari_Eimzaya_Acima) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Dosyalari_Eimzaya_Acima")} key={aciklama.length}>
                        {UserPermConstants.Dosyalari_Eimzaya_Acima}
                    </label>
                </div>

                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Imza_Guncelleme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Imza_Guncelleme)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Imza_Guncelleme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Imza_Guncelleme")} key={aciklama.length}>
                        {UserPermConstants.Imza_Guncelleme}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Imza_Silme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Imza_Silme)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Imza_Silme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Imza_Silme")} key={aciklama.length}>
                        {UserPermConstants.Imza_Silme}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Imza_Veri_Girisi} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Imza_Veri_Girisi)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Imza_Veri_Girisi) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Imza_Veri_Girisi")} key={aciklama.length}>
                        {UserPermConstants.Imza_Veri_Girisi}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Imza_Vize_Kontrol} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Imza_Vize_Kontrol)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Imza_Vize_Kontrol) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Imza_Vize_Kontrol")} key={aciklama.length}>
                        {UserPermConstants.Imza_Vize_Kontrol}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Imza_Yerlestirme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Imza_Yerlestirme)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Imza_Yerlestirme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Imza_Yerlestirme")} key={aciklama.length}>
                        {UserPermConstants.Imza_Yerlestirme}
                    </label>
                </div>

                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1 " name={UserPermConstants.Vekalet_Onay} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Vekalet_Onay)} />
                    <label className={"form-check-label pt-1  " + (hasperm(UserPermConstants.Vekalet_Onay) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Vekalet_Onay")} key={aciklama.length}>
                        {UserPermConstants.Vekalet_Onay}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1 " name={UserPermConstants.Imza_Sertifikali} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Imza_Sertifikali)} />
                    <label className={"form-check-label pt-1  " + (hasperm(UserPermConstants.Imza_Sertifikali) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Imza_Sertifikali")} key={aciklama.length}>
                        {UserPermConstants.Imza_Sertifikali}
                    </label>
                </div>

                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1 " name={UserPermConstants.Eimza_Geri_Alma} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Eimza_Geri_Alma)} />
                    <label className={"form-check-label pt-1  " + (hasperm(UserPermConstants.Eimza_Geri_Alma) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Eimza_Geri_Alma")} key={aciklama.length}>
                        {UserPermConstants.Eimza_Geri_Alma}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1 " name={UserPermConstants.HARC_DOSYALARI} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.HARC_DOSYALARI)} />
                    <label className={"form-check-label pt-1  " + (hasperm(UserPermConstants.HARC_DOSYALARI) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("HARC_DOSYALARI")} key={aciklama.length}>
                        {UserPermConstants.HARC_DOSYALARI}
                    </label>
                </div>



               

                {/*<div className="pt-1 checkbox" >*/}
                {/*    <input type="checkbox" className="form-check-input big-checkbox me-1 " name={UserPermConstants.Son_Imza} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Son_Imza)} />*/}
                {/*    <label className={"form-check-label pt-1 d-inline " + (hasperm(UserPermConstants.Son_Imza) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Son_Imza")} key={aciklama.length}>*/}
                {/*        {UserPermConstants.Son_Imza}*/}
                {/*    </label>*/}
                {/*</div>*/}
            </div>

            {/*--------------------------------------------------------------------------------------------*/}

            <div className="col-md-4 col-12 align-middle mt-5" >
                <div className="fw-bold text-center pb-1" >
                    Yönetici Yetkileri (Başvuru)
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Basvuru_Formu} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Basvuru_Formu)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Basvuru_Formu) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Basvuru_Formu")} key={aciklama.length}>
                        {UserPermConstants.Basvuru_Formu}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Dosyalar} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Dosyalar)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Dosyalar) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Dosyalar")} key={aciklama.length}>
                        {UserPermConstants.Dosyalar}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Ekran_Yonetimi} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Ekran_Yonetimi)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Ekran_Yonetimi) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Ekran_Yonetimi")} key={aciklama.length}>
                        {UserPermConstants.Ekran_Yonetimi}
                    </label>
                </div>


                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Kadastral_Ayarlar} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Kadastral_Ayarlar)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Kadastral_Ayarlar) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Kadastral_Ayarlar")} key={aciklama.length}>
                        {UserPermConstants.Kadastral_Ayarlar}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Surecler} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Surecler)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Surecler) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Surecler")} key={aciklama.length}>
                        {UserPermConstants.Surecler}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Yardim_Ekranlari} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Yardim_Ekranlari)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Yardim_Ekranlari) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Yardim_Ekranlari")} key={aciklama.length}>
                        {UserPermConstants.Yardim_Ekranlari}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Duyuru_Yonetimi} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Duyuru_Yonetimi)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Duyuru_Yonetimi) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Duyuru_Yonetimi")} key={aciklama.length}>
                        {UserPermConstants.Duyuru_Yonetimi}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Pop_Up_Yonetimi} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Pop_Up_Yonetimi)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Pop_Up_Yonetimi) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Pop_Up_Yonetimi")} key={aciklama.length}>
                        {UserPermConstants.Pop_Up_Yonetimi}
                    </label>
                </div>

                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Tarife_Cetveli} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Tarife_Cetveli)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Tarife_Cetveli) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Tarife_Cetveli")} key={aciklama.length}>
                        {UserPermConstants.Tarife_Cetveli}
                    </label>
                </div>

                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Yonetmelik_Yukleme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Yonetmelik_Yukleme)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Yonetmelik_Yukleme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Yonetmelik_Yukleme")} key={aciklama.length}>
                        {UserPermConstants.Yonetmelik_Yukleme}
                    </label>
                </div>

                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Kurum_Bilgileri} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Kurum_Bilgileri)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Kurum_Bilgileri) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Kurum_Bilgileri")} key={aciklama.length}>
                        {UserPermConstants.Kurum_Bilgileri}
                    </label>
                </div>
                {/*<div className="pt-1 checkbox " >*/}
                {/*    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Ada_Parsel_Girisi} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Ada_Parsel_Girisi)} />*/}
                {/*    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Ada_Parsel_Girisi) ? "fw-bold" : "textvmuted")} key={aciklama.length} tabindex="0" data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Ada_Parsel_Girisi")}>*/}
                {/*        {UserPermConstants.Ada_Parsel_Girisi}*/}
                {/*    </label>*/}
                {/*</div>*/}

                {/*<div className="pt-1 checkbox" >*/}
                {/*    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Basvuru_Tipleri} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Basvuru_Tipleri)} />*/}
                {/*    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Basvuru_Tipleri) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Basvuru_Tipleri")} key={aciklama.length}>*/}
                {/*        {UserPermConstants.Basvuru_Tipleri}*/}
                {/*    </label>*/}
                {/*</div>*/}


            </div>
            {/*--------------------------------------------------------------------------------------------*/}

            <div className="col-md-4 col-12 align-middle mt-5" >
                <div className="fw-bold text-center pb-1" >
                    Yönetici Yetkileri (Kullanıcı)
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Kullanici_Rolleri} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Kullanici_Rolleri)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Kullanici_Rolleri) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Kullanici_Rolleri")} key={aciklama.length}>
                        {UserPermConstants.Kullanici_Rolleri}
                    </label>
                </div>
                {/*<div className="pt-1 checkbox" >*/}
                {/*    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Kullanici_Rollerini_Editleme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Kullanici_Rollerini_Editleme)} />*/}
                {/*    <label className={"form-check-label pt-1  " + (hasperm(UserPermConstants.Kullanici_Rollerini_Editleme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Kullanici_Rollerini_Editleme")} key={aciklama.length}>*/}
                {/*        {UserPermConstants.Kullanici_Rollerini_Editleme}*/}
                {/*    </label>*/}
                {/*</div>*/}

                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Kullanici_Listesini_Gorme_Profil_Bilgilerini} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Kullanici_Listesini_Gorme_Profil_Bilgilerini)} />
                    <label className={"form-check-label pt-1 d-inline " + (hasperm(UserPermConstants.Kullanici_Listesini_Gorme_Profil_Bilgilerini) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Kullanici_Listesini_Gorme_Profil_Bilgilerini")} key={aciklama.length}>
                        {UserPermConstants.Kullanici_Listesini_Gorme_Profil_Bilgilerini}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Kullanici_Tipleri} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Kullanici_Tipleri)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Kullanici_Tipleri) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Kullanici_Tipleri")} key={aciklama.length}>
                        {UserPermConstants.Kullanici_Tipleri}
                    </label>
                </div>
                <div className="pt-1 checkbox" >

                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Kullanici_Kayit_Formu} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Kullanici_Kayit_Formu)} />
                    <label className={"form-check-label pt-1  " + (hasperm(UserPermConstants.Kullanici_Kayit_Formu) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Kullanici_Kayit_Formu")} key={aciklama.length}>
                        {UserPermConstants.Kullanici_Kayit_Formu}
                    </label>
                </div>




                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Kullanici_Bilgilerini_Editleme_Roller_Denetim_Yetkileri_Haric} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Kullanici_Bilgilerini_Editleme_Roller_Denetim_Yetkileri_Haric)} />
                    <label className={"form-check-label pt-1 d-inline " + (hasperm(UserPermConstants.Kullanici_Bilgilerini_Editleme_Roller_Denetim_Yetkileri_Haric) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Kullanici_Bilgilerini_Editleme_Roller_Denetim_Yetkileri_Haric")} key={aciklama.length}>
                        {UserPermConstants.Kullanici_Bilgilerini_Editleme_Roller_Denetim_Yetkileri_Haric}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Kullanici_Denetim_Yetkilerini_Editleme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Kullanici_Denetim_Yetkilerini_Editleme)} />
                    <label className={"form-check-label pt-1 d-inline " + (hasperm(UserPermConstants.Kullanici_Denetim_Yetkilerini_Editleme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Kullanici_Denetim_Yetkilerini_Editleme")} key={aciklama.length}>
                        {UserPermConstants.Kullanici_Denetim_Yetkilerini_Editleme}
                    </label>
                </div>




                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Kullanici_Silme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Kullanici_Silme)} />
                    <label className={"form-check-label pt-1  " + (hasperm(UserPermConstants.Kullanici_Silme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Kullanici_Silme")} key={aciklama.length}>
                        {UserPermConstants.Kullanici_Silme}
                    </label>
                </div>


                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Kullnici_Kara_Liste} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Kullnici_Kara_Liste)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Kullnici_Kara_Liste) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Kullnici_Kara_Liste")} key={aciklama.length}>
                        {UserPermConstants.Kullnici_Kara_Liste}
                    </label>
                </div>

                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Sabitlenen_Profil_Degistirme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Sabitlenen_Profil_Degistirme)} />
                    <label className={"form-check-label pt-1 d-inline " + (hasperm(UserPermConstants.Sabitlenen_Profil_Degistirme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Sabitlenen_Profil_Degistirme")} key={aciklama.length}>
                        {UserPermConstants.Sabitlenen_Profil_Degistirme}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1 " name={UserPermConstants.Sifre_Degistirme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Sifre_Degistirme)} />
                    <label className={"form-check-label pt-1  " + (hasperm(UserPermConstants.Sifre_Degistirme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Sifre_Degistirme")} key={aciklama.length}>
                        {UserPermConstants.Sifre_Degistirme}
                    </label>
                </div>
                {/*<div className="pt-1 checkbox" >*/}
                {/*    <input type="checkbox" className="form-check-input big-checkbox me-1 " name={UserPermConstants.Yeni_Kullanici_Kaydetme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Yeni_Kullanici_Kaydetme)} />*/}
                {/*    <label className={"form-check-label pt-1  " + (hasperm(UserPermConstants.Yeni_Kullanici_Kaydetme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Yeni_Kullanici_Kaydetme")} key={aciklama.length}>*/}
                {/*        {UserPermConstants.Yeni_Kullanici_Kaydetme}*/}
                {/*    </label>*/}
                {/*</div>*/}
                {/*<div className="pt-1 checkbox" >*/}
                {/*    <input type="checkbox" className="form-check-input big-checkbox me-1 " name={UserPermConstants.Yeni_Kullanici_Kaydinda_Zorunlu_Alanlari_Bos_Birakabilme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Yeni_Kullanici_Kaydinda_Zorunlu_Alanlari_Bos_Birakabilme)} />*/}
                {/*    <label className={"form-check-label pt-1  d-inline " + (hasperm(UserPermConstants.Yeni_Kullanici_Kaydinda_Zorunlu_Alanlari_Bos_Birakabilme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Yeni_Kullanici_Kaydinda_Zorunlu_Alanlari_Bos_Birakabilme")} key={aciklama.length}>*/}
                {/*        {UserPermConstants.Yeni_Kullanici_Kaydinda_Zorunlu_Alanlari_Bos_Birakabilme}*/}
                {/*    </label>*/}
                {/*</div>*/}

            </div>


            {/*--------------------------------------------------------------------------------------------*/}

            <div className="col-md-4 col-12 align-middle mt-5" >
                <div className="fw-bold text-center pb-1" >
                    İstatistik Yetkileri
                </div>

                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Anlik_Istatistikleri_Gorme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Anlik_Istatistikleri_Gorme)} />
                    <label className={"form-check-label pt-1 d-inline " + (hasperm(UserPermConstants.Anlik_Istatistikleri_Gorme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Anlik_Istatistikleri_Gorme")} key={aciklama.length}>
                        {UserPermConstants.Anlik_Istatistikleri_Gorme}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Kontrolor_Istatistiklerini_Gorme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Kontrolor_Istatistiklerini_Gorme)} />
                    <label className={"form-check-label pt-1 d-inline " + (hasperm(UserPermConstants.Kontrolor_Istatistiklerini_Gorme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Kontrolor_Istatistiklerini_Gorme")} key={aciklama.length}>
                        {UserPermConstants.Kontrolor_Istatistiklerini_Gorme}
                    </label>
                </div>
                <div className="pt-1 checkbox" >

                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Mekansal_Istatistikleri_Gorme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Mekansal_Istatistikleri_Gorme)} />
                    <label className={"form-check-label pt-1  " + (hasperm(UserPermConstants.Mekansal_Istatistikleri_Gorme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Mekansal_Istatistikleri_Gorme")} key={aciklama.length}>
                        {UserPermConstants.Mekansal_Istatistikleri_Gorme}
                    </label>
                </div>

                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Muellif_Istatistiklerini_Gorme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Muellif_Istatistiklerini_Gorme)} />
                    <label className={"form-check-label pt-1 d-inline " + (hasperm(UserPermConstants.Muellif_Istatistiklerini_Gorme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Muellif_Istatistiklerini_Gorme")} key={aciklama.length}>
                        {UserPermConstants.Muellif_Istatistiklerini_Gorme}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Proje_Istatistiklerini_Gorme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Proje_Istatistiklerini_Gorme)} />
                    <label className={"form-check-label pt-1  " + (hasperm(UserPermConstants.Proje_Istatistiklerini_Gorme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Proje_Istatistiklerini_Gorme")} key={aciklama.length}>
                        {UserPermConstants.Proje_Istatistiklerini_Gorme}
                    </label>
                </div>
                <div className="pt-1 checkbox" >
                    <input type="checkbox" className="form-check-input big-checkbox me-1" name={UserPermConstants.Zamansal_Istatistikleri_Gorme} onChange={handleCheckBoxChange} checked={hasperm(UserPermConstants.Zamansal_Istatistikleri_Gorme)} />
                    <label className={"form-check-label pt-1 " + (hasperm(UserPermConstants.Zamansal_Istatistikleri_Gorme) ? "fw-bold" : "textvmuted")} data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content={getAciklama("Zamansal_Istatistikleri_Gorme")} key={aciklama.length}>
                        {UserPermConstants.Zamansal_Istatistikleri_Gorme}
                    </label>
                </div>
            </div>

        </div >




    }




    const renderTable = (tabledata) => {
        var mytable =
            <div className="table-responsive  mainpagescroll" >
                <table className='table table-vdstriped  table-sm' aria-labelledby="tabelLabel" >
                    <thead className="text-center" style={{ "position": "sticky", "top": 0, "z-index": "1" }}>
                        <tr>
                            {/* <th className="text-start">ID</th>*/}
                            <th className="text-start textvmuted">Rol Adı</th>
                            {/*<th style={{ "min-width": "250px" }}>Yetkiler</th>*/}
                            <th className="text-start textvmuted">Başvuru Takip</th>
                            <th className="text-start textvmuted">Varsayılan Rol</th>
                            <th className="text-start"></th>
                        </tr>
                    </thead>
                    <tbody >
                        {tabledata.map(apprec =>
                            <tr key={apprec.id}>
                                {/*<td>*/}
                                {/*    {apprec.id}*/}
                                {/*</td>*/}
                                <td>
                                    <a href="#" onClick={() => setpostdata(apprec)} className="dropdown-item text-primary text-decoration-none"> {apprec.name}</a>

                                    {/* <input type="text" className="form-control form-control-sm" name="name" defaultValue={apprec.name} onChange={(e) => updateRecord(apprec, e)} />*/}
                                </td>
                                {/*<td>*/}
                                {/*    <Multiselect className="p-0 form-control-sm bg-white"*/}
                                {/*        options={perms}*/}
                                {/*        emptyRecordMsg="Başka Kullanıcı Tipi Yok"*/}

                                {/*        isObject={false}*/}
                                {/*        onSelect={(selectedList, selectedItem) => { apprec.perms = selectedList.join() }}*/}
                                {/*        onRemove={(selectedList, selectedItem) => { apprec.perms = selectedList.join() }}*/}
                                {/*        placeholder="Yetkiler"*/}
                                {/*        selectedValues={apprec.perms != null ? apprec.perms.split(',').sort(function (a, b) {return a.localeCompare(b);}) : []}*/}
                                {/*    />*/}
                                {/*</td>*/}
                                <td >
                                    <input type="checkbox" className="form-check-input big-checkbox" disabled name="takip" onChange={(e) => updateRecord(apprec, e)} checked={apprec.takip} />
                                </td>
                                <td >
                                    <input type="radio" className="form-check-input big-checkbox" disabled name="defaultrole" onChange={(e) => updateRecord(apprec, e)} checked={apprec.defaultrole} />
                                </td>
                                <td>

                                    <div className="dropdown">
                                        <button className="btn btn-primary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            İşlemler
                                        </button>
                                        <ul class="dropdown-menu shadow-sm" aria-labelledby="dropdownMenuButton1">

                                            <li className="">
                                                <a href="#" onClick={() => setpostdata(apprec)} className="dropdown-item text-primary text-decoration-none">Güncelle</a>


                                            </li>
                                            <li className="">
                                                <a href="#" onClick={() => { Add({ name: apprec.name + "-2", perms: apprec.perms, takip: apprec.takip }); }} className="dropdown-item text-primary text-decoration-none">Kopyala</a>


                                            </li>
                                            <li>
                                                <a href="#" onClick={() => removeRecord(apprec.id)} className="dropdown-item text-danger text-decoration-none ">Sil</a>
                                            </li>

                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        )}


                    </tbody>
                </table>
            </div >
        return (mytable);
    }

    const filterTable = (event) => {
        var textbox = document.getElementById("mySearchInput")
        if (textbox == null)
            return;
        var value = textbox.value.toLowerCase();
        var keywords = value.split(" ");


        $("#checkboxarea .checkbox").filter(function () {
            var t1 = removeTurkcLow($(this).text());
            for (let index = 0; index < keywords.length; ++index) {
                var t2 =  removeTurkcLow(keywords[index]);
                if (t1.indexOf(t2) == -1) {
                    return $(this).toggle(false);
                }
            }
           return $(this).toggle(true);
        });


    }






    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }
    else
        listTable = renderTable([]);

    return (
        <div className="m-2 rounded-3" style={{ "background-color": "#EDEDF7", "min-height": "100vh" }} key={aciklama.length}>

            <div className="row d-flex  flex-wrap p-4">
                <div className="col-md-8 col-12 d-flex flex-column" >
                    <div className="bg-white rounded-3" >
                        <div className="row ms-2 me-2 pt-3  mb-4 pb-4 border-bottom">
                            <div className="col-4 align-middle fw-bold pt-4 text-center " >
                                YENİ ROL OLUŞTUR
                            </div>
                            <div className="col-8" >
                                <div className="bg-light p-3 rounded-3 textvmuted fw-bold " style={{ "text-align": "justify" }}>
                                    <span className="fa fa-info pe-3" style={{ color: "lightblue", fontSize: "26px" }} ></span>
                                    Bu ekranda istediğiniz yetkilerden seçim yaparak, yetki grupları (yeni kullanıcı rolleri) oluşturabilirsiniz.
                                </div>
                            </div>
                        </div>

                        {postdata.id > 0 && < div className="row ms-2 me-2   mb-4 pb-4 border-bottom">
                            <div className="col-12 align-middle   text-center " >
                                <span className="text-success fw-bold"> {postdata.name} </span>rölünü düzenliyorsunuz
                                <button onClick={() => {
                                    setpostdata({})


                                }} className="ms-2 btn btn-sm btn-secondary text-decoration-none">Vazgeç</button>
                            </div>
                        </div>}

                        <div className="m-2 pb-4 mb-4 mt-4 border-bottom" key={postdata.id}>
                            <div className="row pt-2  mt-3">
                                <div className="col-md-4 col-12" >
                                    <div>
                                        <input type="text" className="form-control form-control-sm" id="rolname" name="name" onChange={handleInputChange} placeholder="Rol İsmi" defaultValue={postdata.name} />
                                        <span className="textvmuted">Role vermek istediğiniz ismi yazınız.</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3 align-middle " >
                                    <input type="checkbox" className="form-check-input big-checkbox " id="takip" name="takip" onChange={handleInputChange} defaultChecked={postdata.takip} />
                                    <span className="align-middle ms-1">Başvuru Takip </span>
                                    <br />
                                    <span className="textvmuted">Başvurularla ilgili bildirim gitsin mı?</span>
                                </div>
                                <div className="col-12 col-md-3 align-middle " >
                                    <input type="checkbox" className="form-check-input big-checkbox" id="defaultrole" name="defaultrole" onChange={handleInputChange} defaultChecked={postdata.defaultrole} />
                                    <span className="align-middle ms-1"> Varsayılan Rol</span>
                                    <br />
                                    <span className="textvmuted">Yeni kullanıcıların varsayılan rolu</span>
                                </div>
                                <div className="col-12 col-md-2 align-middle p-0" >
                                    <button onClick={submitUpdateRecord} className="btn btn-sm btn-primary text-decoration-none p-1 ">Kaydet</button>&nbsp;
                                    <button onClick={() => {
                                        document.getElementById("rolname").value = "";
                                        document.getElementById("takip").checked = false;
                                        document.getElementById("defaultrole").checked = false;

                                    }} className="btn btn-sm btn-info text-decoration-none p-1">Temizle</button>
                                </div>

                            </div>
                        </div>
                        <div className="row border-top pt-3" >
                            <div className="col-12 col-md-3" >

                            </div  >
                            <div className="col-12 col-md-6" >
                                <input className="form-control form-control-sm border-secondary mb-2 " id="mySearchInput" type="text" placeholder="Ara" onKeyUp={filterTable} />
                            </div  >
                            <div className="col-12 col-md-3" >  </div  >
                        </div>

                        {checkboxlist()}



                    </div>
                </div>
                <div className="col-md-4 col-12  d-flex flex-column bg-white rounded-3 p-2" >


                    <div className="align-middle fw-bold text-start" >
                        Oluşturulan Roller
                    </div>
                    <div className="fw-bold text-justify textvmuted mb-3 mt-2" style={{ "text-align": "justify" }}>
                        Oluşturduğunuz rollerin üzerinde tıklayarak, rolu düzenleyebilir, kopyalayabilir veya silebilirsiniz.
                    </div>

                    {listTable}

                </div>
            </div>
        </div >

    );




}