import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { Link } from "react-router-dom";
import ApplicationList from './ApplicationList';
import { ajaxFail, calcBusinessDays, showErrorMessage, showSuccessMessage, sortByKey, getBolean } from '../GlobalState.js';
import { useGlobalState, isEmpty, getappdata, capitalizeWords, toUpperCaseTurkce, fvisible, fdisabled, fzorunlu } from '../GlobalState.js';
import axios from "axios";
import SharedUsersPanel from './SharedUsersPanel.js';
import Apply1 from "./Apply1.js";

import * as UserPermConstants from '../User/UserPermConstants';
import UserLabel from "../User/UserLabel.js";
import { check } from 'prettier';
import GMapLatLang from "./GMapLatLang.js";
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'


import Multiselect from 'multiselect-react-dropdown';
import { CategoryScale } from 'chart.js';

export default function AppMainData(props) {


    const surebg = ["#c9f7f5", "#e1f0ff", "#fff4de", "#ffe2e5"]
    const suretxt = ["text-info", "text-primary", "text-warning", "text-danger"]
    const [sabitsorulistData, setsabitsorulistData] = useState([]);
    const [appData, setData] = useState(props.appData != null ? props.appData : location.state.rec);
    const [mappedusers, setMappedUsers] = useState([]);
    const [userData, setUserdata] = useState(null);
    var [globalData, setGlobalData] = useGlobalState('globalData');
    const [role, setRole] = useGlobalState('role');
    const changeComponent = props.changeComponent;
    const [AdaList, setAdaList] = useState([]);
    const [MevkiList, setMevkiList] = useState([]);
    const [MahalleList, setMahalleList] = useState([]);
    const [mahalleCoordinates, setMahalleCoordinates] = useState(null);
    let location = useLocation();
    const [allDynamicFilds, setAllDynamicFilds] = React.useState();
    const [kontroller, setkontroller] = React.useState();
    const [surecList, setSurecList] = React.useState(null);
    const [harcFileList, setHarcFileList] = useState([]);
    var arsaSahibiTemp = null;

    const [apptypeList, setAppTypeList] = React.useState(props.apptypeList);
    const ref = React.createRef();
    const zorunloBosBirakma = role.indexOf(UserPermConstants.Yeni_Basvuruda_Zorunlu_Alanlari_Bos_Birakabilme) == -1;
    var disablesabitsorular = false;
    var disabledinamiksorular = false;
    var disableaddresarea = false;
    var appdynamicdata = appData.appData != null ? JSON.parse(appData.appData) : {};




    if (appData != null && appData.basvuruDurumu != UserPermConstants.Basvuru_Durumu_Taslak &&
        appData.basvuruDurumu != UserPermConstants.Basvuru_Durumu_GeriGonderildi &&
        appData.basvuruDurumu != UserPermConstants.Basvuru_Durumu_Duzeltme_Bekleniyor
    ) {
        if (role.indexOf(UserPermConstants.Basvurudan_Sonra_Sabit_Editleme) == -1)
            disablesabitsorular = true;
        if (role.indexOf(UserPermConstants.Basvurudan_Sonra_Dinamik_Editleme) == -1)
            disabledinamiksorular = true;
    }



    if (appData != null && appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Duzeltme_Bekleniyor
        && role.indexOf(UserPermConstants.Basvurudan_Sonra_Sabit_Editleme) == -1)
        disableaddresarea = true;

    if (appData != null && appData.vekaletenBasvuru == 1 && isEmpty(appData.vekilTC) == true && userData != null) {
        //this is to fill empty records in database
        appData.vekilTC = userData.tc;
        appData.vekilTel = userData.tel;
        appData.vekilName = capitalizeWords(userData.name + " " + userData.family);
    }

    useEffect(() => {


        fetchSurecList();
        fetchAppDynamicFieldList();
        fetchSabitSoruList();
        fetchIdariMahalleList();
        fetchMahalleList(appData.mevki)
        fetchMevkiList();
        fetchAdaList(appData.mevki, appData.mahalle);
        fetchMappedUsers();
        fetchUserData();
        fetchHarcFiles();
        window.scrollTo(0, 0)
    }, [props.appData])



    const fetchHarcFiles = () => {
        var c = document.getElementById("harcdosyaSpinner")
        if (c != null) {
            c.hidden = false;
        }
        fetch("harcfilelist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: appData.id
        }).then(async response => {
            if (c != null) {
                c.hidden = true;
            }
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                try {
                    response.json().then(async data => {
                        setHarcFileList(data)
                    });
                } catch (ex) {
                    console.log(ex);
                }
            }
        });
    }

    const fetchUserData = () => {
        fetch("getuser?user=" + appData.basvuruYapan, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                //ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    var userdata = JSON.parse(data);
                    setUserdata(userdata);
                });
            }
        });
    }

    const initModal = () => {
        var locModal = document.getElementById('myModal');
        var btnclosedown = document.getElementById('downclosemodal');
        if (btnclosedown != null)
            btnclosedown.addEventListener('click', (e) => {
                locModal.style.display = "none";
                locModal.classList.add("hide");
            });
    }


    const fetchCoordinates = (param) => {
        fetch("getcbscoordinates?param=" + param, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.text().then(value => {

                    setMahalleCoordinates(value)
                })
            }
        });
    }



    const fetchSabitSoruList = () => {
        fetch("getsabitsorulist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setsabitsorulistData(value);
                    if (props.setsabitsorulistData)
                        props.setsabitsorulistData(value);
                })
            }
        });
    }


    const fetchMappedUsers = (e) => {
        fetch("mappeduserstoproject", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: appData.id
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    data = JSON.parse(data);
                    setMappedUsers(data);
                });
            }
        });
    }



    const getDenetimSure = (rec) => {
        if (rec == null || apptypeList.length == 0 || mappedusers.length == 0)
            return "";

        var denetimid = rec.denetimID;
        var havaleDate = rec.mappingDate;
        var appid = rec.appID;
        var id = rec.id;



        if (appData.basvuruDurumu != UserPermConstants.Basvuru_Durumu_Kontrol_Ediliyor
            && appData.basvuruDurumu != UserPermConstants.Basvuru_Durumu_Kontrol_Sirada_Bekliyor) return "";

        var d = apptypeList.find(x => x.id == denetimid);
        if (d == null) return "";

        d = d.deadline;
        if (d == null || rec.deliverDate == null) return "";
        //if (mappedusers.some(x => x.Completed == null
        //    && x.id < id && x.appID == appid)==true) return "";


        var songonderim = rec.deliverDate.split(" ")[0];
        var h = new Date(songonderim);

        havaleDate = havaleDate.split(" ")[0];
        if (h < Date(havaleDate))
            h = new Date(havaleDate);

        var pre = mappedusers.find(x => x.id < id && x.appID == appid)
        if (pre != null && pre.Completed != null) {
            pre = pre.Completed.split(" ")[0];
            if (h < Date(pre))
                h = new Date(pre);
        }

        var diffDays = d - calcBusinessDays(new Date(Date.now()), h)
        // var diffTime = Date.now() - h
        // const diffDays = d - Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
        var i = diffDays > d * 2 / 3 ? 0 : diffDays > d / 3 ? 1 : diffDays > 0 ? 2 : 3;

        return <div className={"ps-3 pe-3 p-2 text-center fw-bold " + suretxt[i]} style={{ "background-color": surebg[i], "border-radius": 5 }}>Kalan Süre: {diffDays}</div>
    }




    const fetchIdariMahalleList = () => {
        fetch("getidarimahallelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {

                response.json().then(value => {
                    var i = 0;
                    var c = document.getElementById("IdariMahalle");
                    if (c == null)
                        return;
                    if (value.length == 0) {
                        document.getElementById("idarimahalleblock").hidden = true;
                        c.required = false;
                    }

                    c.innerHTML = "";
                    for (i = 0; i < value.length; i++) {
                        var opt = document.createElement('option');
                        opt.value = value[i].name;
                        opt.innerHTML = value[i].name;
                        if (appData.idariMahalle == value[i].name) {
                            opt.selected = true;
                        }
                        c.appendChild(opt);
                    }
                    if (appData.idariMahalle == null) c.selectedIndex = -1;


                })
            }
        });
    }



    const fetchMevkiList = () => {
        fetch("getmevkilist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                const contentType = response.headers.get("content-type");
                if (contentType == null || contentType.indexOf("application/json") == -1) {
                    showErrorMessage("Mevki bilgileri alınamadı !");
                    return;
                }
                response.json().then(value => {
                    setMevkiList(value);
                })
            }
        });
    }



    const fetchMahalleList = (mevki) => {
        var url = "getmahallelist";
        if (mevki != null)
            url = "getmahallelist?mevki=" + mevki
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                try {
                    const contentType = response.headers.get("content-type");
                    if (contentType == null || contentType.indexOf("application/json") == -1) {
                        showErrorMessage("Mahalle bilgileri alınamadı !");
                        return;
                    }
                    response.json().then(value => {
                        setMahalleList(value)

                    })
                }
                catch (ex) {
                    showErrorMessage("Mahalle bilgileri alınamadı.")
                }
            }
        });
    }


    const fetchAdaList = (mevki, mahalle) => {
        var query = "";
        if (mevki != null)
            query += "mevki=" + mevki
        if (mahalle == null)
            return;
        fetch("getadalist?" + query + "&mahalle=" + mahalle, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                const contentType = response.headers.get("content-type");
                if (contentType == null || contentType.indexOf("application/json") == -1) {
                    showErrorMessage("Ada bilgileri alınamadı !");
                    return;
                }

                response.json().then(value => {
                    var i = 0;
                    var c = document.getElementById("Adaparselistesi");
                    if (c == null)
                        return;
                    c.innerHTML = "";
                    var selected = false;
                    for (i = 0; i < value.length; i++) {
                        var opt = document.createElement('option');
                        opt.value = value[i].name;
                        opt.innerHTML = value[i].name;
                        if (appData.ada == value[i].name && selected != true) {
                            opt.selected = true;
                            selected = true;
                            fetchCoordinates("ADA:" + value[i].id)
                        }
                        c.appendChild(opt);
                    }
                    if (selected == false)
                        c.selectedIndex = -1;
                    setAdaList(value)
                })
            }
        });
    }




    const fetchAppDynamicFieldList = () => {
        fetch("getappfields", {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    setAllDynamicFilds(sortByKey(data, "sira"));
                });
                var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
                var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
                    return new bootstrap.Popover(popoverTriggerEl)
                })
            }
        });
    }




    const fetchSurecList = () => {

        fetch("sureclist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);

            }
            else {
                response.json().then(value => {
                    setSurecList(value);
                })
            }
        });
    }



    const onSubmit = (e) => {
        e.preventDefault();

        if (zorunloBosBirakma == false) {
            var multiselectinputs = allDynamicFilds.filter(k => isVisible(k) == true && k.type == "select multiple");
            var notfilled = multiselectinputs.filter(k => k.reqired == true && (isEmpty(appdynamicdata[k.name]) || isEmpty(appdynamicdata[k.name].value)))
            if (notfilled.length > 0) {
                var names = notfilled.map(k => k.name).join(",");
                showErrorMessage("Zorunlu alanlar boş bırakılamaz", names);
                return;
            }
        }




        appData.appData = JSON.stringify(appdynamicdata);

        if (appData.vekaletenBasvuru == true && appData.vekaletDosyaname == null && zorunloBosBirakma == false) {
            showErrorMessage("Vekalet dosyanızı yukleyiniz.");
            return;
        }

        fetch("saveapplication", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(appData)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async newapp => {

                    if (newapp.updateCount < 0) {
                        showErrorMessage(newapp.temp);
                        getappdata(newapp.id, setData);
 
                    }
                    else {
                        showSuccessMessage("Başvuru Bilgileri Güncellenmiştir.");
                        newapp.changed = false;
                        if (props.setData)
                            props.setData(newapp);
                        setData(newapp);  //this will remove changed property
                        updateTextAreasHeight();
                    }

                });

            }
        });
    }
    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        appData[target.name] = value;

        if (target.name == "mahalle") {
            document.getElementById("Ada").value = "";
            setData({ ...appData })
            fetchAdaList(appData.mevki, value);
        }
        if (target.name == "mevki") {
            document.getElementById("Ada").value = "";
            //document.getElementById("Mahalle").innerHTML = "";
            setData({ ...appData })
            fetchMahalleList(value);
        }
        appData.changed = true;


    }


    const handleDynamicChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        appdynamicdata[target.name] = { id: target.id, "value": value, "type": target.type };
        appData.changed = true;
    }




    const isReadOnly = (k) => {

        if (isEmpty(k.role) == false && k.role.split(',').some(x => role.indexOf("#" + x + "#") > -1) == false)
            return true;
        return false;

    }
    const isVisible = (k) => {
        if (k.gizli == true && role.indexOf(UserPermConstants.Gizli_Alanlari_Gorme) == -1)
            return false;
        if (isEmpty(k.basvuruTipi) == false && k.basvuruTipi.indexOf(appData.basvuruTipi) == -1)
            return false;
        return true;
    }


    var dynamicFields = "";
    if (allDynamicFilds != null) {
        var appDynamicData = appData.appData != null ? JSON.parse(appData.appData) : [];

        dynamicFields =
            allDynamicFilds.map(k => isVisible(k) == false ? "" :
                <div className="col-12 col-md-4">
                    <label htmlFor={k.name} className="form-label mt-3">{k.name} {k.reqired == true && zorunloBosBirakma && "*"}</label><br />
                    {k.type == "select" ?
                        <select className="form-select form-select-sm selectpicker" id={k.id} name={k.name} onChange={handleDynamicChange} disabled={isReadOnly(k)} required={k.reqired && zorunloBosBirakma}>
                            <option value="" selected={true} hidden></option>
                            {k.initValue != null && k.initValue.split(",").map(rec =>
                                <option value={rec} selected={appDynamicData[k.name] != null && appDynamicData[k.name].value == rec}>{rec}</option>)}
                        </select> :
                        k.type == "select multiple" ?
                            <Multiselect className="p-0 form-control-sm bg-white"
                                disablePreSelectedValues={isReadOnly(k)}
                                required={true}
                                options={(isReadOnly(k) || isEmpty(k.initValue)) ? [] : k.initValue.split(",")}
                                emptyRecordMsg=""
                                isObject={false}
                                onSelect={(selectedList, changedItem) => {
                                    var clist = selectedList.join();
                                    appdynamicdata[k.name] = { id: k.id, "value": clist, "type": k.type };
                                    appData.changed = true;
                                }}
                                onRemove={(selectedList, changedItem) => {
                                    var clist = selectedList.join();
                                    appdynamicdata[k.name] = { id: k.id, "value": clist, "type": k.type };
                                    appData.changed = true;
                                }}
                                placeholder=""
                                selectedValues={(appdynamicdata[k.name] == null || isEmpty(appdynamicdata[k.name].value)) ? [] : appdynamicdata[k.name].value.split(",")}
                            />
                            :
                            k.type == "textarea" ?
                                <textarea onInput={(e) => { e.target.style.height = ""; e.target.style.height = e.target.scrollHeight + 2 + "px" }} readOnly={isReadOnly(k)} type={k.type} className={k.type != "checkbox" ? "form-control form-control-sm " : "form-check-input"}
                                    id={k.id} name={k.name} key={appData.updateCount} defaultValue={appDynamicData[k.name] != null ? appDynamicData[k.name].value : k.initValue}
                                    defaultChecked={appDynamicData[k.name] != null ? appDynamicData[k.name].value : ""} onChange={handleDynamicChange} required={k.reqired} disabled={isReadOnly(k)} required={k.reqired && zorunloBosBirakma}></textarea>
                                :
                                <input readOnly={isReadOnly(k)} type={k.type} className={k.type != "checkbox" ? "form-control form-control-sm " : "form-check-input"}
                                    id={k.id} name={k.name} key={appData.updateCount} defaultValue={appDynamicData[k.name] != null ? appDynamicData[k.name].value : k.initValue}
                                    defaultChecked={appDynamicData[k.name]!=null&&getBolean(appDynamicData[k.name].value)} onChange={handleDynamicChange} required={k.reqired} disabled={isReadOnly(k)} required={k.reqired && zorunloBosBirakma} />
                    }
                </div>

            )
    }







    const getDuzeltmeKalanSure = () => {

        if (props.denetimlist == null || props.denetimlist.length == 0 || apptypeList == null)
            return null;
        var k = props.denetimlist.find(rec => rec.activeDenetim == rec.sira);
        var r = apptypeList.find(x => x.name == k.Name)
        if (r == null || r.duzeltmeDeadline == null || r.duzeltmeDeadline == "" || appData.sonDuzeltmeTalebiTarihi == null) return null;
        var d = Number(r.duzeltmeDeadline);
        var diffDays = d - calcBusinessDays(new Date(Date.now()), new Date(appData.sonDuzeltmeTalebiTarihi.split(" ")[0]))
        var i = diffDays > d * 2 / 3 ? 0 : diffDays > d / 3 ? 1 : diffDays > 0 ? 2 : 3;

        return <div className={"ms-2 ps-2 pe-2 text-center fw-bold " + suretxt[i]} style={{ "background-color": surebg[i], "border-radius": 5 }}>Kalan Süre: {diffDays}</div>
    }


    const getDurumName = (i) => {
        var r = surecList.find(element => element.id == i);
        if (r == null) return "";
        return r.name;
    }
    const validateNumberAndForwardSlash = (event) => {
        var key = event.which;
        if (key >= 48 && key <= 57 || key == 47) {
            return true;
        } else {

            event.preventDefault()
        }
    }
    const validateSelection = (event) => {
        var val = event.target.value;
        if (AdaList.some(x => x.name == val) == false) {
            showErrorMessage(val + " geçerli ada/parsel listesinde bulunmamaktadır.");
            document.getElementById("Ada").value = "";
        }

    }





    const checkEnlemBoylem = (event) => {

        if (appData.enlem == null || appData.enlem.length < 5 || appData.boylam == null || appData.boylam.length < 5) {
            showErrorMessage("Başvuru sırasında konum verisi girilmemiştir");
            event.preventDefault()
            return false;
        }
        return true;

    }


    const chengeVekaletDurum = () => {
        appData.changed = true;
        if (appData.vekaletenBasvuru == true) {
            appData.vekaletenBasvuru = false;
            arsaSahibiTemp = [appData.arsaSahibi, appData.arsaSahibiTel, appData.arsaSahibiTC];

            appData.arsaSahibi = capitalizeWords(userData.name + " " + userData.family);
            appData.arsaSahibiTC = userData.tc;
            appData.arsaSahibiTel = userData.tel;
            document.getElementById("arsaSahibi").disabled = true;
            document.getElementById("arsaSahibiTel").disabled = true;
            document.getElementById("arsaSahibiTC").disabled = true;
            document.getElementById("vekaletbuttondiv").hidden = true;
            document.getElementById("vekilbilgileridiv").hidden = true;

            document.getElementById("changevekaletbutton").innerHTML = " (Taşınmaz Sahibi) ";

            document.getElementById("vekilName").reqired = false;
            document.getElementById("vekilTC").reqired = false;
            document.getElementById("vekilTel").reqired = false;
        }
        else {
            appData.vekilName = capitalizeWords(userData.name + " " + userData.family);
            appData.vekilTC = userData.tc;
            appData.vekilTel = userData.tel;;
            appData.vekaletenBasvuru = true;

            if (arsaSahibiTemp != null) {
                appData.arsaSahibi = arsaSahibiTemp[0];
                appData.arsaSahibiTC = arsaSahibiTemp[1];
                appData.arsaSahibiTel = arsaSahibiTemp[2];
            }
            else {
                appData.arsaSahibi = "";
                appData.arsaSahibiTC = "";
                appData.arsaSahibiTel = "";
            }


            document.getElementById("arsaSahibi").disabled = true;
            document.getElementById("arsaSahibiTel").disabled = true;
            document.getElementById("arsaSahibiTC").disabled = true;
            document.getElementById("vekaletbuttondiv").hidden = true;
            document.getElementById("vekilbilgileridiv").hidden = true;
            document.getElementById("changevekaletbutton").innerHTML = " (Vekil) ";
            document.getElementById("arsaSahibi").disabled = false;
            document.getElementById("arsaSahibiTel").disabled = false;
            document.getElementById("arsaSahibiTC").disabled = false;
            document.getElementById("vekaletbuttondiv").hidden = false;
            document.getElementById("vekilbilgileridiv").hidden = false;


            document.getElementById("vekilName").reqired = zorunloBosBirakma;
            document.getElementById("vekilTC").reqired = zorunloBosBirakma;
            document.getElementById("vekilTel").reqired = zorunloBosBirakma;

            document.getElementById("vekilName").value = capitalizeWords(userData.name + " " + userData.family);
            document.getElementById("vekilTC").value = userData.tc;
            document.getElementById("vekilTel").value = userData.tel;


        }
        document.getElementById("arsaSahibi").value = appData.arsaSahibi;
        document.getElementById("arsaSahibiTel").value = appData.arsaSahibiTel;
        document.getElementById("arsaSahibiTC").value = appData.arsaSahibiTC;

    }



    if (surecList == null) {
        return <div className="row align-items-center justify-content-center" >
            <div className="col-12 text-center">
                <div className="spinner-border " ></div>
            </div>
        </div>
    }

    const updateTextAreasHeight = (event) => {
        var textareas = document.getElementsByTagName("textarea");
        if (textareas != null)
            for (let i = 0; i < textareas.length; i++) {
                textareas[i].style.height = ""; textareas[i].style.height = textareas[i].scrollHeight + 2 + "px"
            }

    }

    const SendFiles = (event) => {
        document.getElementById("fileuploadsp").hidden = false;
        var selectedFile = { file: event.target.files[0] }
        var formData = new FormData();
        formData.append("file", selectedFile.file);
        formData.append("caption", selectedFile.file.name);
        formData.append("appid", appData.id);
        axios.post(
            "submitbasvuruvekaletfile",
            formData,
            {
                headers: {
                    "Content-type": "multipart/form-data",
                },
            }
        ).then(res => {
            document.getElementById("fileuploadsp").hidden = true;
            document.getElementById("fileuploadsp").hidden = true;
            var locModal = document.getElementById('myModal');
            locModal.style.display = "none";
            locModal.classList.add("hide");
            //getappdata(appData.id, setData);
            setData({ ...appData, vekaletDosyaname: selectedFile.file.name })

        })
            .catch(err => { showErrorMessage(err); })
    }


    const handleSelectedFile = (event, filetyp) => {
        if (event.target.files[0] != null) {
            SendFiles([{ file: event.target.files[0] }]);
        }
    }



    updateTextAreasHeight();


    var googlemap = <GMapLatLang appData={appData} changeComponent={props.changeComponent} polygon={mahalleCoordinates} />

    return <div><form action="submitapplication" method="POST" onSubmit={onSubmit} className="m-0 p-0 mt-1" key={appData}>


        < div className="bg-white rounded-3 pb-4 border-bottom " >
            < div className="row align-items-center justify-content-center border-bottom" >
                <div className="col-11 mt-2 text-center pb-2 mb-3 ">
                    <span className="text-muted text-nowrap">Başvuru Durumu: </span>
                    <span className="text-info fw-bold rounded p-1 ps-3 pe-3 text-nowrap d-block d-md-inline" style={{
                        "background-color": "#c9f7f5", "white-space": "pre"
                    }}>{getDurumName(appData.basvuruDurumu)} </span>
                    {appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Ucret_Bekleniyor &&
                        <span><span className="text-danger text-nowrap ms-1"> ( {appData.ucret} TL {globalData != null && isEmpty(globalData.paymentLink) == false && <a href={globalData.paymentLink} target="_blank" className="text-decoration-none align-middle text-primary" >Ödeme Yap</a>}
                            {harcFileList.length > 0 && (role.indexOf(UserPermConstants.HARC_DOSYALARI) > -1 || role.indexOf(UserPermConstants.Kontrolor_Dosyasi_Yukleme)) && <a href={"/getharcfile?id=" + harcFileList[0].id} className="text-decoration-none align-middle ms-2" >Harç Dosyası</a>} )
</span>
 
                        </span>
                    }
                    {appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Taslak &&
                        <span className="text-info fw-bold">( Başvuru Henüz Gönderilmedi )</span>
                    }

                </div>
                <div className="col-1 text-end">
                    <a href="/#" onClick={() => { getappdata(appData.id, setData); props.refreshParent(); }} className="text-decoration-none" ><i className="fa fa-refresh" style={{ "font-size": "18px" }}></i></a>
                </div>

            </div >

            <div className="row pb-2 border-bottom mt-4" >
                <div className="col-12 col-md-1 text-center">
                    <img src={require('../Header/logo2.png').default} className="w-100" />


                </div>
                <div className="col-12 col-md-6 ">
                    <h5> <b>{appData.ada}-{appData.arsaSahibi}</b></h5>
                    <div className="text-muted fw-bold mb-2">Evrak Numarası: {appData.id}</div>
                    {/*<i class="fa fa-map-marker text-muted me-1" ></i> <a onClick={checkEnlemBoylem} target="_blank" href={"https://imar.menderes.bel.tr/Service/" + toUpperCaseTurkce(appData.mahalle + "/" + appData.ada)} className="fw-bold text-decoration-none pt-0 me-4" >CBS  </a>*/}
                    {/*<i class="fa fa-map-marker text-muted me-1" ></i> <a onClick={checkEnlemBoylem} target="_blank" href={"https://www.google.com/maps/@" + appData.enlem + "," + appData.boylam + ",18z"} className="fw-bold text-decoration-none pt-0 me-4"> Google Map </a>*/}
                    <i class="fa fa-map-marker text-muted " ></i> <button key={mahalleCoordinates == null ? 1 : mahalleCoordinates.length} onClick={() => props.changeComponent(googlemap)} className="btn btn-link fw-bold ps-0 text-decoration-none pt-0 me-2"> Google Map </button>
                    <i class="fa fa-map-marker text-muted " ></i> <a onClick={checkEnlemBoylem} target="_blank" href={"https://parselsorgu.tkgm.gov.tr/#ara/cografi/" + appData.enlem + "/" + appData.boylam} className="text-decoration-none pt-0 fw-bold me-2">TKGM </a>
                    {(isEmpty(appData.temp) == true || appData.temp.includes("Düzenleme")) && <button type="button" className="btn btn-sm btn-link text-decoration-none  fw-bold" onClick={() => changeComponent(<Apply1 appData={{ ...appData, "id": -2 }} changeComponent={props.changeComponent} />)}><i class="fa fa-map-marker text-muted " ></i> Konuma Yeni Başvuru</button>}




                    {role.indexOf(UserPermConstants.Kalan_Sure_Gorme) != -1 && <div><div className="text-muted pt-2 mt-2 d-inline-flex" >{getDenetimSure(kontroller)}</div></div>}
                    {appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Duzeltme_Bekleniyor && <div><div className="text-info pt-2 mt-2 d-inline-flex fw-bold" >Düzeltme Talep Tarihi : {(appData.sonDuzeltmeTalebiTarihi)}  {getDuzeltmeKalanSure()}</div></div>}
                    <div className="text-muted pt-2 fw-bold" id="headlastnote"></div>
                </div>
                <div className="col-12 col-md-5">
                    {/*<div className="row">*/}
                    {/*    <div className="col-12 col-md-4 rounded" >Kontrolör</div>*/}
                    {/*    <div className="col-12 col-md-8 rounded " >*/}
                    {/*        {kontroller != null ? <UserLabel user={{ userName: kontroller.userName }} picSize={28} /> :*/}
                    {/*            getDurumName(appData.basvuruDurumu)*/}
                    {/*        }*/}

                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="row mt-2">
                        <div className="col-12 col-md-4 rounded" >Başvuru</div>
                        <div className="col-12 col-md-8 rounded " ><span className="p-1 ps-3 pe-3 rounded-3 text-center text-success fw-bold" style={{ "background-color": "#d6ffe0" }}>{appData.basvuruTarihi} </span></div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12 col-md-4 rounded" >Kabul</div>
                        <div className="col-12 col-md-8 rounded " ><span className="p-1 ps-3 pe-3 rounded-3 text-center text-primary fw-bold" style={{ "background-color": "#e1f0ff" }}>{appData.kabulEdilmeTarihi} </span></div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12 col-md-4 rounded" >Tamamlanma</div>
                        <div className="col-12 col-md-8 rounded " ><span className="p-1 ps-3 pe-3 rounded-3 text-center text-danger fw-bold" style={{ "background-color": "#ffe2e5" }}>{appData.surecTamamlanmaTarihi} </span></div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12 col-md-4 rounded" >Paylaşılanlar</div>
                        <div className="col-12 col-md-8 rounded " ><SharedUsersPanel appData={appData} showCompact={true} /></div>
                    </div>
                </div>
            </div>
            <fieldset disabled={disablesabitsorular} ref={ref}>
                <div className="row ms-4 me-4 mt-2 ">
                    <div className="align-items-center justify-content-center " >
                        <div id="myscript"></div>


                        <div className="row mt-1">
                            <div className="col-12 col-md-4">
                                <label htmlFor="BasvuruDurumu" className="form-label">Başvuru Durumu</label>
                                <input type="text" disabled readOnly={true} className="form-control form-control-sm" id="BasvuruDurumu" placeholder="BasvuruDurumu" name="BasvuruDurumu" value={getDurumName(appData.basvuruDurumu)} onChange={handleInputChange} />
                            </div>
                            <div className="col-12 col-md-4">
                                <label htmlFor="BaşvuruTipi" className="form-label">Başvuru Tipi</label>
                                <div >
                                    <input type="text" disabled readOnly={true} className="form-control form-control-sm" id="basvuruTipi" placeholder="basvuruTipi" name="basvuruTipi" value={appData.basvuruTipi} onChange={handleInputChange} />

                                    {/*<select disabled readOnly={true} className="form-select form-select-sm" defaultValue={appData.basvuruTipi} key={appData.basvuruTipi} name="basvuruTipi" value={appData.basvuruTipi} onChange={handleInputChange} >*/}
                                    {/*    */}{/*{props.apptypeList.map(a => <option value={a.name}>*/}
                                    {/*    */}{/*    {a.name}*/}
                                    {/*    */}{/*</option>)}*/}
                                    {/*</select>*/}
                                </div>
                            </div>

                            <div className="col-12 col-md-4">

                                <label htmlFor="basvuruYapan" className="form-label">
                                    Başvuru Yapan
                                    <button type="button" id="changevekaletbutton" href="#" onClick={() => {
                                        chengeVekaletDurum()
                                        var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
                                        var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
                                            return new bootstrap.Popover(popoverTriggerEl)
                                        })
                                    }} className="btn btn-sm btn-link p-0 m-0 norder-0 text-decoration-none shadow-none">
                                        {appData.vekaletenBasvuru == true ? " (Vekil) " : " (Taşınmaz Sahibi)"} </button>


                                    <span className="" id="vekaletbuttondiv" href="#" hidden={appData.vekaletenBasvuru != true}>
                                        - <a type="button" onClick={() => {
                                            initModal();
                                            document.getElementById('myModal').style.display = "block";
                                            document.getElementById('myModal').classList.remove("hide");

                                        }} className="text-decoration-none m-0 p-0">Dosya</a>
                                        <button type="button" className="btn btn-sm btn-secondary ms-2  text-decoration-none fw-bold" id="mypopover" data-bs-trigger="focus" data-bs-toggle="popover" title="AÇIKLAMA" data-bs-content="Taşınmaz sahibi adına başvuru yapabileceğinizi gösteren belgedir. Bu belge, noter onaylı vekaletname olabileceği gibi Yapı Denetim Başvuruları ve İmar Uygulamalarına yönelik  başvurularda taşınmaz sahibi ile aranızda yaptığınız sözleşme de olabilir.">?</button>

                                    </span>

                                </label>
                                <input type="text" disabled readOnly={true} className="form-control form-control-sm" id="basvuruYapan" value={appData.basvuruYapan} key={appData.basvuruYapan} placeholder="Başvuru Yapan" name="basvuruYapan" onChange={handleInputChange} required={zorunloBosBirakma} />

                            </div>
                        </div>

                        {userData != null &&
                            <div className="row mt-3" id="vekilbilgileridiv" hidden={appData.vekaletenBasvuru != true}>
                                <div className="col-12 col-md-4">

                                    <label className="form-label">Vekil</label>
                                    <input type="text" className="form-control form-control-sm" id="vekilName" name="vekilName" disabled={role.indexOf(UserPermConstants.Basvuru_Vekili_Update) == -1} defaultValue={capitalizeWords(appData.vekilName)} onChange={handleInputChange} />

                                </div>
                                <div className="col-12 col-md-4">
                                    <label className="form-label">Vekil Telefon Numarası</label>
                                    <input type="tel" className="form-control form-control-sm" id="vekilTel" name="vekilTel" disabled={role.indexOf(UserPermConstants.Basvuru_Vekili_Update) == -1} defaultValue={appData.vekilTel} onChange={handleInputChange} />
                                </div>

                                <div className="col-12 col-md-4">

                                    <label className="form-label">Vekil T.C. </label>
                                    <input type="text" className="form-control form-control-sm" id="vekilTC" name="vekilTC" disabled={role.indexOf(UserPermConstants.Basvuru_Vekili_Update) == -1} defaultValue={appData.vekilTC} onChange={handleInputChange} />

                                </div>

                            </div>}


                        <div className="row mt-3">
                            <div className="col-12 col-md-4">

                                <label htmlFor="arsaSahibi" className="form-label">Taşınmaz Sahibi</label>
                                <input disabled={appData.vekaletenBasvuru != true} type="text" className="form-control form-control-sm" id="arsaSahibi" defaultValue={appData.arsaSahibi} key={appData.arsaSahibi} placeholder="Taşınmaz Sahibi" name="arsaSahibi" onChange={handleInputChange} required={fzorunlu("arsaSahibi", sabitsorulistData, appData) == "*" && zorunloBosBirakma} />

                            </div>
                            <div className="col-12 col-md-4">

                                <label htmlFor="arsaSahibiTel" className="form-label">Taşınmaz Sahibi Telefon Numarası</label>
                                <input disabled={appData.vekaletenBasvuru != true} type="tel" pattern="[5-5]{1}[0-9]{2}[0-9]{7}" maxLength="10" placeholder="5__ ___ ____" className="form-control form-control-sm" id="arsaSahibiTel" defaultValue={appData.arsaSahibiTel} name="arsaSahibiTel" onChange={handleInputChange} required={fzorunlu("arsaSahibiTel", sabitsorulistData, appData) == "*" && zorunloBosBirakma} key={appData.arsaSahibiTel} />

                            </div>

                            <div className="col-12 col-md-4">

                                <label htmlFor="basvuruYapan" className="form-label">Taşınmaz Sahibi T.C. </label>
                                <input disabled={appData.vekaletenBasvuru != true} type="text" pattern="[0-9]{1,20}" maxLength="11" className="form-control form-control-sm" id="arsaSahibiTC" defaultValue={appData.arsaSahibiTC} key={appData.arsaSahibiTC} placeholder="Taşınmaz Sahibi T.C." name="arsaSahibiTC" onChange={handleInputChange} required={fzorunlu("arsaSahibiTC", sabitsorulistData, appData) == "*" && zorunloBosBirakma} />

                            </div>

                        </div>


                        <fieldset disabled={disableaddresarea} ref={ref}>

                            <div className="row mt-3">
                                <div className="col-12 col-md-4">
                                    <label htmlFor="Mahalle" className="form-label">Mevki</label>
                                    <select className="form-select form-select-sm" id="Mevki" name="mevki" key={appData.mevki} placeholder="Mevki" onChange={handleInputChange} required={fzorunlu("mevki", sabitsorulistData, appData) == "*"} value={appData.mevki}>
                                        {
                                            MevkiList.map(x => <option value={x.name} > {x.name}</option>)
                                        }

                                    </select>
                                </div>
                                <div className="col-12 col-md-4">
                                    <label htmlFor="Mahalle" className="form-label">Mahalle</label>
                                    <select className="form-select form-select-sm" id="Mahalle" name="mahalle" key={appData.mahalle} placeholder="Mahalle" onChange={handleInputChange} required={fzorunlu("mahalle", sabitsorulistData, appData) == "*"} value={appData.mahalle}>
                                        <option value="" ></option>
                                        {
                                            MahalleList.map(x => <option value={x.name} > {x.name}</option>)
                                        }

                                    </select>
                                </div>
                                <div className="col-12 col-md-4">

                                    <label htmlFor="Ada" className="form-label">Ada/Parsel</label>
                                    <input autoComplete="true" list="Adaparselistesi" className="form-control form-control-sm" key={appData.ada} id="Ada" placeholder="Ada Parsel" name="ada" defaultValue={appData.ada} key={appData.ada} onChange={(e) => { handleInputChange(e); var ada = AdaList.find(x => x.name == e.target.value); if (!isEmpty(ada)) fetchCoordinates("ADA:" + ada.id) }} onKeyPress={validateNumberAndForwardSlash} onBlur={validateSelection} required={fzorunlu("ada", sabitsorulistData, appData) == "*" && zorunloBosBirakma} />
                                    <datalist id="Adaparselistesi">fill by fetchsureclist</datalist>


                                </div>
                            </div>

                            <div className="row mt-3 pb-2">
                                <div className="col-12 col-md-4" id="idarimahalleblock">
                                    <label htmlFor="Mahalle" className="form-label">Idari Mahalle </label>
                                    <select className="form-select form-select-sm" id="IdariMahalle" name="idariMahalle" placeholder="Mahalle" onChange={handleInputChange} defaultValue={appData.idariMahalle} key={appData.idariMahalle} required={fzorunlu("idariMahalle", sabitsorulistData, appData) == "*" && zorunloBosBirakma} >
                                        <option selected> {appData.idariMahalle}</option>
                                    </select>
                                </div>

                                <div className="col-12 col-md-4">
                                    <label htmlFor="Enlem" className="form-label">Sokak</label>
                                    <input type="text" className="form-control form-control-sm" id="Sokak" placeholder="Sokak" name="sokak" onChange={handleInputChange} defaultValue={appData.sokak} key={appData.sokak} required={fzorunlu("sokak", sabitsorulistData, appData) == "*" && zorunloBosBirakma} />
                                </div>
                                <div className="col-12 col-md-4">
                                    <label htmlFor="Boylam" className="form-label">No</label>
                                    <input type="text" className="form-control form-control-sm" id="No" placeholder="No" name="no" onChange={handleInputChange} defaultValue={appData.no} key={appData.no} required={fzorunlu("no", sabitsorulistData, appData) == "*" && zorunloBosBirakma} />
                                </div>

                            </div>

                            <div className="row mt-2 pb-2">
                                <div className="col-12 col-md-4">
                                    <label htmlFor="Enlem" className="form-label">Enlem</label>
                                    <input type="text" className="form-control form-control-sm" id="Enlem" placeholder="Enlem" name="Enlem" onChange={handleInputChange} defaultValue={appData.enlem} key={appData.enlem} required={fzorunlu("enlem", sabitsorulistData, appData) == "*" && zorunloBosBirakma} />
                                </div>
                                <div className="col-12 col-md-4">
                                    <label htmlFor="Boylam" className="form-label">Boylam</label>
                                    <input type="text" className="form-control form-control-sm" id="Boylam" placeholder="Boylam" name="Boylam" onChange={handleInputChange} defaultValue={appData.boylam} key={appData.boylam} required={fzorunlu("boylam", sabitsorulistData, appData) == "*" && zorunloBosBirakma} />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </fieldset>
        </div >


        <fieldset disabled={disabledinamiksorular}>

            <div className="bg-white rounded-3 mt-1" >
                <div className="row " >
                    <div className="align-items-center justify-content-center " >

                        <div className="row mt-1 ms-4 me-4 pb-3 mb-3">
                            {dynamicFields}
                        </div>

                        {/*{fvisible("enlem", sabitsorulistData, appData) && fvisible("boylam", sabitsorulistData, appData) &&*/}
                        {/*    <div className="row mt-1 ms-4 me-4 pb-3 border-top">*/}

                        {/*        <div className="col-12 pt-3  mt-3 rounded-3" style={{ "min-height": "350px" }}>*/}
                        {/*            <GMapLatLang appData={appData} setLatLang={setLatLang} polygon={mahalleCoordinates} key={mahalleCoordinates == null ? 1 : mahalleCoordinates.length} />*/}
                        {/*        </div>*/}
                        {/*    </div>}*/}






                        <div className="row mt-3 border-top pt-3">

                            {(appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Taslak
                                || appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Duzeltme_Bekleniyor
                                || appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Ucret_Bekleniyor
                                || appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_GeriGonderildi
                                || role.indexOf(UserPermConstants.Basvurudan_Sonra_Sabit_Editleme) > -1
                                || role.indexOf(UserPermConstants.Basvurudan_Sonra_Dinamik_Editleme) > -1) &&
                                <div className="col">
                                    {appData.archived != true ?
                                        <button type="submit" className="btn btn-sm btn-primary mb-2 rounded-3  ">Kaydet</button>
                                        : <span className="text-secondary fw-bold">Arşivlenen Başvuru Güncellenemez</span>}
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
        <div className="col text-end mt-3  p-0">

        </div>

    </form>

        <div className="modal" id="myModal" >
            <div class="modal-dialog modal  modal-dialog-centered ">
                <div class="modal-content" >
                    <div class="modal-body" id="mymodalbody" >

                        <div className="row">
                            <div className="col-10">

                                <label for="formFile" class="form-label">Vekalet Dosyası Yukle</label>
                                <br />
                                <input class="form-control form-control-sm mt-2" type="file" accept="*.pdf" id="formFile" onChange={(e) => SendFiles(e)} />
                            </div>

                            <div className="col-2 text-end">
                                <div class="spinner-border  spinner-border-sm" hidden id="fileuploadsp" ></div>
                            </div>


                        </div>

                        <div className="row mt-3">
                            <div className="col-12">
                                {appData.vekaletDosyaname != null && <a className="text-decoration-none" href={"/getbasvuruvekaletfile?appid=" + appData.id} id="vekaletdosyaname" download={appData.vekaletDosyaname}>{appData.vekaletDosyaname} </a>}
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">


                        <button type="button" class="btn btn-danger" id="downclosemodal" data-bs-dismiss="modal">Kapat</button>
                    </div>
                </div>
            </div>
        </div>

    </div>

}



