import React, { useState, useEffect } from 'react';
import UserStatistics from './UserStatistics.js';
import $ from 'jquery';
import { ajaxFail } from '../GlobalState.js';

export default function UserWorkLoad(props) {
    const [workload, setWorkLoad] = React.useState(null);
    const [denetimlist, setDenetimList] = React.useState(null);
    const [devamstat, setDevamStat] = React.useState({ biten: false, devam: false, bekleyen: false, tumu: false });
    const [username, setUserName] = useState(props.userName);

    useEffect(() => {
        getDenetimList();
    }, []);

    const getDenetimList = () => {
        fetch("denetimlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
                this.setState({ status: response.statusText })
            }
            else {
                response.json().then(async data => {
                    var det = [];
                    for (var i = 0; i < data.length; i++)
                        det[data[i].id] = data[i].name;
                    setDenetimList(det);
                    getWorkLoadList();
                });
            }
        });
    }


    const getWorkLoadList = () => {
        fetch("getuserworkloads", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
                this.setState({ status: response.statusText })
            }
            else {
                response.json().then(async data => {
                    setWorkLoad(data);
                });
            }
        });
    }

    const filterTable = (event) => {
        const target = event.target;
        const value = target.value.toLowerCase();
        $("#myworkloadTable tr").filter(function () {
            var keywords = value.split(" ");
            for (let index = 0; index < keywords.length; ++index)
                if ($(this).text().toLowerCase().indexOf(keywords[index]) == -1)
                    return $(this).toggle(false);
            return $(this).toggle(true);
        });
    }



    const renderToList = () => {
        var mytable =
            <div className="mt-1">
                <div className="mt-1 p-2  d-flex align-items-center justify-content-center rounded" style={{ "background-color": "#fff8dd" }}>
                    <input className="form-control w-50" id="myInput" type="text" placeholder="Arama..." onKeyUp={filterTable} />
                </div>

                <div className="mt-2 p-2  d-flex align-items-center justify-content-center rounded" style={{ "background-color": "#fff5f8" }}>
                    <div >

                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="tamamlanang" onClick={() => { setDevamStat({ biten: true, devam: false, bekleyen: false, tumu: false }) }} />
                        <label className="form-check-label me-5 " for="tamamlanang" onClick={() => { setDevamStat({ biten: true, devam: false, bekleyen: false, tumu: false }) }}>
                            Tamamlanan Görevler
                        </label>

                        <input className="form-check-input " type="radio" name="flexRadioDefault" id="bekleyeng" onClick={() => { setDevamStat({ biten: false, devam: false, bekleyen: true, tumu: false }) }} />
                        <label className="form-check-label me-5 " for="bekleyeng" onClick={() => { setDevamStat({ biten: false, devam: false, bekleyen: true, tumu: false }) }}>
                            Sırada Bekleyen Görevler
                        </label>
                        <input className="form-check-input " type="radio" name="flexRadioDefault" id="devamedeng" onClick={() => { setDevamStat({ biten: false, devam: true, bekleyen: false, tumu: false }) }} />
                        <label className="form-check-label me-5 " for="devamedeng" onClick={() => { setDevamStat({ biten: false, devam: true, bekleyen: false, tumu: false }) }}>
                            Devam Eden Görevler
                        </label>

                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="tumg" onClick={() => { setDevamStat({ biten: false, devam: false, bekleyen: false, tumu: true }) }} />
                        <label className="form-check-label me-5 " for="tumg" onClick={() => { setDevamStat({ biten: false, devam: false, bekleyen: false, tumu: true }) }}>
                            Tüm Görevler
                        </label>

                    </div>
                </div>

                <table className='table mt-3 table-striped  table-sm' aria-labelledby="tabelLabel">
                    <thead>
                        <tr>
                            <th></th>
                            <th> Kullanıcı</th>
                            <th> Başvuru No</th>
                            <th> Görev</th>
                            <th> Atama Tarihi</th>
                            <th> Başlama Tarihi</th>
                            <th> Bitirme Tarihi</th>
                        </tr>
                    </thead>
                    <tbody id="myworkloadTable">
                        {workload.map(rec => (devamstat.devam == false || (rec.started != null && rec.completed == null)) &&
                            (devamstat.biten == false || (rec.completed != null)) &&
                            (devamstat.bekleyen == false || (rec.started == null)) &&
                            (username == null || (username == rec.userName)) ?
                            <tr key={rec.id}>
                                <td width="32px"><img className="rounded-circle" style={{ width: "32px", height: "32px" }} src={"/getuserpic?user=" + rec.userName} /></td>
                                <td>{rec.userName}</td>
                                <td>{rec.appID}</td>
                                <td>{denetimlist[rec.denetimID]}</td>
                                <td>{rec.mappingDate}</td>
                                <td>{rec.started}</td>
                                <td>{rec.completed}</td>
                            </tr> : ""
                        )}
                    </tbody>
                </table>
            </div>
        return (mytable);
    }

    var tabledata = "";

    if (workload != null) {
        tabledata = renderToList();
    }


    return <div>
        <div  >
            {tabledata}

        </div>
        <div  >

            {username == null ? <UserStatistics /> : ""}

        </div>
    </div>

}