import React, { useState, useEffect } from 'react';
import VTable from '../Header/VTable.js';
import { sendget, sendpost, ajaxFail, isEmpty, setVisible, showConfirmMessage, showErrorMessage, showSuccessMessage } from '../GlobalState.js';
import MAKS from './MAKS.js';

export default function MaksList(props) {
    const [makslist, setMaksList] = useState([]);
    const appData = props.appData;
    const setActivePanel = props.setActivePanel;
    const [rec, setRec] = useState({});
    const [maksMahalleList, setmaksMahalleList] = useState([]);
    const [maksAdresList, setMaksAdresList] = useState([]);
    const [yollist, setYols] = useState([]);
    const [numarajlist, setNumaratjList] = useState([]);
    const [selectedAdres, setSelectedAdres] = useState([]);



    useEffect(() => {
        sendget("makslist?appid=" + appData.id, "mysnip", showTable)
        fetchMaksMahalle();

    }, [])

    const fetchMaksMahalle = () => {
        fetch("getmaksmahallelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setmaksMahalleList(value);

                })
            }
        });
    }

    const fetchMaksYol = (id) => {
        fetch("getmaksyollist?mahalleid=" + id, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setMaksAdresList(value)
                    const yols = new Set();
                    value.forEach(x => yols.add(x.yolad));

                    setYols([...yols]);

                })
            }
        });
    }


    const handleMahalleChange = (event) => {
        var idx = document.getElementById("maksmahalle").selectedIndex;
        rec.yapiBelgeAdres_MahalleKimlikNo = document.getElementById("maksmahalle").options[idx].value
        rec.yapiBelgeAdres_MahalleAdi = document.getElementById("maksmahalle").options[idx].innerHTML
        
        fetchMaksYol(document.getElementById("maksmahalle").options[idx].value);
    }

    const handleYolChange = (event) => {
        var idx = document.getElementById("maksyol").selectedIndex;
        var yolad = document.getElementById("maksyol").options[idx].innerHTML;
        rec.yol = yolad;
        setNumaratjList(maksAdresList.filter(x => x.yolad == yolad).map(x => x.kapino));
    }

    const handleKapiNoChange = (event) => {
        var idx = document.getElementById("makskapino").selectedIndex;
        var k = document.getElementById("makskapino").options[idx].innerHTML;
        idx = document.getElementById("maksyol").selectedIndex;
        var yolad = document.getElementById("maksyol").options[idx].innerHTML;
        var adres = maksAdresList.filter(x => x.yolad == yolad && x.kapino == k);
        if (adres.length == 0) {
            showErrorMessage("Seçilen Kapi No Bulunamadı !");
            return;

        }
        document.getElementById("adresinfo").innerHTML = "Adres: " + adres.length + " , Numarataj: " + adres[0].numarataj + " , Yapı K. No" + adres[0].yapikimlikno + " , Bagimsiz Bol K. No:" + adres[0].bagimsizbolkimlikno + " , Bagimsiz Bol No:" + adres[0].yapiBelgeAdres_BagimsizBolumNo;
        rec.yapiBelgeAdres_DisKapiNo = k;
        rec.yapiBelgeYapi_YapiKimlikNo = adres[0].yapikimlikno;
        rec.yapiBelgeAdres_BagimsizBolumKimlikNo = adres[0].bagimsizbolkimlikno;
        if (isEmpty(adres[0].bagimsizbolumno)==false)
        rec.yapiBelgeAdres_BagimsizBolumNo = adres[0].bagimsizbolumno+"";
    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        if (isEmpty(value) == false)
            rec[target.name] = value;
        else
            rec[target.name] = null;
    }

    const handleSelectChange = (event) => {
        const target = event.target;
        const value =target.value;
        if (isEmpty(value) == false)
            rec[target.name] = Number(value);
        else
            rec[target.name] = null;
    }


    const showTable = (response) => {
        response.json().then(async data => {
            data.forEach(x => {
                if (x.yapiBelge_YapiBelgeTipKod == 1)       x.yapiBelge_YapiBelgeTipName = "Yapi Ruhsat Belgesi";
                if (x.yapiBelge_YapiBelgeTipKod == 2)  x.yapiBelge_YapiBelgeTipName = "Yapi Kullanma Izin Belgesi";
                if (x.yapiBelge_YapiBelgeTipKod == 3) x.yapiBelge_YapiBelgeTipName = "Yanan Yikilan Yapi Formu";
                if (x.yapiBelge_YapiBelgeTipKod == 4) x.yapiBelge_YapiBelgeTipName = " Yikim Ruhsat Belgesi";
                if (x.ruhsat_RuhsatVerilisAmacKod == 1) x.yapiBelge_YapiBelgeTipName = "YeniYapi";
                if (x.ruhsat_RuhsatVerilisAmacKod == 2) x.ruhsat_RuhsatVerilisAmacName = "Yenileme";
                if (x.ruhsat_RuhsatVerilisAmacKod == 3) x.ruhsat_RuhsatVerilisAmacName = "Yeniden";
                if (x.ruhsat_RuhsatVerilisAmacKod == 4) x.ruhsat_RuhsatVerilisAmacName = "Ek Bina";
                if (x.ruhsat_RuhsatVerilisAmacKod == 5) x.ruhsat_RuhsatVerilisAmacName = "Kat Ilavesi";
                if (x.ruhsat_RuhsatVerilisAmacKod == 6) x.ruhsat_RuhsatVerilisAmacName = "Ilave";
                if (x.ruhsat_RuhsatVerilisAmacKod == 7) x.ruhsat_RuhsatVerilisAmacName = "Gecici";
                if (x.ruhsat_RuhsatVerilisAmacKod == 8) x.ruhsat_RuhsatVerilisAmacName = "Tadilat";
                if (x.ruhsat_RuhsatVerilisAmacKod == 9) x.ruhsat_RuhsatVerilisAmacName = "Dolgu";
                if (x.ruhsat_RuhsatVerilisAmacKod == 10) x.ruhsat_RuhsatVerilisAmacName = "Restorasyon";
                if (x.ruhsat_RuhsatVerilisAmacKod == 11) x.ruhsat_RuhsatVerilisAmacName = "Guclendirme";
                if (x.ruhsat_RuhsatVerilisAmacKod == 12) x.ruhsat_RuhsatVerilisAmacName = "Kullanim Degisimi";
                if (x.ruhsat_RuhsatVerilisAmacKod == 13) x.ruhsat_RuhsatVerilisAmacName = "Fosseptik";
                if (x.ruhsat_RuhsatVerilisAmacKod == 14) x.ruhsat_RuhsatVerilisAmacName = "Mekanik Tesisat";
                if (x.ruhsat_RuhsatVerilisAmacKod == 15) x.ruhsat_RuhsatVerilisAmacName = "Elektrik Tesisati";
                if (x.ruhsat_RuhsatVerilisAmacKod == 16) x.ruhsat_RuhsatVerilisAmacName = "Isim Degisikligi";
                if (x.ruhsat_RuhsatVerilisAmacKod == 17) x.ruhsat_RuhsatVerilisAmacName = "Istinat Duvari";
                if (x.ruhsat_RuhsatVerilisAmacKod == 18) x.ruhsat_RuhsatVerilisAmacName = "Bahce Duvari";
                if (x.ruhsat_RuhsatVerilisAmacKod == 19) x.ruhsat_RuhsatVerilisAmacName = "Diger";
                if (x.ruhsat_RuhsatVerilisAmacKod == 20) x.ruhsat_RuhsatVerilisAmacName = "Diger Kacak Yapi";
                if (x.ruhsat_RuhsatVerilisAmacKod == 21) x.ruhsat_RuhsatVerilisAmacName = "Diger Iksa";
                if (x.ruhsat_RuhsatVerilisAmacKod == 22) x.ruhsat_RuhsatVerilisAmacName = "Diger 775 Sayili Gece kondu Kanunu";
                if (x.ruhsat_RuhsatVerilisAmacKod == 23) x.ruhsat_RuhsatVerilisAmacName = "Diger Hes";
                if (x.ruhsat_RuhsatVerilisAmacKod == 24) x.ruhsat_RuhsatVerilisAmacName = "Diger Baz";
            })
            setMaksList(data)
        });

    }

    const rowClick = (idx, rec, col) => {
        setActivePanel(<MAKS appData={appData} setActivePanel={setActivePanel} MaksApp={rec} />)
    }
    const rowDelete = (idx, rec, col) => {
        if (rec.bultenNo) {
            showErrorMessage("Bulten numarası alınmış başvuru silinemez.")
            return;
        }
        showConfirmMessage("Başvuruyu Silmek İstediğinizden Emin misniz?", "", "Evet", "Hayır", () => {
            sendpost("removemaksapp", rec.id, "mysnip", (response) => {
                showTable(response)
            })


        })

    }


    const save = () => {
        rec.appId = appData.id;
        sendpost("savemaksapp", rec, "newsnipper", (response) => {
            response.json().then(value => {
                showSuccessMessage("Başvuru kaydedilmiştir", "Başvuru No: " + value.id);
                sendget("makslist?appid=" + appData.id, "mysnip", showTable)
                document.getElementById("maksmahalle").selectedIndex = 0;
                document.getElementById("maksyol").selectedIndex = 0;
                document.getElementById("makskapino").selectedIndex = 0;
                document.getElementById("makskapino").selectedIndex = 0;
                document.getElementById("ruhsat_RuhsatVerilisAmacKod").selectedIndex = 0;
            })
        })
    }

    return (
        <div>
            <div className="bg-white rounded-3 p-1 border">
                <div className="row">
                    <div className="text-start col-12 fw-bold text-primary ">
                        Yeni Başvuru
                    </div>
                </div>

                <div className="row ">


                    <div className="text-start col-3">
                        <label className="form-label">Mahalle</label>
                        < select className="form-select form-select-sm" id="maksmahalle" name="maksmahalle" onChange={handleMahalleChange}>
                            <option value=""></option>
                            {maksMahalleList.map(x => <option value={x.kimlikno}>{x.ad}</option>)}
                        </select>

                    </div>

                    <div className="text-start col-3">
                        <label className="form-label">YOL</label>
                        < select className="form-select form-select-sm" id="maksyol" name="maksyol" onChange={handleYolChange}>
                            <option value=""></option>
                            {yollist.map(x => <option value={x}>{x}</option>)}
                        </select>
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Kapı No</label>
                        < select className="form-select form-select-sm" id="makskapino" name="makskapino" onChange={handleKapiNoChange}>
                            <option value=""></option>
                            {numarajlist.map(x => <option value={x}>{x}</option>)}
                        </select>
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Ruhsat Verilis Amac</label>
                        <select class="form-select form-select-sm" aria-label="Default select example" id="ruhsat_RuhsatVerilisAmacKod" name="ruhsat_RuhsatVerilisAmacKod" onChange={handleSelectChange}>
                            <option ></option>
                            <option value="1">YeniYapi</option>
                            <option value="2">Yenileme</option>
                            <option value="3">Yeniden</option>
                            <option value="4">Ek Bina</option>
                            <option value="5">Kat Ilavesi</option>
                            <option value="6">Ilave</option>
                            <option value="7">Gecici</option>
                            <option value="8">Tadilat</option>
                            <option value="9">Dolgu</option>
                            <option value="10">Restorasyon</option>
                            <option value="11">Guclendirme</option>
                            <option value="12">Kullanim Degisimi</option>
                            <option value="13">Fosseptik</option>
                            <option value="14">Mekanik Tesisat</option>
                            <option value="15">Elektrik Tesisati</option>
                            <option value="16">Isim Degisikligi</option>
                            <option value="17">Istinat Duvari</option>
                            <option value="18">Bahce Duvari</option>
                            <option value="19">Diger</option>
                            <option value="20">Diger Kacak Yapi</option>
                            <option value="21">Diger Iksa</option>
                            <option value="22">Diger 775 Sayili Gece kondu Kanunu</option>
                            <option value="23">Diger Hes</option>
                            <option value="24">Diger Baz</option>
                        </select>
                    </div>

                </div>

                <div className="row mt-2 small ps-2 text-success" id="adresinfo">

                </div>
                <div className="row mt-3">
                    <div className="text-start col-3 pt-3">
                        <input type="checkbox" className="form-check-input" id="yapiBelge_YapiDenetimTabi" name="yapiBelge_YapiDenetimTabi" onChange={handleInputChange} /> Bina Yapı Denetime tabi değil
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Yibf No</label>
                        <input type="number" className="form-control form-control-sm" id="yapiBelge_YibfNo" name="yapiBelge_YibfNo" onChange={handleInputChange} />
                    </div>

                    <div className="text-start col-3">
                        <label className="form-label">Yapi Belge Tipi</label>
                        <select class="form-select form-select-sm" aria-label="Default select example" id="yapiBelge_YapiBelgeTipKod" name="yapiBelge_YapiBelgeTipKod" onChange={handleSelectChange} >
                            <option selected ></option>
                            <option value="1" >Yapi Ruhsat Belgesi</option>
                            <option value="2" >Yapi Kullanma Izin Belgesi</option>
                            <option value="3">Yanan Yikilan Yapi Formu</option>
                            <option value="4">Yikim Ruhsat Belgesi</option>
                        </select>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12 text-end">
                        <div id="newsnipper" className="spinner-border spinner-border-sm" hidden></div>

                        <button type="button" className="ms-1 btn btn-primary  btn-sm  text-decoration-none " style={{ "border-radius": "8px" }} onClick={save}>
                            Başvuru Oluştur
                        </button>
                    </div>
                </div>



            </div>

            <div className="mt-3 rounded-3 border p-1">
                <div className="row">
                    <div className="text-start col-6 fw-bold text-primary ">
                        Mevcut Başvurular
                    </div>
                    <div className="text-end col-6 ">
                        <div id="mysnip" className="spinner-border spinner-border-sm" ></div>

                    </div>
                </div>
                <VTable data={makslist} cols={["id", "regDate", "yapiBelge_YapiBelgeTipName","ruhsat_RuhsatVerilisAmacName", "bultenNo"]} headers={["No", "Başvuru Tarihi","Yapı Belge Tipi","Variliş Amaç", "Bulten No"]} rowClick={rowClick} rowDelete={rowDelete} />
            </div >
        </div >
    );


}