import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import ReactToPdf from 'react-to-pdf';
import { useGlobalState, changeTurkceToEnglis, capitalizeWords } from '../GlobalState.js';
import { ajaxFail } from '../GlobalState.js';
import Application from './Application.js';
import { jsPDF } from "jspdf";

export default function ApplicationPrint(props) {
    var appTypes = props.appTypes;
    const [appGrupList, setappGrupList] = useState(null);
    const [fileTypes, setFileTypeData] = useState(null);

    useEffect(() => {
        fetchGeneralData();
        
        fetchUserData();
        fetchAppGrupList();
        fetchFileList();
    }, [])

    const fetchFileList = () => {
        fetch("filenamelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setFileTypeData(value);
                    fetchFiles();
                })
            }
        });
    }

    const fetchAppGrupList = () => {
        fetch("apptypegroupslist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setappGrupList(value);
                })
            }
        });
    }

   



    let location = useLocation();
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const ref = React.createRef();
    const [appData, setData] = useState(props.rec != null ? props.rec : location.state.rec);
    var [gdata, setGData] = useState(null);
    const [userData, setUserdata] = useState(null);

    var [allDynamicFilds, setAllDynamicFilds] = React.useState();

    const fetchAppDynamicFieldList = () => {
        fetch("getappfields", {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {

                response.json().then(async data => {
                    setAllDynamicFilds(data);
                });
            }
        });
    }


    const getAppGrupName = () => {
        if (appTypes == null || appGrupList==null) {
            return "(İmar ve Şehircilik Müdürlüğü)";
        }
        var g = appTypes.find(x => x.name == appData.basvuruTipi);
        if (g == null || g.grup==null)
            return "(İmar ve Şehircilik Müdürlüğü)";
        var grp = appGrupList.find(x => x.id == g.grup);

        if (grp == null )
            return "(İmar ve Şehircilik Müdürlüğü)";
        return "(" + grp.name+")"
    }

    const getFileName = (f) => {
        if (f.typeId==null ) {
            return f.caption;
        }

       var fname=fileTypes.find(x => x.id == f.typeId);
        if (fname == null) {
            return f.caption;
        }

        return fname.name;
       
    }



    const fetchUserData = () => {
        fetch("getuser?user=" + appData.basvuruYapan, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                //ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    var userdata = JSON.parse(data);
                    setUserdata(userdata);
                });
            }
        });
    }
    const fetchFiles = () => {
        fetch("getapplicationfiles", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(appData)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    setUploadedFiles(data)
                });
            }
        });
    }


    const fetchGeneralData = () => {
        fetch("getgeneraldata", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    setGData(data);
                    fetchAppDynamicFieldList();
                });
            }
        });
    }



    const toPdfJSPDF = () => {

        var doc = new jsPDF('p', 'pt', "a4");
        // doc.addFont("Arial", "Arial", "normal");
        doc.setFont("Arial", "normal");
        doc.setFontSize(14);
        var source = document.getElementById("pdfsource").cloneNode(true);
        source.className = "";
        var colElems = source.firstChild.getElementsByClassName("buttonCol");
        for (var i = colElems.length - 1; i >= 0; i--) {
            colElems[i].remove()
        }
        var colElems = source.firstChild.getElementsByClassName("checkCol");
        for (var i = colElems.length - 1; i >= 0; i--) {
            colElems[i].firstChild.className = "";
            colElems[i].firstChild.style["width"] = "12px";
        }

        colElems = source.firstChild.getElementsByClassName("dateCol");
        for (var i = colElems.length - 1; i >= 0; i--) {
            colElems[i].innerText = colElems[i].innerText.replace("/", "");
            colElems[i].innerText = colElems[i].innerText.replace("/", "  ");
            colElems[i].innerText = colElems[i].innerText.replace(":", " : ");
        }


        colElems = source.firstChild.getElementsByClassName("messageCol");
        if (colElems != null)
            for (var i = colElems.length - 1; i >= 0; i--) {
                colElems[i].parentNode.innerText = colElems[i].innerText;
            }

        source.innerHTML = changeTurkceToEnglis(source.innerHTML);

        source.style["font-size"] = "10px";
        source.style["font-family"] = "Arial";
        source.style["letter-spacing"] = "0.0px";
        // source = source.replaceAll("delete", "")
        doc.html(
            source, {
            'x': 35,
            'y': 0,

            width: 520,
            autoPaging: 'text',
            //margin:5,
            windowWidth: 520,
            margin: [40, 0, 40, 0],
            callback: function (pdf) {
                pdf.setFontSize(12);
                pdf.save('basvuru_ciktisi.pdf');
            }

        });
        // window.open(doc.output("bloburl"), "_blank");
        //doc.output("dataurlnewwindow");
    }




    var filename = appData.ada + "_" + appData.arsaSahibi;
    if (filename.indexOf('.') > 0)
        filename = filename.substring(0, filename.indexOf('.') - 1);

    var printbutton = <div className="d-flex justify-content-center mt-2 mb-2">
        <ReactToPdf targetRef={ref} x={15} y={10} scale={1} filename={filename}>
            {({ toPdf }) => (
                <button className="btn btn-primary btn-sm text-decoration-none" onClick={toPdf}><i className="	fa fa-print pe-2"></i>PDF Çıktısı</button>
            )}
        </ReactToPdf>
    </div>


    var appDynamicData = JSON.parse(appData.appData);
    const changeComponent = props.changeComponent;

    return (
        <div>
            <div className="row ">
                <div className="col-12 text-end">
                   
                        <ReactToPdf targetRef={ref} filename="pdf_file.pdf" x={10} y={10} scale={0.7} >
                            {({ toPdf }) => (
                                <button className="btn btn-primary btn-sm " onClick={toPdfJSPDF}>PDF Oluştur</button>
                            )}
                        </ReactToPdf>
                    
                  <button className="ms-2 btn btn-sm btn-primary rounded me-3" onClick={() => { changeComponent(<Application appData={appData} changeComponent={changeComponent} backButton={props.backButton} />) }}> <i className="fa fa-arrow-circle-left pe-1"></i> Geri Don</button>
  
                </div>



            </div>
            <div className="d-flex justify-content-center " >


                <div style={{ width: "520px" }} ref={ref} id="pdfsource">
                    <div className="d-flex justify-content-center mt-4 fw-bold">{gdata != null ? gdata.belediyeName.toUpperCase() : ""} BELEDİYE BAŞKANLIĞINA</div>
                    <div className="d-flex justify-content-center mb-3 mt-3">{getAppGrupName()}</div>
                    <div className="d-flex justify-content-center mb-3" >Aşağıda bilgileri bulunan parsele ilişkin ekte yer alan projenin
                        tarafınızca incelenerek gereğinin yapılması hususunu arz ederim.
                    </div>
                    <div className="mt-4 container border rounded border-dark" >

                        <div className="row border-bottom border-dark">
                            <div className="col-4 ">
                                Başvuru Numara
                            </div>
                            <div className="col-8 border-start border-dark">
                                {appData.id}
                            </div>
                        </div>

                        <div className="row border-bottom border-dark">
                            <div className="col-4 ">
                                Başvuru Tipi
                            </div>
                            <div className="col-8 border-start border-dark">
                                {appData.basvuruTipi}
                            </div>
                        </div>

                        <div className="row border-bottom border-dark">
                            <div className="col-4 ">
                                Başvuru Tarihi
                            </div>
                            <div className="col-8 border-start border-dark ">
                                {appData.basvuruTarihi}
                            </div>
                        </div>

                        <div className="row ">
                            <div className="col-4 ">
                                Başvuru Kabul Tarihi
                            </div>
                            <div className="col-8 border-start border-dark">
                                {appData.kabulEdilmeTarihi}
                            </div>
                        </div>

                    </div>
                    <div className="mt-4  container border rounded border-dark">
                        <div className="row border-bottom border-dark">
                            <div className="col-12 ">
                                <b>Adres Bilgileri</b>
                            </div>
                        </div>
                        <div className="row border-bottom border-dark">
                            <div className="col-4 ">
                                Mahalle
                            </div>
                            <div className="col-8 border-start border-dark">
                                {appData.mahalle} {appData.mevki}
                            </div>
                        </div>

                        <div className="row">

                            <div className="col-4 ">
                                Ada/Parsel
                            </div>
                            <div className="col-8 border-start border-dark">
                                {appData.ada}
                            </div>
                        </div>


                    </div>
                    <div className="mt-4  container border rounded border-dark">

                        <div className="row border-bottom border-dark">
                            <div className="col-12 ">
                                <b>Taşınmaz Sahibi Bilgileri</b>
                            </div>
                        </div>
                        <div className="row border-bottom border-dark">
                            <div className="col-4 ">
                                İsim Soyisim
                            </div>
                            <div className="col-8 border-start border-dark">
                                {appData.arsaSahibi}
                            </div>
                        </div>
                        <div className="row border-bottom border-dark">
                            <div className="col-4 ">
                                TC Kimlik No
                            </div>
                            <div className="col-8 border-start border-dark">
                                {appData.arsaSahibiTC}
                            </div>
                        </div>
                        <div className="row  border-dark">
                            <div className="col-4 ">
                                Telefon
                            </div>
                            <div className="col-8 border-start border-dark">
                                {appData.arsaSahibiTel}
                            </div>
                        </div>

                    </div>

                    <div className="mt-4  container border rounded border-dark">
                        {allDynamicFilds != null && appDynamicData != null &&
                            allDynamicFilds.map(k => k.printable == true &&
                                k.name != null &&
                                k.basvuruTipi != null && k.basvuruTipi.includes(appData.basvuruTipi) &&
                                <div className="row border-bottom border-dark">
                                    <div className="col-4 ">    {k.name}   </div>
                                    <div className="col-8 border-start border-dark">
                                        {appDynamicData[k.name] != null ? appDynamicData[k.name].value : ""}
                                    </div>
                                </div>)
                        }
                    </div>

                    <div className="mt-4 border rounded border-dark p-2">
                        {gdata != null ? gdata.yasalUyari : "Yukarıdaki iletişim bilgilerinin doğru olduğunu teyit eder, yanlış bilgilendirmeden dolayı oluşabilecek sorunlardan sorumlu olduğumu beyan eder ve Ruhsat Bilgilerinin yukarıda yazan numaralarla mesaj yoluyla paylaşacağını ve Menderes Belediyesinin Kişisel Verilerin Korunması ve İşlenmesi Şartlarını kabul ederim."}
                        <br />
                        {gdata != null ? gdata.kvkk : ""}

                    </div>





                    <div className="mt-4 container border rounded border-dark">

                        <div className="row border-bottom border-dark">
                            <div className="col-4 p-3 mb-3">
                                İmza
                            </div>
                            <div className="col-8 border-start border-dark">

                            </div>
                        </div>

                        <div className="row  border-bottom border-dark">
                            <div className="col-4">
                                İsim Soyisim
                            </div>
                            <div className="col-8 border-start border-dark">
                                {appData != null ? capitalizeWords(appData.vekilName) : ""}
                            </div>
                        </div>
                        <div className="row border-bottom border-dark">
                            <div className="col-4">
                                TC Kimlik Numarası
                            </div>
                            <div className="col-8 border-start border-dark">
                                {appData != null ? appData.vekilTC : ""}
                            </div>
                        </div>


                        <div className="row  border-dark">
                            <div className="col-4">
                                Cep Telefonunu
                            </div>
                            <div className="col-8 border-start border-dark">
                                {appData != null ? appData.vekilTel : ""}
                            </div>
                        </div>

                    </div>


                    <div className="mt-4 p-0 pb-5 container border-top border-dark">

                        Başvuruya eklenen dosyalrın listesi ekte yer almaktadır.
                    </div>


                    <div className="mt-5 mb-3  container border rounded border-dark">
                        <div className="row border-bottom border-dark mb-2">
                            <div className="col-12 fw-bold">
                                Eklenen Dosyalar
                            </div>
                        </div>
                        <div className="row">
                            {uploadedFiles.map((rec, idx) => <div className="col-6  p-0">   <span className="fw-bold "> {idx + 1}-</span>
                                {getFileName(rec)} 
                            </div>)}


                        </div>

                    </div>




                </div>
            </div>




            {/* {printbutton}*/}
        </div>


    );

}