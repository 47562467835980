import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { ajaxFail, isEmpty, showConfirmMessage, showSuccessMessage } from '../GlobalState.js';
import { typeOf } from 'react-is';

export default function SettingDenetim(props) {
    var postdata = {};
    const [listData, setListData] = useState([]);
    const [appTypeGroups, setappTypeGroups] = useState([]);
    const multiselectRef = React.createRef();

    const multiselectRefNew = React.createRef();

    const [showIsAdimlari, setShowIsAdimlari] = useState(false);
   


    const fetchAppTypeGrupList = () => {
        fetch("apptypegroupslist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setappTypeGroups(value);
                   
                })
            }
        });
    }


    const fetchDenetimList = () => {
        fetch("denetimlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }


    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.trim();
        const name = target.name;
        rec[name] = value;
        if ((name == "deadline" || name == "precondition") && value == '')
            rec[name] = null;
    }

    const renderTable = (tabledata) => {
        if (tabledata == null)
            return "";
        var mytable =
            <div className="table-responsive mainpagescroll" style={{ "height": "85vh" }} >
                <table className='table table-vdstriped  table-sm ' aria-labelledby="tabelLabel">
                    <thead style={{ "position": "sticky", "top": 0, "z-index":"1" }} className="bg-light ">
                        <tr>
                            {/* <th>ID</th>*/}
                            <th>Denetim Adı</th>
                            <th>Aktif</th>
                           
                            {showIsAdimlari == false && <th>Onay</th>}
                            {showIsAdimlari == false && <th>Ruhsat</th>}
                            {showIsAdimlari == false && <th>Dosya Kontrol</th>}
                            {showIsAdimlari == false &&<th>Hızlı Arşiv</th>}
                            {showIsAdimlari == false && <th>Koşul</th>}

                            <th>Kontrol Süre</th>
                            <th>Düzeltme Süre</th>
                            <th>Düzeltme Sayı</th>
                            {showIsAdimlari == false && <th>Grup</th>}
                            
                            {/*<th>Kayıt Tarihi</th>*/}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody id="myTable">
                        {tabledata.filter(x => (x.hide ==true)==showIsAdimlari).map(apprec =>
                            <tr key={apprec.id}>
                                {/* <td>{apprec.id}</td>*/}
                                <td>
                                    <input type="text" className="form-control form-control-sm  " style={{ "background-color": "transparent", "min-width": "200px" }} placeholder="Denetim Adı" name="Name" onChange={(e) => updateRecord(apprec, e)} defaultValue={apprec.name} />
                                </td>
                                <td>
                                    <input type="checkbox" className="form-check-input"  name="active" defaultChecked={apprec.active} onChange={(e) => updateRecord(apprec, e)} />
                                </td>
                                {showIsAdimlari == false && <td>
                                    <input type="checkbox" className="form-check-input" name="onaysureci" defaultChecked={apprec.onaysureci} onChange={(e) => updateRecord(apprec, e)} />
                                </td>}
                                {showIsAdimlari == false && <td>
                                    <input type="checkbox" className="form-check-input" name="ruhsatsureci" defaultChecked={apprec.ruhsatsureci} onChange={(e) => updateRecord(apprec, e)} />
                                </td>}
                                {showIsAdimlari == false && <td>
                                    <input type="checkbox" className="form-check-input" name="eksiksiz" defaultChecked={apprec.eksiksiz} onChange={(e) => updateRecord(apprec, e)} />
                                </td>}
                                {showIsAdimlari == false && <td>
                                    <input type="checkbox" className="form-check-input" name="hizliArsiv" defaultChecked={apprec.hizliArsiv} onChange={(e) => updateRecord(apprec, e)} />
                                </td>}
                                


                                {showIsAdimlari == false && <td style={{ "min-width": "200px" }}>
                                    <Multiselect className="p-0 ps-1 form-control-sm"
                                        options={listData}
                                        emptyRecordMsg="Başka Kayıt Yok"
                                        ref={multiselectRef}
                                        displayValue="name"
                                        isObject={true}
                                        onSelect={(selectedList, removedItem) => apprec["precondition"] = selectedList.length == 0 ? null : selectedList.map(rec => rec.id).join()} // Function will trigger on select event
                                        onRemove={(selectedList, removedItem) => apprec["precondition"] = selectedList.length == 0 ? null : selectedList.map(rec => rec.id).join()} // Function will trigger on remove event
                                        selectedValues={apprec.precondition == null ? [] : listData.filter(x => apprec.precondition.split(",").includes(x.id + ""))}
                                    />
                                </td>}
                                <td style={{ "min-width": "60px" }}><input type="number" className="form-control form-control-sm  " style={{ "background-color": "transparent" }} placeholder="" name="deadline" onChange={(e) => updateRecord(apprec, e)} defaultValue={apprec.deadline} /></td>
                                <td style={{ "min-width": "60px" }}><input type="number" className="form-control form-control-sm  " style={{ "background-color": "transparent" }} placeholder="" name="duzeltmeDeadline" onChange={(e) => updateRecord(apprec, e)} defaultValue={apprec.duzeltmeDeadline} /></td>
                                <td style={{ "min-width": "60px" }}><input type="number" className="form-control form-control-sm  " style={{ "background-color": "transparent" }} placeholder="" name="duzeltmeSayi" onChange={(e) => updateRecord(apprec, e)} defaultValue={apprec.duzeltmeSayi} /></td>
                                {showIsAdimlari == false && <td>
                                    <select className="form-select form-select-sm" name="grup" required={true} onChange={(e) => updateRecord(apprec, e)}>
                                        <option></option>
                                        {appTypeGroups.map(x => <option value={x.id} selected={x.id == apprec.grup}>{x.name}</option>)}
                                    </select>
                                </td>}

                                {/*<td>{apprec.creatorUserName}</td>*/}
                                {/*<td>{apprec.crerateTime}</td>*/}
                                <td>  <button onClick={() => { savedenetim(apprec); }} className="btn btn-link btn-sm text-decoration-none m-0 p-0"><i className="fa fa-floppy-o fs-6" aria-hidden="true" title="Güncelle"></i></button>
                                    &nbsp;&nbsp;
                                    <button onClick={() => {
                                        showConfirmMessage(apprec.hide==true?"Kayıt Başvuru Tiplerine Taşınacaktır":"Kayıt İş Adımlarına Taşınacaktır", "", "Devam Et", "İptal", () => {
                                        apprec.hide = apprec.hide == true ? false : true;
                                        savedenetim(apprec);
                                        })


                                    }} className="btn btn-link btn-sm text-decoration-none m-0 p-0"><i className={apprec.hide == true ? "fa fa-book  fs-6" : "fa fa-code-fork fs-6"} aria-hidden="true" title={apprec.hide == true ? "Başvuru Tiplerine Taşı" : "İş Adımlarına Taşı"}></i></button>
                                   &nbsp;&nbsp;  <button onClick={() => { sil(apprec); }} className="btn btn-link btn-sm text-decoration-none m-0 p-0"><i className="fa fa-trash text-danger fs-6" aria-hidden="true" title="Sil"></i></button>

                                </td>
                            </tr>
                        )}
                        <tr className="bg-white">
                            <td>
                                <input type="text" className="form-control form-control-sm" id="DenetimAdi" placeholder="Denetim Adı" name="Name" onChange={handleInputChange} />
                            </td>
                            <td>
                                <input type="checkbox" className="form-check-input" name="active" id="active" defaultChecked={false} onChange={handleInputChange} />
                            </td>
                            {showIsAdimlari == false && <td>
                                <input type="checkbox" className="form-check-input" name="onaysureci" id="onaysureci" defaultChecked={false} onChange={handleInputChange} />
                            </td>}
                            {showIsAdimlari == false && <td>
                                <input type="checkbox" className="form-check-input" name="ruhsatsureci" id="ruhsatsureci" defaultChecked={false} onChange={handleInputChange} />
                            </td>}
                            {showIsAdimlari == false && <td>
                                <input type="checkbox" className="form-check-input" name="eksiksiz" id="eksiksiz" defaultChecked={false} onChange={handleInputChange} />
                            </td>}
                            {showIsAdimlari == false && <td>
                                <input type="checkbox" className="form-check-input" name="hizliArsiv" id="hizliArsiv" defaultChecked={false} onChange={handleInputChange} />
                            </td>}

                            {showIsAdimlari == false && <td>
                                <Multiselect className="p-0 ps-1 form-control-sm bg-white"
                                    options={listData}
                                    emptyRecordMsg="Başka Kayıt Yok"
                                    displayValue="name"
                                    isObject={true}
                                    ref={multiselectRefNew}
                                    onSelect={(selectedList, removedItem) => { postdata["precondition"] = selectedList.length == 0 ? null : selectedList.map(rec => rec.id).join() }} // Function will trigger on select event
                                    onRemove={(selectedList, removedItem) => { postdata["precondition"] = selectedList.length == 0 ? null : selectedList.map(rec => rec.id).join() }} // Function will trigger on remove event
                                />
                            </td>}
                            <td>
                                <input type="number" className="form-control form-control-sm" id="deadline" placeholder="" name="deadline" onChange={handleInputChange} />
                            </td>
                            <td>
                                <input type="number" className="form-control form-control-sm" id="duzeltmeDeadline" placeholder="" name="duzeltmeDeadline" onChange={handleInputChange} />
                            </td>
                            <td>
                                <input type="number" className="form-control form-control-sm" id="duzeltmeSayi" placeholder="" name="duzeltmeSayi" onChange={handleInputChange} />
                            </td>
                            {showIsAdimlari == false && <td>
                                <select className="form-select form-select-sm" name="grup" id="grup" required={true} onChange={handleInputChange}>
                                    <option></option>
                                    {appTypeGroups.map(x => <option value={x.id}>{x.name}</option>)}
                                </select>
                            </td>}

                            {/*<td></td>*/}
                            {/*<td></td>*/}
                            <td>  <button onClick={() => savedenetim(null)} className="btn btn-sm btn-link text-decoration-none m-0 p-0"><i class="fa fa-plus-circle fs-5" aria-hidden="true" title="Ekle"></i></button>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        return (mytable);
    }

    const sil = (rec) => {
        var r = rec;
       
        showConfirmMessage(rec.name + " İsimli Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removedenetim", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(rec)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response)
                }
                else {
                    response.json().then(value => {
                        setListData(value);
                    })
                }
            }));

    }



    const savedenetim = (rec) => {
        var r = rec;
        if (r == null) {
            postdata.hide = showIsAdimlari;
            r = postdata;
        }

        fetch("savedenetim", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(r)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                if (rec != null) {
                    showSuccessMessage("Dentim Güncellendi. ")
                    setListData([...listData ]);
                }
                else {
                    fetchDenetimList();
                    if (document.getElementById("DenetimAdi"))document.getElementById("DenetimAdi").value = "";
                    if (document.getElementById("active")) document.getElementById("active").checked = false;
                    if (document.getElementById("onaysureci")) document.getElementById("onaysureci").checked = false;
                    if (document.getElementById("ruhsatsureci")) document.getElementById("ruhsatsureci").checked = false;
                    if (document.getElementById("hide")) document.getElementById("hide").checked = false;
                    if (document.getElementById("eksiksiz")) document.getElementById("eksiksiz").checked = false;
                    if (document.getElementById("deadline")) document.getElementById("deadline").value = "";
                    if (document.getElementById("duzeltmeDeadline")) document.getElementById("duzeltmeDeadline").value = "";
                    if (document.getElementById("duzeltmeSayi")) document.getElementById("duzeltmeSayi").value = "";
                    if (document.getElementById("hizliArsiv")) document.getElementById("hizliArsiv").checked = false;
                    if (document.getElementById("grup")) document.getElementById("grup").selectedIndex = -1;
                    if (multiselectRefNew.current) multiselectRefNew.current.resetSelectedValues();
                    postdata = {};
                   
                }
            }
        });

    }

    const handleInputChange = (event) => {
        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value;
        if (target.type === 'text' && isEmpty(value) == false)
            value = value.trim();

        postdata[target.name] = value;

        if (target.name == "deadline" && value == '')
            postdata[target.name] = null;

    }


    useEffect(() => {
        fetchDenetimList();
        fetchAppTypeGrupList();
    }, [])



    return (
        <div className="">
            <ul className="nav nav-tabs nav-pills">
                <li className="nav-item">
                    <a className="nav-link active" id="basvurTipleri" aria-current="page" href="#" onClick={(e) => {
                        document.getElementById("isAdimlari").classList.remove('active');
                        document.getElementById("basvurTipleri").classList.add('active');
                        setShowIsAdimlari(false);
                    }}>Başvuru Tipleri <i className={"fa fa-book  fs-6"} aria-hidden="true" ></i></a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#" id="isAdimlari"
                        onClick={(e) => {
                            document.getElementById("basvurTipleri").classList.remove('active');
                            document.getElementById("isAdimlari").classList.add('active');
                            setShowIsAdimlari(true);
                        }}
                    >İş Adımları <i className={"fa fa-code-fork fs-6"} aria-hidden="true" ></i></a>
                </li>
            </ul>
            <div className="row">
                <div className="col-12" >
                    {renderTable(listData)}
                </div>
            </div>
        </div>

    );


}