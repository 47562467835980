import React, { useState, useEffect } from 'react';
import { ajaxFail } from '../GlobalState.js';
import EImzaListem from './EImzaListem';


export default function EImzaMenu(props) {



    const [eimzalist, setEimzaList] = React.useState(null);

    useEffect(() => {
        getEimzaList();
    }, []);



    const getEimzaList = () => {
        fetch("getmyeimzalist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    if (data != null) {
                        data.forEach(r => r.imza = JSON.parse(r.iadeNedeni));
                    }
                    data = data.filter(r => r.imza.transactionUUID == " imzam bekleniyor");
                    
                    setEimzaList(data);
                });
            }
        });
    }

    var menutxt = "";
    if (eimzalist != null && eimzalist.length > 0) {
        menutxt = <span id="eimzalistemcount" class="badge rounded-pill bg-primary" > {eimzalist.length}</span>;
    }


    return <a href="#" onClick={() => { props.changeComponent(<EImzaListem eimzalist={eimzalist} changeComponent={props.changeComponent} />) }}> <i className="fa fa fa-pencil-square-o ps-4"></i> E-İmza Bekleyenler {menutxt}</a>
}
