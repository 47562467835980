import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import $ from 'jquery';
import Application from "../Application/Application.js"
import ApplicationList from "../Application/ApplicationList.js"
import { ajaxFail } from '../GlobalState.js';

export default function MessagePanel(props) {
    let location = useLocation();
    const [messages, setMsg] = useState(props.messages);
    const [activemessage, setActiveMsg] = useState("");
    const [showall, setShowAll] = useState(true);
    const me = <ApplicationList changeComponent={props.changeComponent} />
    const getMessageList = () => {
        fetch("getmymessages", {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(async data => {
                    setMsg(data);
                });
            }

        });
    }


    useEffect(() => {
        getMessageList();
    }, [])

    const getapp = (id) => {
        fetch("getapp", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(id)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    props.changeComponent(<Application appData={data} changeComponent={props.changeComponent} backButton={me} />)
                });
            }
        });
    }


    var sidebartags = [];
    sidebartags.push(<a className="d-block  p-2 m-2  rounded-3  text-decoration-none" href="javascript:void(0)" onClick={() => setShowAll(true)}>Tum Mesajlar</a>);
    sidebartags.push(<a className="d-block  p-2 m-2  rounded-3  text-decoration-none" href="javascript:void(0)" onClick={() => setShowAll(false)}>Okunmamış Mesajlar</a>);

    var cc =0;
    if (messages != null) {
        for (let i = 0; i < messages.length; i++)
            if (messages[i]!=null && messages[i].opened != true)
                cc++;
        document.getElementById("unreadmessagecount").innerHTML = cc;
    }

    const showMessage = m => {
        markmessagesopen(m);
        setActiveMsg(
            <div className="m-2">
                <div className="row">
                    <div className="col float-start"><b>{m.title}</b></div>
                    <div className="col float-end text-end">{m.sendDate}</div>
                </div>
                <div className="row mt-1">
                    <div className="col float-start"><img src={"/getuserpic?user=" + m.sender} id="profileimage" style={{ width: "32px" }} className="rounded-pill p-0 m-0" /> {m.receiver} ({m.sender})</div>
                   
                    {m.appID != null && <div className="col float-end text-end">
                        <button type="button" className="btn btn-primary  btn-sm" onClick={() => { getapp(m.appID) }}>Başvuruya Git</button>
                    </div>}
                </div>

                <div className="mt-3" style={{ "white-space":"pre-line"}}>{m.body}</div>
            </div>
        )
       
    }



    const deleteMessage = m => {
        fetch("deletemessage", {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(m.id)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(async data => {
                    setMsg(data);
                });
            }
        });
    }

    const markmessagesopen = (msg) => {
        fetch("markmessagesopen", {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(msg)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(async data => {
                    setMsg(data);
                    
                });
            }
        });
        return true;
    }

    const filterTable = event => {
        const target = event.target;
        const value = target.value.toLowerCase();
        $("#myTable tr").filter(function () {
            var keywords = value.split(" ");
            for (let index = 0; index < keywords.length; ++index)
                if ($(this).text().toLowerCase().indexOf(keywords[index]) == -1)
                    return $(this).toggle(false);
            return $(this).toggle(true);
        });
    }

    var messageList =
        <div className="mt-1 ">
            <div className="mb-1 w-100  text-center align-items-center justify-content-center ">
                <input className="form-control w-100" id="myInput" type="text" placeholder="Arama..." onKeyUp={filterTable} />
            </div>
            <div className="overflow-auto" style={{ maxHeight:600 }}>
            
            <table className='table table-striped table-sm' aria-labelledby="tabelLabel">
                <tbody id="myTable">
                    {messages.map(m => (showall == true || m.opened!=true)?<tr key={m.id}>
                        <td width="90%">
                            <span style={{ "font-size": "12px" }} className="text-muted">{m.sendDate}</span><br />
                            <a href="/applicationcard" className="text-decoration-none" onClick={() => showMessage(m)} href="javascript:void(0)">{m.opened == true ?  m.title : <b>{m.title} <span id="unreadmessagecount" class="badge rounded-pill bg-success" >yeni</span></b>}</a>
                            </td>
                            <td width="10%"> <a className="text-decoration-none" onClick={() => deleteMessage(m)} href="javascript:void(0)">sil</a>
                            </td>
                        </tr>:null
                    )}
                </tbody>
                </table>
            </div>
        </div>





    return <div className="row" >
        {/*<div className="col-2 bg-dark " >*/}
        {/*    <SideBar tags={sidebartags}  panel={[]} />*/}


        {/*</div>*/}
        <div >
            <div className="align-items-center justify-content-center" >
                <div className="row p-2">
                    <div className="col-12">
                        <a href="#" className="text-decoration-none me-3" onClick={() => { deleteMessage({ id: -1 }) }}> Tümünü Sil</a>
                        <a href="#" className="text-decoration-none" onClick={() => { markmessagesopen({id:-1}) }}> Tümünü Okundu Yap</a>
                    </div>
                </div>

                <div className="row p-2">
                    <div className="col-4 bg-light rounded p-2">
                        {messageList}
                    </div>
                    <div className="col-7 ms-4  bg-light p-2 rounded">
                        {activemessage}
                    </div>
             
                </div>

            </div>
        </div>
    </div>





}


