import React, { useState, useEffect } from 'react';
import { useGlobalState, ajaxFail } from '../GlobalState.js';


export default function Statistic(props) {

    const [role, setRole] = useGlobalState('role');



    const drawChart = (dataarray, target, ftitle) => {
        var data = window.google.visualization.arrayToDataTable(dataarray);
        var options = {
            chartArea: {
                // leave room for y-axis labels
                width: '80%'
            },
            height: 275,
            width: '100%',
            title: ftitle,
            legend: { position: 'none' },
            titleTextStyle: {
                color: '#0098db',
                fontSize: 22,
                bold: true
            },

        };
        var chart = new window.google.visualization.ColumnChart(document.getElementById(target));
        chart.draw(data, options);

    }

    useEffect(() => {
        if (role.indexOf("Admin") > -1) {
            const script = document.createElement("script");
            script.src = "https://www.gstatic.com/charts/loader.js";
            script.async = true;
            document.getElementById("myscript").appendChild(script);
            script.onload = () => {
                window.google.charts.load("current", { packages: ['corechart'] });
                window.google.charts.setOnLoadCallback(populateProjectData);
            }
        }

    }, [])


    const populateProjectData = () => {
        fetch("countprojectbytype", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {

                response.json().then(async data => {
                    data = JSON.parse(data);
                    var dataarray = [];
                  //  "#fff8dd", "#e8fff3", "#fff5f8", "#f8f5ff"
                    var colors = ['#2E4C6D', '#396EB0', '#DADDFC', '#FC997C', '#66806A', '#7E8BD9', '#558D97', '#B4C6A6', '#FFC286', '#FFF1AF'];
                    dataarray.push(['Durum', 'Toplam', { role: "style" }]);

                    var temp = {};

                    for (var i = 0; i < data.length; i++) {
                        if (temp[data[i].BasvuruDurumu] == null)
                            temp[data[i].BasvuruDurumu] = data[i].Toplam;
                        else
                            temp[data[i].BasvuruDurumu] = temp[data[i].BasvuruDurumu] + data[i].Toplam;
                       
                    }
                    var cidx = 0;
                    for (var i in temp) {
                        dataarray.push([i, temp[i], colors[cidx++]]);
                    }

                    drawChart(dataarray, 'columnchart_material', 'Proje Başvuru Durumları');
                    populateUserData();
                });
            }
        });
    }

    const populateUserData = () => {
        fetch("countuserbymission", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    data = JSON.parse(data);

                    var dataarray = [];
                    var colors = ['#2E4C6D', '#396EB0', '#DADDFC', '#FC997C', '#66806A', '#7E8BD9', '#558D97', '#B4C6A6', '#FFC286', '#FFF1AF'];
                    dataarray.push(['Durum', 'Toplam', { role: "style" }]);
                    for (var i = 0; i < data.length; i++) {
                        dataarray.push([data[i].userName, data[i].Toplam, colors[i]]);
                    }

                    drawChart(dataarray, 'kullanıcilarpanel', 'Görev Durumları')
                });
            }
        });
    }


    //appData.basvuruDurumu != "Kontrol Ediliyor"
    return (
        <div  >
            <div id="myscript"></div>
            <div className="row ">
                <div className="col-6 ps-2">
                    <div id="columnchart_material"></div>
                </div>
                <div className="col-6  ps-1 ">
                    <div id="kullanıcilarpanel"></div>
                </div>
            </div>
        </div>
    );
}





