import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import MessageMenu from './MessageMenu.js';
import EImzaMenu from '../Application/EImzaMenu.js';
import SettingDenetim from '../Setting/SettingDenetim.js';
import FileSettingList from '../Setting/FileSettingList.js';
import ListSetting from '../Setting/ListSetting.js';
import DocumentSetting from '../Setting/MevzuaatSetting.js';
import AppSettingPanel from '../Setting/BasvuruFormuSetting.js';
import GeneralSettingPanel from '../Setting/GeneralSettingPanel.js';
import AppTypePanel from '../Setting/UserTypePanel.js';
import SureclerPanel from '../Setting/SureclerPanel.js';
import RegisterSettingPanel from '../Setting/RegisterSettingPanel.js';
import DuyuruSetting from '../Setting/DuyuruSetting.js';
import HelpSettingPanel from '../Setting/HelpSettingPanel.js';
import AdaSetting from '../Setting/AdaSetting.js';
import MevkiSetting from '../Setting/MevkiSetting.js';
import ReportSetting from '../Setting/ReportSetting.js';
import SettingRoles from '../Setting/SettingRoles.js';
import Apply1 from "../Application/Apply1.js";
import ApplicationList from "../Application/ApplicationList.js";
import ApplicationListCardView from "../Application/ApplicationListCardView.js";
import AllApplicationList from '../Application/AllApplicationList.js';
import HavaleSablonSetting from '../Setting/HavaleSablonSetting.js';
import UserAppCard from "../Application/UserAppCard.js";
import IadeListesi from "../Application/IadeListesi.js";


import SharedApplicationList from '../Application/SharedApplicationList.js';
import UserList from "../User/UserList.js";
import UserWorkLoad from '../User/UserWorkLoad.js';
import Register1 from "../User/Register1.js";
import * as UserPermConstants from '../User/UserPermConstants';
import AddressBook from "../Dashboard/AddressBook.js";
import AppGroupSetting from '../Setting/AppGroupSetting.js';
import SettingIdariMahalle from '../Setting/SettingIdariMahalle.js';

import DelayedApplications from '../Application/DelayedApplications.js';
import TakipEdilenler from '../Application/TakipEdilenler.js';
import { useGlobalState } from '../GlobalState.js';
import StatisticPanel from '../Statistic/StatisticPanel.js';
import HelpPanel from '../Dashboard/HelpPanel.js';

import Home from "../Dashboard/Home";
import SabitSoruSetting from '../Setting/SabitSoruSetting.js';
import SablonFileSetting from '../Setting/SablonFileSetting.js';
import FileGroupsSetting from '../Setting/FileGroupsSetting.js';
import EimzaNameSetting from '../Setting/EimzaNameSetting.js';
import AppTypeGroupSetting from '../Setting/AppTypeGroupSetting.js';
import CBSMahalleSetting from '../Setting/CBSMahalleSetting.js';
import CBSAdaSetting from '../Setting/CBSAdaSetting.js';
import MakaleSetting from '../Setting/MakaleSetting.js';
import SampleFormSetting from '../Setting/SampleFormSetting.js';
import MevzuatPanel from '../Dashboard/MevzuatPanel.js';
import DeletedAppList from '../Application/DeletedAppList.js';
import ArchivedAppList from '../Application/ArchivedAppList.js';
import MaksList from '../MAKS/MaksList.js';
import KadastralVeri from '../Application/KadastralVeri.js';
import RemovedFiles from '../Application/RemovedFiles.js';

export default function CommonSideBar(props) {
    var panel = props.panel;
    var tags = props.tags;



    const [role, setRole] = useGlobalState('role');
    const [userName, setUserName] = useGlobalState('userName');

    useEffect(() => {

    }, [role]);


    var list = [];
    if (panel != null)
        list.push(panel.map(p => <Link to={p.target} state={p.state} className={"d-block  p-2 m-2 text-decoration-none rounded-3 "} >
            <i style={{ "font-size": "16px" }} class={p.icon}></i>&nbsp;&nbsp;{p.name}</Link>));

    if (tags != null)
        list.push(tags.map(p => <div > {p}</div>));

    var kullanicimenu = (role.indexOf(UserPermConstants.Yeni_Kullanici_Kaydetme) > -1 || role.indexOf(UserPermConstants.Kullanici_Listesini_Gorme_Profil_Bilgilerini) > -1 ||
        role.indexOf(UserPermConstants.Kullanici_Tipleri) > -1 || role.indexOf(UserPermConstants.Kullanici_Kayit_Formu) > -1 ||
        role.indexOf(UserPermConstants.Kullanici_Rolleri) > -1 || role.indexOf(UserPermConstants.Is_Yuku) > -1)

    const changeComponent = (name) => {
        props.setComponent(name);

    }



    return <div className="sidebar" key={role}>

        <div>
            <a href="#" onClick={() => { changeComponent(<Home changeComponent={changeComponent} />) }}>
                <i className="fa fa-home"></i>
                Ana Ekran
            </a>
        </div>

        <div>
            <a data-bs-toggle="collapse" data-bs-target="#basvuruoptions" href="#">
                <i className="fa fa-clone"></i>  Başvurular</a>
        </div>
        <div className="collapse" id="basvuruoptions">
            <div>
                <a href="#" onClick={() => { changeComponent(<Apply1 changeComponent={changeComponent} />) }}> <i className="fa fa-folder-o ps-4"></i> Yeni Başvuru</a>
            </div>

            <div >
                <a href="#" onClick={() => { changeComponent(<ApplicationList changeComponent={changeComponent} role={role} />) }}> <i className="fa fa-clone ps-4"></i> Liste Görünümü</a>
            </div>
            <div>
                <a href="#" onClick={() => { changeComponent(<ApplicationListCardView changeComponent={changeComponent} role={role} userName={userName} />) }}> <i className="fa fa-th-large ps-4"></i> Kart Görünümü</a>
            </div>
            <div >
                <a href="#" onClick={() => { changeComponent(<DeletedAppList changeComponent={changeComponent} role={role} />) }}> <i className="fa fa-trash-o ps-4"></i> Silinen Başvurular</a>
            </div>
            {(userName.toLowerCase() == "admin" || userName.toLowerCase() == "a"|| userName.toLowerCase() == "universe")&& < div >
                <a href="#" onClick={() => { changeComponent(<RemovedFiles changeComponent={changeComponent} role={role} />) }}> <i className="fa fa-trash-o ps-4"></i> Silinen Dosyalar</a>
            </div>}
            
            <div >
                <a href="#" onClick={() => { changeComponent(<ArchivedAppList changeComponent={changeComponent} role={role} />) }}> <i className="fa fa-archive ps-4"></i> Arşivlenen Başvurular</a>
            </div>

            {role.indexOf(UserPermConstants.Is_Yuku) > -1 && <div>
                <a href="#" onClick={() => { changeComponent(<UserAppCard changeComponent={changeComponent} role={role} userName={userName} />) }}> <i className="fa fa-group ps-4"></i> İş Yükü Görünümü</a>
            </div>}

            {role.indexOf(UserPermConstants.MAKS_Veri_Girisi) >= 0 && <div >
                <a href="#" onClick={() => { changeComponent(<MaksList changeComponent={changeComponent} role={role} />) }}> <i className="fa fa-bank ps-4"></i>MAKS Başvuruları</a>
            </div>}

            <div>
                <a href="#" onClick={() => { changeComponent(<SharedApplicationList changeComponent={changeComponent} />) }}> <i className="fa fa-share-alt ps-4"></i> Paylaşılan Başvurular</a>
            </div>
            <div>
                <a href="#" onClick={() => { changeComponent(<AllApplicationList changeComponent={changeComponent} />) }}> <i className="fa fa fa-cubes ps-4"></i> Tüm Görevler</a>
            </div>
            <div >
                {role.indexOf(UserPermConstants.Tum_Geciken_Basvurular) > -1 &&
                    <a href="#" onClick={() => { changeComponent(<DelayedApplications changeComponent={changeComponent} role={role} />) }}> <i className="fa fa-clone ps-4"></i> Geciken Başvurular</a>
                }
            </div>
            <div >
                <a href="#" onClick={() => { changeComponent(<TakipEdilenler changeComponent={changeComponent} role={role} />) }}> <i className="fa fa-clone ps-4"></i> Takip Edilen Başvurular</a>
            </div>

            <div >
                <a href="#" onClick={() => { changeComponent(<IadeListesi changeComponent={changeComponent} role={role} />) }}> <i className="fa fa-clone ps-4"></i> İade Listesi</a>
            </div>


            <div>
                <EImzaMenu changeComponent={changeComponent} />
            </div>

        </div>
        {(role.indexOf(UserPermConstants.Adres_Defterini_Duzenleme) > -1 || role.indexOf(UserPermConstants.Adres_Defterini_Gorme) > -1) &&
            <div>
                <a href="#" onClick={() => { changeComponent(<AddressBook changeComponent={changeComponent} />) }}> <i className="fa fa-id-card"></i> Adres Defteri</a>
            </div>
        }



        <div>
            <MessageMenu changeComponent={changeComponent} />
        </div>

        {(role.indexOf(UserPermConstants.Kontrolor_Istatistiklerini_Gorme) > -1 || role.indexOf(UserPermConstants.Proje_Istatistiklerini_Gorme) > -1
            || role.indexOf(UserPermConstants.Muellif_Istatistiklerini_Gorme) > -1 || role.indexOf(UserPermConstants.Mekansal_Istatistikleri_Gorme) > -1
            || role.indexOf(UserPermConstants.Zamansal_Istatistikleri_Gorme) > -1 || role.indexOf(UserPermConstants.Anlik_Istatistikleri_Gorme) > -1
            || role.indexOf(UserPermConstants.Anlik_Istatistikleri_Gorme) > -1
        ) && <div>
                <a href="#" onClick={() => { changeComponent(<StatisticPanel />) }}> <i className="fa fa-bar-chart"></i> İstatistik</a>
            </div>}

        <div>
            <a href="#" onClick={() => { changeComponent(<MevzuatPanel />) }}> <i className="fa fa-bank"></i> Mevzuat</a>
        </div>

        {role.indexOf(UserPermConstants.Tarife_Cetveli) > -1 && < div >
            <a href="#" onClick={() => { }}> <i className="fa fa-dollar"></i> Tarife Cetveli</a>
        </div>}


        <div>

        </div>








        {/* ----------------------------------------------------------------------*/}


        {(role.indexOf(UserPermConstants.Basvuru_Formu) > -1 || role.indexOf(UserPermConstants.Dosyalar) > -1
            || role.indexOf(UserPermConstants.Ekran_Yonetimi) > -1 || role.indexOf(UserPermConstants.Surecler) > -1
            || role.indexOf(UserPermConstants.Kadastral_Ayarlar) > -1) && <div>
                <a data-bs-toggle="collapse" data-bs-target="#basvuruayarlaroptions" href="#">
                    <i className="fa fa-gears"></i>Başvuru Yönetimi</a></div>}

        <div className="collapse" id="basvuruayarlaroptions">
            {role.indexOf(UserPermConstants.Basvuru_Formu) > -1 && <div >
                <a href="#" onClick={() => { changeComponent(<SettingDenetim />) }}> <i className="fa fa-edit ps-4"></i> Başvuru Tipleri</a>
            </div>}
            {role.indexOf(UserPermConstants.Basvuru_Formu) > -1 && <div >
                <a href="#" onClick={() => { changeComponent(<AppTypeGroupSetting />) }}> <i className="fa fa-edit ps-4"></i>Başvuru Tipi Gurupları</a>
            </div>}
            {role.indexOf(UserPermConstants.Basvuru_Formu) > -1 && <div >
                <a href="#" onClick={() => { changeComponent(<AppSettingPanel />) }}> <i className="fa fa-files-o ps-4"></i> Başvuru Formu</a>
            </div>}
            {/*<div >*/}
            {/*    <a href="#" onClick={() => { }}> <i className="fa fa-files-o ps-4"></i> Başvuru Standartları</a>*/}
            {/*</div>*/}
            {role.indexOf(UserPermConstants.Ekran_Yonetimi) > -1 && <div >
                <a href="#" onClick={() => { changeComponent(<ListSetting />) }}> <i className="fa fa-server ps-4"></i>Ekran Yönetimi</a>
            </div>}
            {role.indexOf(UserPermConstants.Dosyalar) > -1 && <div >
                <a href="#" onClick={() => { changeComponent(<FileSettingList />) }}> <i className="fa fa-folder-open-o ps-4"></i>Dosyalar</a>
            </div>}
            {role.indexOf(UserPermConstants.Dosyalar) > -1 && <div >
                <a href="#" onClick={() => { changeComponent(<SablonFileSetting />) }}> <i className="fa fa-folder-open-o ps-4"></i>Şablon Dosyalar</a>
            </div>}

            {role.indexOf(UserPermConstants.Dosyalar) > -1 && <div >
                <a href="#" onClick={() => { changeComponent(<FileGroupsSetting />) }}> <i className="fa fa-folder-open-o ps-4"></i>Dosyalar Grubu</a>
            </div>}



            {/*<div >*/}
            {/*    <a href="#" onClick={() => { changeComponent(<MevkiSetting />) }}> <i className="fa fa-map-marker ps-4"></i>Mevki</a>*/}
            {/*</div>*/}
            {/*<div >*/}
            {/*    <a href="#" onClick={() => { changeComponent(<MahalleSetting />) }}> <i className="fa fa-map ps-4"></i>Mahalle</a>*/}
            {/*</div>*/}
            {/*<div >*/}
            {/*    <a href="#" onClick={() => { changeComponent(<AdaSetting />) }}> <i className="fa fa-home ps-4"></i>Ada/Parsel</a>*/}
            {/*</div>*/}
            {role.indexOf(UserPermConstants.Surecler) > -1 && <div >
                <a href="#" onClick={() => { changeComponent(<SureclerPanel />) }}> <i className="fa fa-refresh ps-4"></i> Süreçler</a>
            </div>}
            {role.indexOf(UserPermConstants.Surecler) > -1 &&<div >
                <a href="#" onClick={() => { changeComponent(<AppGroupSetting />) }}> <i className="	fa fa-credit-card ps-4"></i>Başvuru Grupları</a>
            </div>}
            {role.indexOf(UserPermConstants.Basvuru_Formu) > -1 && <div >
                <a href="#" onClick={() => { changeComponent(<SabitSoruSetting />) }}> <i className="fa fa-question ps-4"></i>Sabit Sorular</a>
            </div>}
            {role.indexOf(UserPermConstants.Basvuru_Formu) > -1 && <div >
                <a href="#" onClick={() => { changeComponent(<HavaleSablonSetting />) }}> <i className="fa fa-dedent ps-4"></i>Havale Sablonu</a>
            </div>}

            {role.indexOf(UserPermConstants.Kadastral_Ayarlar) > -1 && <div >
                <a href="#" onClick={() => { changeComponent(<KadastralVeri changeComponent={changeComponent} />) }}> <i className="fa fa-dedent ps-4"></i> Kadastral Veri</a>
            </div>}



            {role.indexOf(UserPermConstants.Basvuru_Formu) > -1 && <div>
                <a href="#" onClick={() => { changeComponent(<EimzaNameSetting changeComponent={changeComponent} />) }}> <i className="fa fa-bookmark ps-4"></i>Eimza</a>
            </div>}
        </div >

        {(role.indexOf(UserPermConstants.Kullanici_Listesini_Gorme_Profil_Bilgilerini) > -1 || role.indexOf(UserPermConstants.Kullanici_Tipleri) > -1
            || role.indexOf(UserPermConstants.Kullanici_Kayit_Formu) > -1 || role.indexOf(UserPermConstants.Kullanici_Rolleri) > -1
            || role.indexOf(UserPermConstants.Is_Yuku) > -1) &&
            <div>  <a data-bs-toggle="collapse" data-bs-target="#usersayarlaroptions" href="#">
                <i className="fa fa-gears"></i>  Kullanıcı Yönetimi </a> </div >}



        <div className="collapse" id="usersayarlaroptions">
            {role.indexOf(UserPermConstants.Kullanici_Kayit_Formu) > -1 && <div >
                <a href="#" onClick={() => { changeComponent(<Register1 changeComponent={changeComponent} />) }}> <i className="fa fa-user-plus ps-4"></i> Yeni Kullanıcı</a>
            </div>}
            {role.indexOf(UserPermConstants.Kullanici_Listesini_Gorme_Profil_Bilgilerini) > -1 && <div >

                <a href="#" onClick={() => { changeComponent(<UserList changeComponent={changeComponent} />) }}> <i className="fa fa-users ps-4"></i> Kullanıcılar Listesi</a>
            </div>}

            {role.indexOf(UserPermConstants.Kullanici_Tipleri) > -1 && <div >
                <a href="#" onClick={() => { changeComponent(<AppTypePanel />) }}> <i className="fa fa-map-signs ps-4"></i> Kullanıcı Tipi</a>
            </div>}

            {role.indexOf(UserPermConstants.Kullanici_Kayit_Formu) > -1 && <div >
                <a href="#" onClick={() => { changeComponent(<RegisterSettingPanel />) }}> <i className="fa fa-address-card-o ps-4"></i>Kullanıcı Kayıt Formu</a>
            </div>}

            {role.indexOf(UserPermConstants.Kullanici_Rolleri) > -1 && <div >
                <a href="#" onClick={() => { changeComponent(<SettingRoles />) }}> <i className="fa fa-lock ps-4"></i>Kullanıcı Rolleri</a>
            </div>}
            {role.indexOf(UserPermConstants.Is_Yuku) > -1 && <div >
                <a href="#" onClick={() => { changeComponent(<UserWorkLoad changeComponent={changeComponent} />) }}> <i className="fa fa-bar-chart ps-4"></i> İş Yuku</a>
            </div>}
        </div >





        {/*<div >*/}
        {/*    <a href="#" onClick={() => { changeComponent(<FollowSetting />) }}> <i className="fa fa-bell-o ps-4"></i>Bidirimler</a>*/}
        {/*</div>*/}




        {/*<div >*/}
        {/*    <a href="#" onClick={() => { changeComponent(<DocumentSetting />) }}> <i className="fa fa-info-circle"></i>Yönetmelik</a>*/}
        {/*</div>*/}




        {(role.indexOf(UserPermConstants.Duyuru_Yonetimi) > -1 || role.indexOf(UserPermConstants.Kurum_Bilgileri) > -1
            || role.indexOf(UserPermConstants.Yonetmelik_Yukleme) > -1 || role.indexOf(UserPermConstants.Yardim_Ekranlari) > -1
            || role.indexOf(UserPermConstants.Is_Yuku) > -1) && <div>
            <a data-bs-toggle="collapse" data-bs-target="#ayarlaroptions" href="#">
                <i className="fa fa-gears"></i>  Ayarlar</a>
        </div>}
        <div className="collapse" id="ayarlaroptions">
            {role.indexOf(UserPermConstants.Duyuru_Yonetimi) > -1 && <div >
                <a href="#" onClick={() => { changeComponent(<DuyuruSetting />) }}> <i className="fa fa-comments-o ps-4"></i>Duyuru Yönetimi</a>
            </div>}
            {/*{role.indexOf(UserPermConstants.Megsis_Ayarlar) > -1 && <>*/}
            {/*    <div >*/}
            {/*        <a href="#" onClick={() => { changeComponent(<CBSMahalleSetting changeComponent={changeComponent} />) }}> <i className="fa fa-map-marker ps-4"></i>CBS Mahalleler</a>*/}
            {/*    </div>*/}
                <div >
                    <a href="#" onClick={() => { changeComponent(<ReportSetting changeComponent={changeComponent} />) }}> <i className="fa fa-map-marker ps-4"></i>Raporlar</a>
                </div>
           {/* </>*/}
            {role.indexOf(UserPermConstants.Yardim_Ekranlari) > -1 && <div >
                <a href="#" onClick={() => { changeComponent(<HelpSettingPanel />) }}> <i className="fa fa-info-circle ps-4"></i>Yardım</a>
            </div>}
            {role.indexOf(UserPermConstants.Kurum_Bilgileri) > -1 && <div >
                <a href="#" onClick={() => { changeComponent(<GeneralSettingPanel />) }}> <i className="fa fa-reorder ps-4"></i>Kurum Ayarları</a>
            </div>}
            {/*<div >*/}
            {/*    <a href="#" onClick={() => { changeComponent(<SettingIdariMahalle />) }}> <i className="fa fa-map ps-4"></i>Idari Mahalle</a>*/}
            {/*</div>*/}

            {role.indexOf(UserPermConstants.Yonetmelik_Yukleme) > -1 && <div >
                <a href="#" onClick={() => { changeComponent(<DocumentSetting />) }}> <i className="fa fa-bank  ps-4"></i> Mevzuat</a>
            </div>}

          {role.indexOf(UserPermConstants.Yardim_Ekranlari) > -1 && <div >
                <a href="#" onClick={() => { changeComponent(<MakaleSetting />) }}> <i className="fa fa-dedent ps-4"></i>Makaleler</a>
            </div>}

            {role.indexOf(UserPermConstants.Yardim_Ekranlari) > -1 && <div >
                <a href="#" onClick={() => { changeComponent(<SampleFormSetting />) }}> <i className="fa fa-dedent ps-4"></i>Örnek Formlar</a>
            </div>}

        </div >

        <div>


            <a href="#" onClick={() => { changeComponent(<HelpPanel changeComponent={changeComponent} />) }}> <i className="	fa fa-info ps-2"></i> Yardım</a>

        </div>


    </div >

}