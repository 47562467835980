import React, { useState, useEffect } from 'react';
import { ajaxFail } from '../GlobalState.js';



export default function DenetimPermissionPanel(props) {
    var postdata = {};
    const [listData, setListData] = useState(null);
    var username = props.username;

    const fetchList = () => {
        fetch("getdenetimperms", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(username)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                    fetchDenetimList();
                })
                
            }
        });
    }

    const fetchDenetimList = () => {
        fetch("denetimlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    var ulist = value.map(rec => "<option value='" + rec.id + "'>" + rec.name + "</option>");
                    document.getElementById("DenetimId").innerHTML = ulist;
                    document.getElementById("DenetimId").selectedIndex = -1;
                    if (ulist.length > 0)
                        document.getElementById("DenetimId").disabled = false;
                    else {
                        document.getElementById("DenetimId").disabled = true;
                    }
                })
            }
        });
    }



    const renderTable = (tabledata) => {
        var mytable =

                <table className='table  table-sm' aria-labelledby="tabelLabel">

                    <tbody id="myTable">
                        {tabledata.map(apprec =>
                            <tr key={apprec.id}>
                                <td>{apprec.name}</td>
                                <td>  <button type="button" onClick={() => { Sil(apprec.id) }} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Sil</button></td>
                            </tr>
                        )}
                        <tr className="bg-white">
                        <td>
                            <select className="form-select form-select-sm" id="DenetimId" name="DenetimId" onChange={handleInputChange}>
                            </select>

                        </td>
                        <td>  <button type="button" onClick={Add} className="btn btn-sm btn-link text-decoration-none m-0 p-0">Ekle</button></td>
                        </tr>
                    </tbody>
                </table>

        return (mytable);
    }


    const Add = () => {
        postdata.UserName = username;
        fetch("mapdenetimtouser", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                fetchList();
            }
        });
    }

    const Sil = (denetimid) => {
        postdata = {}
        postdata.UserName = username;
        postdata.denetimId = denetimid;
        fetch("removedenetimfromuser", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                fetchList();
            }
        });
    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        postdata[target.name] = value;
    }


    useEffect(() => {
        fetchList();
    }, [])


    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }
    else
        listTable = <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center" ><div className="spinner-border" ></div></div>



    return (
        <div >
            <h6>Denetim Yetkileri</h6>


            {listTable}
        </div>

    );


}