import React, { useState, useEffect } from 'react';
import { ajaxFail, removeTurkcLow, showConfirmMessage, showErrorMessage, showSuccessMessage } from '../GlobalState.js';
import Multiselect from 'multiselect-react-dropdown';
import $ from 'jquery';

export default function HavaleSablonSetting(props) {
    var postdata = {};
    const [listData, setListData] = useState([]);
    const [denetimList, setDenetimList] = useState([]);
    const [kontrolorList, setKontrolorList] = useState([]);
    const multiselectRef = React.createRef();
    const multiselectRefNew = React.createRef();

    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }


    const fetchDenetimList = () => {
        fetch("denetimlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setDenetimList(value);
                })
            }
        });
    }




    const fetchKontrollers = (e) => {
        var rec = e.target.value;
        if (rec == null || rec == -1) {
            if (kontrolorList.length > 0)
                setKontrolorList([])
            return;
        }
        fetch("userswithperm", {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    setKontrolorList(data)
                });
            }
        });

    }

    const removeRecord = (rec) => {
        showConfirmMessage(rec.id + " Numaralı Kaydı Silmek İstedinizden Emin Misiniz?", "", "Evet", "Hayır", () =>
            fetch("removehavalesablon", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(rec)
            }).then(async response => {
                if (!response.ok) {
                    ajaxFail(response);
                }
                else {
                    response.json().then(value => {
                        setListData(value);
                    })
                }
            }));
    }




    const fetchList = () => {

        fetch("havalesablonlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }

    const Reset = () => {

        document.getElementById("denetim").value = null;
        document.getElementById("kontolor").value = null
        document.getElementById("mapping").value = null
        document.getElementById("parallel").disabled = true;

    }

    const Add = () => {

        var d = document.getElementById("denetim").value
        var k = document.getElementById("kontolor").value
        var parallel = document.getElementById("parallel").checked

        if (d == null || k == null) return;
        if (document.getElementById("mapping").value != null && document.getElementById("mapping").value != "")
            document.getElementById("mapping").value += "," + (parallel == true ? "&" : "");
        document.getElementById("mapping").value += k + "=" + d
        document.getElementById("parallel").disabled = false;

    }

    const SaveSablon = (rec) => {
        var update = true;

        if (rec == null) {
            update = false;
            var m = document.getElementById("mapping").value
            var s = document.getElementById("sablonName").value
            var b = multiselectRefNew.current.getSelectedItems().map(x => x.name).join();
            if (m == null || m == "" || s == null || s == "" || b == null || b == "") {
                showErrorMessage("Tüm alanları doldurnuz");
                return;
            }
            rec = { basvuruTipi: b, mapping: m, sablonName: s }
        }


        fetch("savehavalesablon", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    if (update)
                        showSuccessMessage("Guncelleme Başarılı")
                    else
                        showSuccessMessage("Şablon Oluşturuldu")
                    setListData(value);
                })

            }
        });
    }



    const filterTable = (event) => {
        const target = event.target;
        const value = target.value.toLowerCase();
        $("#myTable tr").filter(function (idx) {
            var keywords = removeTurkcLow(value);
            var childs = $(this).children();
            var text = removeTurkcLow(childs.eq(1).children().eq(0).val()) + " " + removeTurkcLow(childs.eq(3).children().eq(0).val());
            if (listData[idx].basvuruTipi!=null)
            text = text + " " + removeTurkcLow(listData[idx].basvuruTipi);
            if (text.toLowerCase().indexOf(keywords.toLowerCase()) == -1)
                return $(this).toggle(false);
            return $(this).toggle(true);
        });

    }


    const renderTable = (tabledata) => {
        var mytable =
            <div className="table-responsive mainpagescroll" style={{ "height": "70vh" }}>
                <table className='table table-striped  table-sm' aria-labelledby="tabelLabel">
                    <thead style={{ "position": "sticky", "top": 0, "z-index": "1" }} className="bg-light">
                        <tr>
                            <th width="2%">No</th>
                            <th width="15%">İsim</th>
                            <th width="43%">Başvuru Tipi</th>
                            <th width="30%">Havale</th>
                            <th width="10%">
                                <input className="form-control form-control-sm" id="myInput" type="text" placeholder="Arama" onKeyUp={filterTable} />

                            </th>
                        </tr>
                    </thead>
                    <tbody id="myTable">
                        {tabledata.map(apprec =>
                            <tr key={apprec.id}>
                                <td>{apprec.id}</td>
                                <td>

                                    <input type="text" className="form-control form-control-sm bg-white" placeholder="Şablon İsmi" name="sablonName" defaultValue={apprec.sablonName} onChange={(e) => updateRecord(apprec, e)} />

                                </td>
                                <td>
                                    <Multiselect className="p-0 ps-1 form-control-sm bg-white"
                                        options={denetimList.map(x => x.name)}
                                        emptyRecordMsg="Başka Kayıt Yok"
                                        displayValue="name"
                                        isObject={false}
                                        selectedValues={apprec.basvuruTipi != null && apprec.basvuruTipi.length > 0 ? apprec.basvuruTipi.split(',') : []}
                                        onSelect={(selectedList, removedItem) => { apprec.basvuruTipi = selectedList.length == 0 ? null : selectedList.join() }} // Function will trigger on select event
                                        onRemove={(selectedList, removedItem) => { apprec.basvuruTipi = selectedList.length == 0 ? null : selectedList.join() }} // Function will trigger on remove event
                                    />
                                </td>
                                <td>
                                    <input type="text" className="form-control form-control-sm bg-white" placeholder="Şablon İsmi" defaultValue={apprec.mapping} name="mapping" onChange={(e) => updateRecord(apprec, e)} />

                                </td>

                                <td>
                                    <button onClick={() => SaveSablon(apprec)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Güncelle</button>&nbsp;
                                    <button onClick={() => removeRecord(apprec)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Sil</button>
                                </td>
                            </tr>
                        )}

                    </tbody>
                </table>
            </div>
        return (mytable);
    }





    useEffect(() => {
        fetchDenetimList();
        fetchList();

    }, [])


    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }
    else
        listTable = renderTable([]);

    return (
        <div className="  rounded ">

            <h5 className="ps-1">Havale Şablonları</h5>
            <div className="border p-2 rounded-3">
                <div className="row mt-1">
                    <div className="col-12 col-md-4  p-1">
                        <input type="text" className="form-control  bg-white" id="sablonName" placeholder="Şablon İsmi" name="sablonName" />
                    </div>
                    <div className="col-12 col-md-7  p-1">

                        <Multiselect className="p-0 form-control-sm bg-white"
                            options={denetimList}
                            emptyRecordMsg="Başka Kayıt Yok"
                            displayValue="name"
                            isObject={true}
                            ref={multiselectRefNew}
                            placeholder="Başvuru Tipleri"
                        //onSelect={(selectedList, removedItem) => { postdata["precondition"] = selectedList.length == 0 ? null : selectedList.map(rec => rec.id).join() }} // Function will trigger on select event
                        //onRemove={(selectedList, removedItem) => { postdata["precondition"] = selectedList.length == 0 ? null : selectedList.map(rec => rec.id).join() }} // Function will trigger on remove event
                        />

                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-12 col-md-4 p-1">
                        <input type="text" disabled={true} readOnly={true} className="form-control form-control-sm" id="mapping" placeholder="" name="mapping" />
                    </div>
                    <div className="col-12 col-md-3 p-1">

                        <select className="form-select form-select-sm " id="denetim" name="denetim" placeholder="Denetim" onChange={fetchKontrollers}>
                            <option value={-1}></option>
                            {denetimList.map(x => <option value={x.id}>{x.name} </option>)}
                        </select>
                    </div>

                    <div className="col-12 col-md-3 p-1">
                        <select className="form-select form-select-sm" id="kontolor" name="kontolor" placeholder="Denetim">
                            <option ></option>
                            {kontrolorList.map(x => <option value={x.userName}>{x.name + " " + x.family} </option>)}
                        </select>
                    </div>
                    <div className="col-6 col-md-1 p-1">
                        <input type="checkbox" className="form-check-input" id="parallel" name="parallel" disabled={document.getElementById("mapping") != null && document.getElementById("mapping").value.length == 0} /> Paralel

                    </div>
                    <div className="col-6 col-md-1 p-1">
                        <button onClick={Add} className="btn btn-sm btn-primary text-decoration-none ">Ekle</button>
                    </div>

                </div>

                <div className="row mt-2 mb-1">
                    <div className="col-12">
                        <button onClick={() => SaveSablon(null)} className="btn btn-sm btn-primary text-decoration-none ">Şablonu Oluştur</button>
                        <button onClick={Reset} className="btn btn-sm btn-primary text-decoration-none ms-1">Sıfırla</button>

                    </div>

                </div>
            </div>


            <div className="row mt-2">
                <div className="col-12">
                    {listTable}
                </div>
            </div>
        </div >

    );


}