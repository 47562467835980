

import React, { useState, useEffect } from 'react';


export default function VTable(props) {
    const data = props.data;
    const cols = props.cols;
    const headers = props.headers;
    const showRowNumber = props.showRowNumber;
    const rowClick = props.rowClick;
    const rowDelete = props.rowDelete;


        return <div  >
            <div className="mt-1" >
                <div className="table-responsive">
                    <table className='table mt-3 table-vdstriped' aria-labelledby="tabelLabel">
                        {headers != null && <thead >
                            {headers.map(col => <th className='text-start fw-bold'>  <a className="text-decoration-none" href="#" >   {col}   </a>    </th>)}
                        </thead>}
                        <tbody>
                            {data.map((rec, idx) =>

                                <tr>
                                    {showRowNumber == true && <td>
                                        {idx + 1}
                                    </td>}
                                    {cols.map(col => <td>  <a className="text-decoration-none" href="#" onClick={() => { if (rowClick) rowClick(idx,rec, col) } }>   {rec[col]}   </a>    </td>)}
                                    {rowDelete != null &&
                                        <td className="text-end">  <a className="text-decoration-none material-icons align-middle" href="#" onClick={() => { rowDelete(idx, rec) }}>  delete   </a>    </td>}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

}




