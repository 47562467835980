import React, { useState, useEffect } from 'react';
import { ajaxFail, showSuccessMessage } from '../GlobalState.js';

export default function CommunicationSetting(props) {
    var postdata = {};
    const [listData, setListData] = useState(null);

    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }

    const submitUpdateSMS = (id, name) => {
        var firm = "Makine";
        if (id == 2) {
            firm = "Mobil"
        }
        if (id == 3) {
            firm = "Asistel"
        }
        if (id ==4) {
            firm = "Mobildev"
        }
        var rec = {
            userName: document.getElementById("userName" + firm).value,
            sifre: document.getElementById("sifre" + firm).value,
            userCode: document.getElementById("userCode" + firm).value,
            accountId: document.getElementById("accountId" + firm).value,
            sender: document.getElementById("sender" + firm).value,
            serviseAddress: document.getElementById("serviseAddress" + firm).value,
            id: id,
            name: name,
            active: true


        }

        document.getElementById("iletisimmakinesi").disabled = true;
        document.getElementById("mobilpark").disabled = true;
        document.getElementById("Asistel").disabled = true;
        document.getElementById("Mobildev").disabled = true;

        fetch("savesmssetting", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                fetchSMSSetting();
            }
        });
    }




    const submitUpdateEmail = () => {
        var rec = {
            userName: document.getElementById("euserName").value,
            password: document.getElementById("password").value,
            smtpPort: document.getElementById("smtpPort").value,
            smtpServer: document.getElementById("smtpServer").value,
            tls: document.getElementById("tls").checked
        }
        fetch("saveemailsetting", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                showSuccessMessage("Güncelleme Başarılı")
            }
        });
    }


    const sendTestEmail = () => {
        var email = document.getElementById("testemail").value;
        fetch("sendtestemail", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(email)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.text().then(value => {
                    if (value.length<= 2)
                        document.getElementById("testemailresult").innerHTML = "Email Gönderildi.";
                    else
                        document.getElementById("testemailresult").innerHTML = value;
                });
            }
        });
    }


    const sendTestSMS= () => {
        var tel = document.getElementById("testtel").value;
        fetch("sendsms", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify("TEST|"+tel)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.text().then(value => {
                  
                    if (value.length <= 2)
                        document.getElementById("testsmsresult").innerText = "SMS Gönderildi.";
                    else
                        document.getElementById("testsmsresult").innerText = value;
                });
            }
        });
    }


    const fetchSMSSetting = () => {
        fetch("getsmssetting", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    if (value.length == 0) return;

                    document.getElementById("userNameMakine").value = value[0].userName;
                    document.getElementById("sifreMakine").value = value[0].sifre;
                    document.getElementById("userCodeMakine").value = value[0].userCode;
                    document.getElementById("accountIdMakine").value = value[0].accountId;
                    document.getElementById("senderMakine").value = value[0].sender;
                    document.getElementById("serviseAddressMakine").value = value[0].serviseAddress;
                    document.getElementById("activeMakine").checked = value[0].active;

                    document.getElementById("userNameMobil").value = value[1].userName;
                    document.getElementById("sifreMobil").value = value[1].sifre;
                    document.getElementById("userCodeMobil").value = value[1].userCode;
                    document.getElementById("accountIdMobil").value = value[1].accountId;
                    document.getElementById("senderMobil").value = value[1].sender;
                    document.getElementById("serviseAddressMobil").value = value[1].serviseAddress;
                    document.getElementById("activeMobil").checked = value[1].active;


                    document.getElementById("userNameAsistel").value = value[2].userName;
                    document.getElementById("sifreAsistel").value = value[2].sifre;
                    document.getElementById("userCodeAsistel").value = value[2].userCode;
                    document.getElementById("accountIdAsistel").value = value[2].accountId;
                    document.getElementById("senderAsistel").value = value[2].sender;
                    document.getElementById("serviseAddressAsistel").value = value[2].serviseAddress;
                    document.getElementById("activeAsistel").checked = value[2].active;


                    document.getElementById("userNameMobildev").value = value[3].userName;
                    document.getElementById("sifreMobildev").value = value[3].sifre;
                    document.getElementById("userCodeMobildev").value = value[3].userCode;
                    document.getElementById("accountIdMobildev").value = value[3].accountId;
                    document.getElementById("senderMobildev").value = value[3].sender;
                    document.getElementById("serviseAddressMobildev").value = value[3].serviseAddress;
                    document.getElementById("activeMobildev").checked = value[3].active;


                    document.getElementById("iletisimmakinesi").disabled = !value[0].active;
                    document.getElementById("mobilpark").disabled = !value[1].active;
                    document.getElementById("Asistel").disabled = !value[2].active;
                    document.getElementById("Mobildev").disabled = !value[3].active;



                })
            }
        });
    }

    const fetchEmailSetting = () => {

        fetch("getemailsetting", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }

            else {
                response.json().then(value => {
                    if (value.length == 0) return;
                    document.getElementById("euserName").value = value[0].userName;
                    document.getElementById("password").value = value[0].password;
                    document.getElementById("smtpPort").value = value[0].smtpPort;
                    document.getElementById("smtpServer").value = value[0].smtpServer;
                    document.getElementById("tls").checked = value[0].tls;

                })
            }
        });
    }

    useEffect(() => {
        fetchEmailSetting();
        fetchSMSSetting();
    }, [])



    return (<>
        <div className="rounded-1 mt-5 p-2 bg-light ">

            <h5>SMS Ayarları</h5>
            <div className="row rounded-3 mb-1">

                <div className="col-12" >
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="activeMakine" onMouseDown={(event) => { if (event.target.checked == false) submitUpdateSMS(1, "İletisim Makinesi") }} />
                    <label class="form-check-label ps-1" for="flexRadioDefault1">
                        İletişim Makinesi
                    </label>
                </div>
            </div>

            <fieldset className="row" id="iletisimmakinesi">

                <div className="row rounded-3 mb-1">

                    <div className="col-10" >
                        <input type="text" className="form-control form-control-sm" placeholder="Servis Adresi" id="serviseAddressMakine" />
                    </div>
                </div>
                <div className="row rounded-3">
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Kullanıcı Adı" id="userNameMakine" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Şifre" id="sifreMakine" />
                    </div>
                    <div className="col-2 " >
                        <input type="text" className="form-control form-control-sm" placeholder="User Code" id="userCodeMakine" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Account id" id="accountIdMakine" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Sender" id="senderMakine" />
                    </div>
                    <div className="col-2" >
                        <button onClick={() => submitUpdateSMS(1, "İletisim Makinesi")} className="btn btn-sm btn-primary">Güncelle</button>
                    </div>
                </div>
            </fieldset>

            <div className="row rounded-3 mb-1 mt-3">

                <div className="col-12" >
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="activeMobil" onMouseDown={(event) => { if (event.target.checked == false) submitUpdateSMS(2, "MobilPark") }} />
                    <label class="form-check-label ps-1" for="flexRadioDefault1">
                        Mobil Park
                    </label>
                </div>
            </div>

            <fieldset className="row" id="mobilpark">

                <div className="row rounded-3 mb-1">

                    <div className="col-10" >
                        <input type="text" className="form-control form-control-sm" placeholder="Servis Adresi" id="serviseAddressMobil" />
                    </div>
                </div>
                <div className="row rounded-3">
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Kullanıcı Adı" id="userNameMobil" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Şifre" id="sifreMobil" />
                    </div>
                    <div className="col-2 " >
                        <input type="text" className="form-control form-control-sm" placeholder="User Code" id="userCodeMobil" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Account id" id="accountIdMobil" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Sender" id="senderMobil" />
                    </div>
                    <div className="col-2" >
                        <button onClick={() => submitUpdateSMS(2, "MobilPark")} className="btn btn-sm btn-primary">Güncelle</button>
                    </div>
                </div>
            </fieldset>




            <div className="row rounded-3 mb-1 mt-3">

                <div className="col-12" >
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="activeAsistel" onMouseDown={(event) => { if (event.target.checked == false) submitUpdateSMS(3, "Asistel") }} />
                    <label class="form-check-label ps-1" for="flexRadioDefault1">
                        Asistel
                    </label>
                </div>
            </div>

            <fieldset className="row" id="Asistel">

                <div className="row rounded-3 mb-1">

                    <div className="col-10" >
                        <input type="text" className="form-control form-control-sm" placeholder="Servis Adresi" id="serviseAddressAsistel" />
                    </div>
                </div>
                <div className="row rounded-3">
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Kullanıcı Adı" id="userNameAsistel" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Şifre" id="sifreAsistel" />
                    </div>
                    <div className="col-2 " >
                        <input type="text" className="form-control form-control-sm" placeholder="User Code" id="userCodeAsistel" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Account id" id="accountIdAsistel" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Sender" id="senderAsistel" />
                    </div>
                    <div className="col-2" >
                        <button onClick={() => submitUpdateSMS(3, "Asistel")} className="btn btn-sm btn-primary">Güncelle</button>
                    </div>
                </div>
            </fieldset>

            <div className="row rounded-3 mb-1 mt-3">

                <div className="col-12" >
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="activeMobildev" onMouseDown={(event) => { if (event.target.checked == false) submitUpdateSMS(4, "Mobildev") }} />
                    <label class="form-check-label ps-1" for="flexRadioDefault1">
                        Mobildev
                    </label>
                </div>
            </div>

            <fieldset className="row" id="Mobildev">

                <div className="row rounded-3 mb-1">

                    <div className="col-10" >
                        <input type="text" className="form-control form-control-sm" placeholder="Servis Adresi" id="serviseAddressMobildev" />
                    </div>
                </div>
                <div className="row rounded-3">
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Kullanıcı Adı" id="userNameMobildev" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Şifre" id="sifreMobildev" />
                    </div>
                    <div className="col-2 " >
                        <input type="text" className="form-control form-control-sm" placeholder="User Code" id="userCodeMobildev" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Account id" id="accountIdMobildev" />
                    </div>
                    <div className="col-2" >
                        <input type="text" className="form-control form-control-sm" placeholder="Sender" id="senderMobildev" />
                    </div>
                    <div className="col-2" >
                        <button onClick={() => submitUpdateSMS(4, "Mobildev")} className="btn btn-sm btn-primary">Güncelle</button>
                    </div>
                </div>
            </fieldset>



            <div className="row border-top mt-5 pt-2 mb-3">
                <div className="col-2" >
                    <input type="text" className="form-control form-control-sm" placeholder="TEL - 055******" id="testtel" />
                </div>
                <div className="col-2 " >
                    <button onClick={sendTestSMS} className="btn btn-sm btn-primary">Test SMS Gönder</button>
                </div>
                <div className="col-8" id="testsmsresult">
                </div>
            </div>

        </div>


        <div className="rounded-1 mt-5 p-2 bg-light ">
            <h5>Email Ayarları</h5>
            <div className="row ">
                <div className="col-4" >
                    <input type="text" className="form-control form-control-sm" placeholder="Kullanıcı Adı" id="euserName" />
                </div>
                <div className="col-4" >
                    <input type="text" className="form-control form-control-sm" placeholder="Şifre" id="password" />
                </div>

                <div className="col-2" >
                    <input type="number" className="form-control form-control-sm" placeholder="Port" id="smtpPort" />
                </div>
                <div className="col-2" >
                    <div class="form-check">
                        <input className="form-check-input" type="checkbox" id="tls" />
                        <label className="form-check-label" for="flexCheckChecked">
                            TLS
                        </label>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-4" >
                    <input type="text" className="form-control form-control-sm" placeholder="Sunucu" id="smtpServer" />
                </div>

                <div className="col-3" >
                    <button onClick={submitUpdateEmail} className="btn btn-sm btn-primary">Güncelle</button>
                </div>
            </div>

            <div className="row border-top mt-2 pt-2 mb-3">
                <div className="col-4" >
                    <input type="text" className="form-control form-control-sm" placeholder="Email" id="testemail" />
                </div>
                <div className="col-3 " >
                    <button onClick={sendTestEmail} className="btn btn-sm btn-primary">Test Email Gönder</button>
                </div>
                <div className="col-5" id="testemailresult">
                </div>
            </div>
        </div>
    </>
    );


}