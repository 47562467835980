import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/js/bootstrap.min.js'
import { ajaxFail, showErrorMessage } from '../GlobalState.js';
import * as UserPermConstants from '../User/UserPermConstants';
import { useGlobalState, isEmpty } from '../GlobalState.js';
import Multiselect from 'multiselect-react-dropdown';
import Apply5 from "./Apply5.js";
import Apply3 from "./Apply3.js";
export default function Apply4(props) {

    useEffect(() => {
        fetchAppDynamicFieldList();

    }, [])

    const [role, setRole] = useGlobalState('role');
    const zorunloBosBirakma = role.indexOf(UserPermConstants.Yeni_Basvuruda_Zorunlu_Alanlari_Bos_Birakabilme) == -1;

    var applicationdata = props.applicationdata;
    var appdynamicdata = applicationdata.appData != null ? JSON.parse(applicationdata.appData) : {};



    const [allDynamicFilds, setAllDynamicFilds] = React.useState();

    const handleDynamicChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked.toString() : target.value;
        appdynamicdata[target.name] = { id: target.id, "value": value, "type": target.type };
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (zorunloBosBirakma == false) {
            var multiselectinputs = allDynamicFilds.filter(k => isVisible(k) == true && k.type == "select multiple");
            var notfilled = multiselectinputs.filter(k => k.reqired == true && (isEmpty(appdynamicdata[k.name]) || isEmpty(appdynamicdata[k.name].value)))
            if (notfilled.length > 0) {
                var names = notfilled.map(k => k.name).join(",");
                showErrorMessage("Zorunlu alanlar boş bırakılamaz", names);
                return;
            }
        }




        applicationdata.appData = JSON.stringify(appdynamicdata);
        document.getElementById("buttonSpinner").hidden = false;
        fetch("saveapplication", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(applicationdata)
        }).then(async response => {
            document.getElementById("buttonSpinner").hidden = true;
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async a => {
                    props.changeComponent(<Apply5 applicationdata={a} changeComponent={props.changeComponent} />)
                });
            }
        });
    }

    const fetchAppDynamicFieldList = () => {
        fetch("getappfields", {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    setAllDynamicFilds(data);
                });
            }
        });
    }

    const isReadOnly = (k) => {

        if (isEmpty(k.role) == false && k.role.split(',').some(x => role.indexOf("#" + x + "#") > -1) == false)
            return true;
        return false;

    }
    const isVisible = (k) => {
        if (k.gizli == true && role.indexOf(UserPermConstants.Gizli_Alanlari_Gorme) == -1)
            return false;
        if (isEmpty(k.basvuruTipi) == false && k.basvuruTipi.indexOf(applicationdata.basvuruTipi) == -1)
            return false;
        return true;
    }


    var dynamicFields = "";
    if (allDynamicFilds != null) {
        var appDynamicData = applicationdata.appData != null ? JSON.parse(applicationdata.appData) : {};


        allDynamicFilds.forEach(k => {
            if (appdynamicdata[k.name] == null && k.initValue != null) {
                if (k.type == "checkbox")
                    appdynamicdata[k.name] = { "value": k.initValue == 1, "type": k.type, "id": k.id };
                else if (k.type != "select" && k.type != "select multiple")
                    appdynamicdata[k.name] = { "value": k.initValue, "type": k.type,"id":k.id };
            }
        });
       

        dynamicFields =
            allDynamicFilds.map(k => isVisible(k)==false ? "" :
                <div className="row p-2 rounded-5" >
                <div className="col-12 col-md-6">

                        <label htmlFor={k.name} className="form-label p-0">{k.name} {k.reqired == true && "*" && zorunloBosBirakma}</label><br />
                    {k.type == "select" ?
                            <select disabled={isReadOnly(k)} readOnly={isReadOnly(k)}  className="form-select form-select-sm selectpicker" id={k.id} name={k.name} onChange={handleDynamicChange} required={k.reqired && zorunloBosBirakma} >
                                <option value="" selected={true} hidden></option>
                                {k.initValue != null && k.initValue.split(",").map(rec =>
                                    <option value={rec} selected={appdynamicdata[k.name] != null && appdynamicdata[k.name].value == rec}>{rec}</option>)}
                            </select> :
                            k.type == "select multiple" ?
                                <Multiselect className="p-0 form-control-sm bg-white"
                                    disablePreSelectedValues={isReadOnly(k)}
                                    required={true}
                                    options={k.initValue.split(",")}
                                    emptyRecordMsg=""
                                    isObject={false}
                                    onSelect={(selectedList, changedItem) => {
                                        var clist = selectedList.join();
                                        appdynamicdata[k.name] = { id: k.id, "value": clist, "type": k.type };
                                        
                                    }}
                                    onRemove={(selectedList, changedItem) => {
                                        var clist = selectedList.join();
                                        appdynamicdata[k.name] = { id: k.id, "value": clist, "type": k.type };
                                        
                                    }}
                                    placeholder=""
                                    selectedValues={(appdynamicdata[k.name] == null || isEmpty(appdynamicdata[k.name].value) || isReadOnly(k)) ? [] : appdynamicdata[k.name].value.split(",")}
                                />
                                :
                            <input type={k.type} disabled={isReadOnly(k) } readOnly={isReadOnly(k)} className={k.type != "checkbox" ? "form-control form-control-sm " : "form-check-input"}
                                    id={k.id} name={k.name} defaultValue={appdynamicdata[k.name] != null ? appdynamicdata[k.name].value : k.initValue}
                                    defaultChecked={appdynamicdata[k.name] != null ? appdynamicdata[k.name].value : k.initValue==1} onChange={handleDynamicChange} required={k.reqired && zorunloBosBirakma} />
                    }
                    </div></div>

            )
    }


    return <div  >

        <div className="row" >
            <div className="  col-md-2 col-12 " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    1. Başvuru Tipi

                </div>
            </div>
            <div className=" col-md-2 col-12  " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    2. Konum
                </div>
            </div>
            <div className=" col-md-2 col-12    " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    3. Müellifler
                </div>
            </div>
            <div className=" col-md-2 col-12   text-primary " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    4. Diğer Sorular
                </div>
            </div>
            <div className=" col-md-2 col-12    " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    5. Dosyalar
                </div>
            </div>
            <div className=" col-md-2 col-12     " >
                <div className="  w-75 fw-bold border-bottom border-3 " >
                    6. Onay
                </div>
            </div>
        </div>

        <div className="row m-3" >
            <div className="col-12" >
                <div className="  align-items-center justify-content-center p-1" >
                    <form action="submitapplication" className="mt-2" method="POST" onSubmit={onSubmit}>

                        
                                {dynamicFields}
                    


                        <div className="row mt-3 border-top pt-2" >
                            <div className="col-6 text-start">
                                <button type="button" className="btn btn-sm btn-primary mb-2 " onClick={() => { props.changeComponent(<Apply3 applicationdata={applicationdata} changeComponent={props.changeComponent} />) }}>Geri Dön</button>
                            </div>

                            <div className="col-6 text-end">
                                <button type="submit" name="actionSave" value="Save" className="btn btn-sm btn-primary mb-2 ">
                                    Kaydet ve Devam Et 
                                    <span class="ms-1 spinner-border spinner-border-sm" id="buttonSpinner" hidden></span>
                                </button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div >
    </div >

}



