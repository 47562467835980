import React, { useState, useEffect } from 'react';
import { ajaxFail } from '../GlobalState.js';



export default function KabulAppTypePermission(props) {
    var postdata = {};
    const [listData, setListData] = useState(null);
    const [denetimList, setDenetimList] = useState(null);
    var username = props.username;



    const fetchList = () => {
        fetch("kabullistperms", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(username)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                    fetchDenetimList();
                })

            }
        });
    }

    const fetchDenetimList = () => {
        fetch("denetimlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {

                    setDenetimList(value);
                })
            }
        });
    }

    const getDenetimName = (id) => {
        var rec = denetimList.find(x => x.id == id);
        if (rec == null)
            return "";
        return rec.name;
    }


    const renderTable = (tabledata) => {
        var mytable =

            <table className='table  table-sm' aria-labelledby="tabelLabel">

                <tbody id="myTableAppTypes">
                    {tabledata.map(apprec =>
                        <tr key={apprec.id}>
                            <td>{getDenetimName(apprec.appType)}</td>
                            <td>  <button type="button" onClick={() => { Sil(apprec) }} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Sil</button></td>
                        </tr>
                    )}
                    <tr className="bg-white">
                        <td>
                            <select className="form-select form-select-sm" id="apptypes" name="appType" onChange={handleInputChange}>
                                <option></option>
                                {denetimList.map(rec => <option value={rec.id}>{rec.name} </option>)}
                            </select>
                        </td>
                        <td>  <button type="button" onClick={Add} className="btn btn-sm btn-link text-decoration-none m-0 p-0">Ekle</button></td>
                    </tr>
                </tbody>
            </table>

        return (mytable);
    }


    const Add = () => {
        postdata.UserName = username;
        fetch("addkabullistperm", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                    var dc = document.getElementById("apptypes");
                    if (dc == null)
                        return;
                    dc.selectedIndex = -1;
                })
            }
        });
    }

    const Sil = (a) => {
        fetch("removekabullistperm", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(a)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        postdata[target.name] = value;
    }


    useEffect(() => {
        fetchList();
    }, [])


    var listTable = "";
    if (listData != null && denetimList != null)
        listTable = renderTable(listData);
    else
        listTable = <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center" ><div className="spinner-border" ></div></div>


    return (
        <div >
            <h6>Başvuru Kabul Yetkileri</h6>
            {listTable}
        </div>

    );


}