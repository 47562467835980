import React, { useState, useEffect } from 'react';
import { ajaxFail, isEmpty, setVisible, showConfirmMessage, showErrorMessage, showSuccessMessage } from '../GlobalState.js';
import { useGlobalState } from '../GlobalState.js';
import MaksGorevli from './MaksGorevli';
import MaksProje from './MaksProje.js';
import MaksDuvardolgu from './MaksDuvardolgu.js';
import MaksDoseme from './MaksDoseme.js';
import MaksOrtakAlan from './MaksOrtakAlan.js';
import MaksIcmesu from './MaksIcmesu.js';
import MaksAtiksu from './MaksAtiksu.js';
import MaksMuhtelifGelir from './MaksMuhtelifGelir.js';
import MaksYapiDenetim from './MaksYapiDenetim.js';
import MaksBagimsizBolum from './MaksBagimsizBolum.js';
import Maksisitma from './Maksisitma.js';
import MaksisitmaYakit from './MaksisitmaYakit.js';
import MaksSuTemin from './MaksSuTemin.js';
import MaksSuYakit from './MaksSuYakit.js';
import MaksTesisat from './MaksTesisat.js';
import MaksTasiyici from './MaksTasiyici.js';

export default function MAKS(props) {
    const [role, setRole] = useGlobalState('role');
    const [rec, setRec] = useState(props.MaksApp);

    const [maksMahalleList, setmaksMahalleList] = useState([]);
    const [maksAdresList, setMaksAdresList] = useState([]);
    const [yollist, setYols] = useState([]);
    const [numarajlist, setNumaratjList] = useState([]);
    const [selectedAdres, setSelectedAdres] = useState([]);

    const [userData, setUserData] = useState(null);
    const [userName, setuserName] = useGlobalState('userName');
    const appData = props.appData;

    useEffect(() => {
        // load();
        fetchUserData();
        fetchMaksMahalle();
    }, [])




    const handleMahalleChange = (event) => {
        var idx = document.getElementById("maksmahalle").selectedIndex;
        document.getElementById("yapiBelgeAdres_MahalleKimlikNo").value = document.getElementById("maksmahalle").options[idx].value
        document.getElementById("yapiBelgeAdres_MahalleAdi").value = document.getElementById("maksmahalle").options[idx].innerHTML
        fetchMaksYol(document.getElementById("maksmahalle").options[idx].value);
    }

    const handleYolChange = (event) => {
        var idx = document.getElementById("maksyol").selectedIndex;
        var yolad = document.getElementById("maksyol").options[idx].innerHTML;
        setNumaratjList(maksAdresList.filter(x => x.yolad == yolad).map(x => x.kapino));
    }

    const handleNumaratjChange = (event) => {
        var idx = document.getElementById("maksnumaratj").selectedIndex;
        var k = document.getElementById("maksnumaratj").options[idx].innerHTML;
        idx = document.getElementById("maksyol").selectedIndex;
        var yolad = document.getElementById("maksyol").options[idx].innerHTML;

        var adres = maksAdresList.filter(x => x.yolad == yolad && x.kapino == k);
        if (adres.length == 0) {
            showErrorMessage("Seçilen Numarataja Kimlik No Bulunamadı !");
            return;

        }
        /* if (adres.length > 0) {
             showErrorMessage("Seçilen Numarataja Birden Fazla Kimlik No bulundu !");
             return;
         }*/

        document.getElementById("adresinfo").innerHTML = "Adres: " + adres.length + " , Numarataj: " + adres[0].numarataj + " , Yapı K. No" + adres[0].yapikimlikno + " , Bagimsiz Bol K. No:" + adres[0].bagimsizbolkimlikno + " , Bagimsiz Bol No:" + adres[0].yapiBelgeAdres_BagimsizBolumNo;
        document.getElementById("yapiBelgeYapi_YapiKimlikNo").value = adres[0].yapikimlikno;
        document.getElementById("yapiBelgeAdres_BagimsizBolumKimlikNo").value = adres[0].bagimsizbolkimlikno;
        document.getElementById("yapiBelgeAdres_BagimsizBolumNo").value = adres[0].bagimsizbolumno;
        document.getElementById("yapiBelgeAdres_NumaratajKimlikNo").value = k;

        setSelectedAdres(adres);


    }



    const getDefult = (name) => {
        if (rec == null)
            return "";
        return rec[name];
    }



    const fetchUserData = () => {
        fetch("getuser?user=" + userName, {
            method: 'GET'
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(async data => {
                    setUserData(data);
                });
            }
        });
    }



    const fetchMaksMahalle = () => {
        fetch("getmaksmahallelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setmaksMahalleList(value);

                })
            }
        });
    }

    const fetchMaksYol = (id) => {
        fetch("getmaksyollist?mahalleid=" + id, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setMaksAdresList(value)
                    const yols = new Set();
                    value.forEach(x => yols.add(x.yolad));

                    setYols([...yols]);

                })
            }
        });
    }


    const senToMAKS = () => {
        setVisible("myspinner", true)
        fetch("yapiruhsatkaydet", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            ,
            body: rec.id
        }).then(async response => {
            setVisible("myspinner", false)
            try {
                response.text().then(value => {
                    value = value.replace("\\n", " ").replace("\n", " ").replace("\n", " ").replace("\r", " ");
                    showSuccessMessage(value);
                })
            }
            catch (ex) {

            }
        });
    }


    /*   const load = () => {
   
           fetch("getmaksapp", {
               method: 'POST',
               headers: {
                   'Accept': 'application/json',
                   'Content-Type': 'application/json'
               },
               body: JSON.stringify("app " + appData.id)
           }).then(async response => {
               if (!response.ok) {
                   ajaxFail(response)
               }
               else {
                   try {
                       response.json().then(value => {
                           if (value.length > 0)
                               setRec(value[0]);
                       })
                   }
                   catch (ex) {
                       showErrorMessage(ex);
                   }
               }
           });
       }*/



    const save = () => {
        rec.appId = appData.id;
        setVisible("myspinner", true)
        fetch("savemaksapp", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            setVisible("myspinner", false)
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                try {
                    response.json().then(value => {

                        showSuccessMessage("Başvuru kaydedilmiştir", "Başvuru No: " + value.id);
                        setRec(value);
                    })
                }
                catch (ex) {

                }
            }
        });
    }

    const Oku = () => {
        rec.appId = appData.id;

        var bultenno = document.getElementById("yapiBelge_BultenNo").value;
        setVisible("myspinner", true)
        fetch("readfrommaks?maksid=" + rec.id + "&bultenno=" + bultenno, {
            method: 'GET'

        }).then(async response => {
            setVisible("myspinner", false)
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                try {
                    response.json().then(value => {
                        showSuccessMessage(bultenno + " bulten numaralı başvuru MAKS servisinden alınmıştır")
                        setRec(value);
                    })
                }
                catch (ex) {

                }
            }
        });
    }

    const Onayla = () => {
        setVisible("myspinner", true)
        fetch("approveRuhsat", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec.id)

        }).then(async response => {
            setVisible("myspinner", false)
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                try {
                    response.text().then(value => {
                        showSuccessMessage(value);
                    })
                }
                catch (ex) {

                }
            }
        });
    }

    const SantiyeSefiSorgula = () => {
        document.getElementById("santiyeSefSonuc").innerHTML = "Yanıt Bekleniyor ...";
        setVisible("myspinner", true)
        fetch("santiyesefisorgula", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec.id)

        }).then(async response => {
            setVisible("myspinner", false)
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                try {
                    response.text().then(value => {
                        document.getElementById("santiyeSefSonuc").innerHTML = value;
                    })
                }
                catch (ex) {

                }
            }
        });
    }

    const YambisSorgula = () => {
        document.getElementById("YambisSorgula").innerHTML = "Yanıt Bekleniyor ...";
        setVisible("myspinner", true)
        fetch("yambisSorgula", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec.id)

        }).then(async response => {
            setVisible("myspinner", false)
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                try {
                    response.text().then(value => {
                        value = value.replaceAll('\\n', "").replaceAll('"', "    ").replaceAll("\\", " ").replaceAll("{", "").replaceAll("}", "").replaceAll(",", "<br/>");
                        document.getElementById("YambisSorgula").innerHTML = value;
                    })
                }
                catch (ex) {

                }
            }
        });
    }






    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        if (isEmpty(value) == false)
            rec[target.name] = value;
        else
            rec[target.name] = null;
    }

    var mainbuttons = <div className="row mt-1">

        <div className="col-12 col-md-6">
            <h5 className="text-primary mb-0" >MAKS Yapı Ruhsat Belgesi </h5>
            {isEmpty(recid) ? "" : "ID: " + recid}&nbsp;&nbsp;&nbsp;&nbsp;
            {isEmpty(appid) ? "" : "AppID: " + appid}
        </div>
        <div className="col-12 col-md-1 text-end">
            <div class="spinner-border spinner-border-sm" id="myspinner" hidden>
            </div>
        </div>
        <div className="col-12 col-md-5 text-end">
            <button type="button" className="ms-1 btn btn-primary  btn-sm  text-decoration-none " style={{ "border-radius": "8px" }} onClick={save}>
                {rec.id > 0 ? "Kaydet" : "Yeni Başvuru Oluştur"}
            </button>
        </div>
    </div>

    var recid = getDefult("id");
    var appid = getDefult("appId");
    if (userData == null)
        return <div class="spinner-border spinner-border-sm">   </div>

    if (rec.id == null || rec.id <= 0)
        return mainbuttons;


    return (
        <div className="mb-5 rounded-3 pt-2 pb-3 ps-3 pe-3" style={{ "background-color": "#ebebeb", "min-height": "100vh" }}>

            {mainbuttons}

            <div className="row">
                <div className="col-2">
                    <button disabled={rec == null || !(rec.id > 0)} type="button" className="ms-1 btn btn-success  btn-sm  text-decoration-none w-100" style={{ "border-radius": "8px" }} onClick={senToMAKS}>
                        Gönder
                    </button>
                </div>
                <div className="col-2">
                    <button disabled={rec == null || !(rec.id > 0)} type="button" className="ms-1 btn btn-success  btn-sm  text-decoration-none w-100" style={{ "border-radius": "8px" }} onClick={senToMAKS}>
                        Güncelle
                    </button>
                </div>
                <div className="col-2">
                    <button disabled={rec == null || !(rec.id > 0)} type="button" className="ms-1 btn btn-success  btn-sm  text-decoration-none w-100" style={{ "border-radius": "8px" }} onClick={Oku}>
                        Oku
                    </button>
                </div>

                <div className="col-2">
                    <button disabled={rec == null || !(rec.id > 0)} type="button" className="ms-1 btn btn-success  btn-sm  text-decoration-none w-100" style={{ "border-radius": "8px" }} onClick={Onayla}>
                        Onayla
                    </button>
                </div>
                <div className="col-2">
                    <a disabled={rec == null || !(rec.id > 0)} type="button" className="ms-1 btn btn-success  btn-sm  text-decoration-none w-100" style={{ "border-radius": "8px" }} download="ruhsat.pdf" href={"/getRuhsatPDF?id=" + rec.id}>
                        Ruhsatı İndir
                    </a>

                </div>
            </div>

            <div className="row mt-2">
                <div className="col-3">
                    <a className="text-decoration-none" href="#BAŞVURUBİLGİLERİ">Başvuru Bilgileri</a>
                </div>
                <div className="col-3">
                    <a className="text-decoration-none" href="#YapıSahipiBilgileri">Yapı Sahipi Bilgileri</a>
                </div>
                <div className="col-3">
                    <a className="text-decoration-none" href="#YapıBelgeBilgileri">Yapı Belge Bilgileri</a>
                </div>
                <div className="col-3">
                    <a className="text-decoration-none" href="#YapiBelgeAdresBilgileri">Yapi Belge Adres Bilgileri</a>
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <a className="text-decoration-none" href="#Ruhsat">Ruhsat</a>
                </div>
                <div className="col-3">
                    <a className="text-decoration-none" href="#YapıMütahhitBilgileri">Mütahhit Bilgileri</a>
                </div>
                <div className="col-3">
                    <a className="text-decoration-none" href="#ŞantiyeŞefiBilgileri">Şantiye Şefi</a>
                </div>
                <div className="col-3">
                    <a className="text-decoration-none" href="#YapiBelgeAdresBilgileri">Bagimsiz Bolum</a>
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <a className="text-decoration-none" href="#YapiOzellikBilgileri">Yapi Ozellik Bilgileri</a>
                </div>
                <div className="col-3">
                    <a className="text-decoration-none" href="#MaksTasiyici">Tasiyici</a>
                </div>
                <div className="col-3">
                    <a className="text-decoration-none" href="#YapıDenetimKuruluşBilgileri">Yapı Denetim Kuruluş</a>
                </div>
                <div className="col-3">
                    <a className="text-decoration-none" href="#YapıToprakBilgileri"> Toprak Bilgileri</a>
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <a className="text-decoration-none" href="#YapiBelgeDigerHusus">Diger Husus</a>
                </div>
                <div className="col-3">
                    <a className="text-decoration-none" href="#YapiİşYeriBilgileri">Yapi İşYeri Bilgileri</a>
                </div>
                <div className="col-3">
                    <a className="text-decoration-none" href="#Maksisitma">Isitma</a>
                </div>
                <div className="col-3">
                    <a className="text-decoration-none" href="#MaksisitmaYakit"> Isitma Yakit</a>
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <a className="text-decoration-none" href="#MaksSuTemin">Sıcak Su Temin</a>
                </div>
                <div className="col-3">
                    <a className="text-decoration-none" href="#MaksSuYakit">Sıcak Su Temin Yakit</a>
                </div>
                <div className="col-3">
                    <a className="text-decoration-none" href="#MaksIcmesu">Içme Su</a>
                </div>
                <div className="col-3">
                    <a className="text-decoration-none" href="#MaksAtiksu"> Maks Atik Su</a>
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <a className="text-decoration-none" href="#MaksGorevli">Gorevli</a>
                </div>
                <div className="col-3">
                    <a className="text-decoration-none" href="#MaksProje">Proje</a>
                </div>
                <div className="col-3">
                    <a className="text-decoration-none" href="#MaksDuvardolgu">Duvardolgu</a>
                </div>
                <div className="col-3">
                    <a className="text-decoration-none" href="#MaksDoseme"> Doseme</a>
                </div>
            </div>
            <div className="row">
                <div className="col-3">
                    <a className="text-decoration-none" href="#MaksOrtakAlan">Ortak Alan</a>
                </div>
                <div className="col-3">
                    <a className="text-decoration-none" href="#MaksTesisat">Tesisat</a>
                </div>
                <div className="col-3">
                    <a className="text-decoration-none" href="#MaksMuhtelifGelir">Muhtelif Gelir</a>
                </div>
                <div className="col-3">
                    <a className="text-decoration-none" href="#MaksYapiDenetim">Yapi Denetim</a>
                </div>
            </div>

            {/*--------------------------------------------------------ADRES -------------------------------*/}


            <div className="bg-white rounded-3 p-2 mt-2 shadow-sm">
                <div className="row" id="BAŞVURUBİLGİLERİ">
                    <div className="text-start col-12 fw-bold text-primary ">
                        Başvuru Bilgileri
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="text-start col-12 text-success">
                        {rec.yapiBelgeAdres_MahalleAdi}( {rec.yapiBelgeAdres_MahalleKimlikNo}) / {rec.yol} /  {rec.yapiBelgeAdres_DisKapiNo}
                    </div>
                </div>

                <div className="row mt-1">
                    <div className="text-start col-3 pt-3">
                        <input type="checkbox" className="form-check-input" id="yapiBelge_YapiDenetimTabi" name="yapiBelge_YapiDenetimTabi" defaultChecked={getDefult("yapiBelge_YapiDenetimTabi") == true} key={getDefult("yapiBelge_YapiDenetimTabi")} onChange={handleInputChange} key={rec.yapiBelge_YapiDenetimTabi} /> Bina Yapı Denetime tabi değil
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Yibf No</label>
                        <input type="number" className="form-control form-control-sm" id="yapiBelge_YibfNo" name="yapiBelge_YibfNo" defaultValue={getDefult("yapiBelge_YibfNo")} key={getDefult("yapiBelge_YibfNo")} onChange={handleInputChange} />
                    </div>

                </div>

                <div className="row mt-3">

                </div>



            </div>

            {/*-------------------------------------------------------------------Yapı Sahipi Bilgileri-----------------------------------------------*/}
            <div className="bg-white rounded-3 p-2 mt-2 shadow-sm">
                <div className="row" id="YapıSahipiBilgileri">
                    <div className="text-start col-12 fw-bold text-primary ">
                        Yapı Sahipi Bilgileri
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">Kurum Tur</label>
                        <select class="form-select form-select-sm" id="yapiSahip_YapiSahipKurumTurKod" name="yapiSahip_YapiSahipKurumTurKod" defaultValue={getDefult("yapiSahip_YapiSahipKurumTurKod")} key={getDefult("yapiSahip_YapiSahipKurumTurKod")} onChange={handleInputChange} >
                            <option  ></option>
                            <option value="1" selected={getDefult("yapiSahip_YapiSahipKurumTurKod") == "1"}>Özel</option>
                            <option value="2" selected={getDefult("yapiSahip_YapiSahipKurumTurKod") == "2"}>Kamu</option>
                            <option value="3" selected={getDefult("yapiSahip_YapiSahipKurumTurKod") == "3"}>Yabancı</option>
                        </select>
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Kurum Kod</label>
                        <select class="form-select form-select-sm" id="yapiSahip_YapiSahipKurumKod" name="yapiSahip_YapiSahipKurumKod" defaultValue={getDefult("yapiSahip_YapiSahipKurumKod")} key={getDefult("yapiSahip_YapiSahipKurumKod")} onChange={handleInputChange} >
                            <option  ></option>
                            <option value="1" selected={getDefult("yapiSahip_YapiSahipKurumKod") == "481"}>Özel Kişiler</option>
                            <option value="2" selected={getDefult("yapiSahip_YapiSahipKurumKod") == "482"}>Özel Kuruluşlar</option>
                            <option value="3" selected={getDefult("yapiSahip_YapiSahipKurumKod") == "467"}>Yapı Koopratifleri</option>
                            <option value="3" selected={getDefult("yapiSahip_YapiSahipKurumKod") == "485"}>Ortaklık</option>
                        </select>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">Ad</label>
                        <input type="text" className="form-control form-control-sm" id="yapiSahip_Ad" name="yapiSahip_Ad" defaultValue={getDefult("yapiSahip_Ad")} key={getDefult("yapiSahip_Ad")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Soyad </label>
                        <input type="text" className="form-control form-control-sm" id="yapiSahip_Soyad" name="yapiSahip_Soyad" defaultValue={getDefult("yapiSahip_Soyad")} key={getDefult("yapiSahip_Soyad")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">TC Kimlik No</label>
                        <input type="number" className="form-control form-control-sm" id="yapiSahip_TCKimlikNo" name="yapiSahip_TCKimlikNo" defaultValue={getDefult("yapiSahip_TCKimlikNo")} key={getDefult("yapiSahip_TCKimlikNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Unvan</label>
                        <input type="text" className="form-control form-control-sm" id="yapiSahip_Unvan" name="yapiSahip_Unvan" defaultValue={getDefult("yapiSahip_Unvan")} key={getDefult("yapiSahip_Unvan")} onChange={handleInputChange} />
                    </div>

                </div>
                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">Vergi Dairesi</label>
                        <input type="text" className="form-control form-control-sm" id="yapiSahip_VergiDaire" name="yapiSahip_VergiDaire" defaultValue={getDefult("yapiSahip_VergiDaire")} key={getDefult("yapiSahip_VergiDaire")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Vergi No</label>
                        <input type="number" className="form-control form-control-sm" id="yapiSahip_VergiNo" name="yapiSahip_VergiNo" defaultValue={getDefult("yapiSahip_VergiNo")} key={getDefult("yapiSahip_VergiNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Acik Adres</label>
                        <input type="text" className="form-control form-control-sm" id="yapiSahip_AcikAdres" name="yapiSahip_AcikAdres" defaultValue={getDefult("yapiSahip_AcikAdres")} key={getDefult("yapiSahip_AcikAdres")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Bagimsiz Bolum Kimlik No</label>
                        <input type="number" className="form-control form-control-sm" id="yapiSahip_BagimsizBolumKimlikNo" name="yapiSahip_BagimsizBolumKimlikNo" defaultValue={getDefult("yapiSahip_BagimsizBolumKimlikNo")} key={getDefult("yapiSahip_BagimsizBolumKimlikNo")} onChange={handleInputChange} />
                    </div>

                </div>
            </div>



            {/*--------------------------------------------------------YAPI BELGE -------------------------------*/}


            <div className="bg-white rounded-3 p-2 mt-2 shadow-sm">
                <div className="row" id="YapıBelgeBilgileri">
                    <div className="text-start col-12 fw-bold text-primary ">
                        Yapı Belge Bilgileri
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">Yapi Belge Tipi</label>
                        <select disabled class="form-select form-select-sm" aria-label="Default select example" id="yapiBelge_YapiBelgeTipKod" name="yapiBelge_YapiBelgeTipKod" defaultValue={getDefult("yapiBelge_YapiBelgeTipKod")} key={getDefult("yapiBelge_YapiBelgeTipKod")} onChange={handleInputChange} >
                            <option selected ></option>
                            <option value="1" selected={getDefult("yapiBelge_YapiBelgeTipKod") == "1"}>Yapi Ruhsat Belgesi</option>
                            <option value="2" selected={getDefult("yapiBelge_YapiBelgeTipKod") == "2"}>Yapi Kullanma Izin Belgesi</option>
                            <option value="3" selected={getDefult("yapiBelge_YapiBelgeTipKod") == "3"}>Yanan Yikilan Yapi Formu</option>
                            <option value="4" selected={getDefult("yapiBelge_YapiBelgeTipKod") == "4"}>Yikim Ruhsat Belgesi</option>
                        </select>
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Yapi Kimlik No</label>
                        <input type="number" className="form-control form-control-sm" id="yapiBelgeYapi_YapiKimlikNo" name="yapiBelgeYapi_YapiKimlikNo" defaultValue={getDefult("yapiBelgeYapi_YapiKimlikNo")} key={getDefult("yapiBelgeYapi_YapiKimlikNo")} onChange={handleInputChange} />
                    </div>

                    <div className="text-start col-3">
                        <label className="form-label">Yapi Belge Durum Kod</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelge_YapiBelgeDurumKod" name="yapiBelge_YapiBelgeDurumKod" defaultValue={getDefult("yapiBelge_YapiBelgeDurumKod")} key={getDefult("yapiBelge_YapiBelgeDurumKod")} onChange={handleInputChange} />
                    </div>

                    <div className="text-start col-3">
                        <label className="form-label">Bulten No</label>
                        <input type="number" className="form-control form-control-sm" id="yapiBelge_BultenNo" name="yapiBelge_BultenNo" defaultValue={getDefult("yapiBelge_BultenNo")} key={getDefult("yapiBelge_BultenNo")} onChange={handleInputChange} />

                    </div>
                </div>

                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">Onay Kod</label>
                        <input type="number" className="form-control form-control-sm" id="yapiBelge_OnayKod" name="yapiBelge_OnayKod" defaultValue={getDefult("yapiBelge_OnayKod")} key={getDefult("yapiBelge_OnayKod")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Sistem Onay Tarih</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelge_SistemOnayTarih" name="yapiBelge_SistemOnayTarih" defaultValue={getDefult("yapiBelge_SistemOnayTarih")} key={getDefult("yapiBelge_SistemOnayTarih")} onChange={handleInputChange} />
                    </div>

                </div>


            </div>


            {/*-------------------------------------------------------------------Yapi Belge Adres Bilgileri------------------------------------------*/}
            <div className="bg-white rounded-3 p-2 mt-4 shadow-sm">
                <div className="row" id="YapiBelgeAdresBilgileri">
                    <div className="text-start col-12 fw-bold text-primary">
                        Yapi Belge Adres Bilgileri
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label"> Il</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeAdres_IlAdi" name="yapiBelgeAdres_IlAdi" defaultValue={getDefult("yapiBelgeAdres_IlAdi")} key={getDefult("yapiBelgeAdres_IlAdi")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Il Kimlik No</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeAdres_IlKimlikNo" name="yapiBelgeAdres_IlKimlikNo" value={getDefult("yapiBelgeAdres_IlKimlikNo")} key={getDefult("yapiBelgeAdres_IlKimlikNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Ilçe</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeAdres_IlceAdi" name="yapiBelgeAdres_IlceAdi" defaultValue={getDefult("yapiBelgeAdres_IlceAdi")} key={getDefult("yapiBelgeAdres_IlceAdi")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Ilce Kimlik No</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeAdres_IlceKimlikNo" name="yapiBelgeAdres_IlceKimlikNo" defaultValue={getDefult("yapiBelgeAdres_IlceKimlikNo")} key={getDefult("yapiBelgeAdres_IlceKimlikNo")} onChange={handleInputChange} />
                    </div>


                </div>

                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">Mevki</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeAdres_MezraMevkiAd" name="yapiBelgeAdres_MezraMevkiAd" defaultValue={getDefult("yapiBelgeAdres_MezraMevkiAd")} key={getDefult("yapiBelgeAdres_MezraMevkiAd")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Mahalle</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeAdres_MahalleAdi" name="yapiBelgeAdres_MahalleAdi" defaultValue={getDefult("yapiBelgeAdres_MahalleAdi")} key={getDefult("yapiBelgeAdres_MahalleAdi")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Mahalle Kimlik No</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeAdres_MahalleKimlikNo" name="yapiBelgeAdres_MahalleKimlikNo" defaultValue={getDefult("yapiBelgeAdres_MahalleKimlikNo")} key={getDefult("yapiBelgeAdres_MahalleKimlikNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Mahalle Tanıtım Kod</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeAdres_MahalleTanitimKod" name="yapiBelgeAdres_MahalleTanitimKod" defaultValue={getDefult("yapiBelgeAdres_MahalleTanitimKod")} key={getDefult("yapiBelgeAdres_MahalleTanitimKod")} onChange={handleInputChange} />
                    </div>

                </div>

                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">Kurum</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeAdres_KurumAdi" name="yapiBelgeAdres_KurumAdi" defaultValue={getDefult("yapiBelgeAdres_KurumAdi")} key={getDefult("yapiBelgeAdres_KurumAdi")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Kurum Kimlik No</label>
                        <input type="number" className="form-control form-control-sm" id="yapiBelge_KurumKimlikNo" name="yapiBelge_KurumKimlikNo" defaultValue={getDefult("yapiBelge_KurumKimlikNo")} key={getDefult("yapiBelge_KurumKimlikNo")} onChange={handleInputChange} />
                    </div>

                    <div className="text-start col-3">
                        <label className="form-label">Bucak Adı</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeAdres_BucakAdi" name="yapiBelgeAdres_BucakAdi" defaultValue={getDefult("yapiBelgeAdres_BucakAdi")} key={getDefult("yapiBelgeAdres_BucakAdi")} onChange={handleInputChange} />
                    </div>

                    <div className="text-start col-3">
                        <label className="form-label">Bucak Kimlik No</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeAdres_BucakKimlikNo" name="yapiBelgeAdres_BucakKimlikNo" defaultValue={getDefult("yapiBelgeAdres_BucakKimlikNo")} key={getDefult("yapiBelgeAdres_BucakKimlikNo")} onChange={handleInputChange} />
                    </div>



                </div>



                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">Koy Kimlik No</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeAdres_KoyKimlikNo" name="yapiBelgeAdres_KoyKimlikNo" defaultValue={getDefult("yapiBelgeAdres_KoyKimlikNo")} key={getDefult("yapiBelgeAdres_KoyKimlikNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Koy Adı</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeAdres_KoyAdi" name="yapiBelgeAdres_KoyAdi" defaultValue={getDefult("yapiBelgeAdres_KoyAdi")} key={getDefult("yapiBelgeAdres_KoyAdi")} onChange={handleInputChange} />
                    </div>

                    <div className="text-start col-3">
                        <label className="form-label">Bina Kimlik No</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeAdres_BinaKimlikNo" name="yapiBelgeAdres_BinaKimlikNo" defaultValue={getDefult("yapiBelgeAdres_BinaKimlikNo")} key={getDefult("yapiBelgeAdres_BinaKimlikNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Bagımsız Bolum Kimlik No</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeAdres_BagimsizBolumKimlikNo" name="yapiBelgeAdres_BagimsizBolumKimlikNo" defaultValue={getDefult("yapiBelgeAdres_BagimsizBolumKimlikNo")} key={getDefult("yapiBelgeAdres_BagimsizBolumKimlikNo")} onChange={handleInputChange} />
                    </div>
                </div>


                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">Adres No</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeAdres_AdresNo" name="yapiBelgeAdres_AdresNo" defaultValue={getDefult("yapiBelgeAdres_AdresNo")} key={getDefult("yapiBelgeAdres_AdresNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Kısmi Bagımsız Bolum No</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeAdres_KismiBagimsizBolumNo" name="yapiBelgeAdres_KismiBagimsizBolumNo" defaultValue={getDefult("yapiBelgeAdres_KismiBagimsizBolumNo")} key={getDefult("yapiBelgeAdres_KismiBagimsizBolumNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Bagimsiz Bolum No</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeAdres_BagimsizBolumNo" name="yapiBelgeAdres_BagimsizBolumNo" defaultValue={getDefult("yapiBelgeAdres_BagimsizBolumNo")} key={getDefult("yapiBelgeAdres_BagimsizBolumNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Blok No</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeAdres_BlokNo" name="yapiBelgeAdres_BlokNo" defaultValue={getDefult("yapiBelgeAdres_BlokNo")} key={getDefult("yapiBelgeAdres_BlokNo")} onChange={handleInputChange} />
                    </div>
                </div>



                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">Pafta No</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeAdres_PaftaNo" name="yapiBelgeAdres_PaftaNo" defaultValue={getDefult("yapiBelgeAdres_PaftaNo")} key={getDefult("yapiBelgeAdres_PaftaNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Ada No</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeAdres_AdaNo" name="yapiBelgeAdres_AdaNo" defaultValue={getDefult("yapiBelgeAdres_AdaNo")} key={getDefult("yapiBelgeAdres_AdaNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Parsel No</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeAdres_ParselNo" name="yapiBelgeAdres_ParselNo" defaultValue={getDefult("yapiBelgeAdres_ParselNo")} key={getDefult("yapiBelgeAdres_ParselNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Dış Kapi No</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeAdres_DisKapiNo" name="yapiBelgeAdres_DisKapiNo" defaultValue={getDefult("yapiBelgeAdres_DisKapiNo")} key={getDefult("yapiBelgeAdres_DisKapiNo")} onChange={handleInputChange} />
                    </div>
                </div>


                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">Numarataj Kimlik No</label>
                        <input type="number" className="form-control form-control-sm" id="yapiBelgeAdres_NumaratajKimlikNo" name="yapiBelgeAdres_NumaratajKimlikNo" defaultValue={getDefult("yapiBelgeAdres_NumaratajKimlikNo")} key={getDefult("yapiBelgeAdres_NumaratajKimlikNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Csbm Tanıtım Kod</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeAdres_CsbmTanitimKod" name="yapiBelgeAdres_CsbmTanitimKod" defaultValue={getDefult("yapiBelgeAdres_CsbmTanitimKod")} key={getDefult("yapiBelgeAdres_CsbmTanitimKod")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-6">
                        <label className="form-label">Açık Adres</label>
                        <input type="number" className="form-control form-control-sm" id="yapiBelgeAdres_AcikAdres" name="yapiBelgeAdres_AcikAdres" defaultValue={getDefult("yapiBelgeAdres_AcikAdres")} key={getDefult("yapiBelgeAdres_AcikAdres")} onChange={handleInputChange} />
                    </div>
                </div>
            </div>


            {/*----------------------------------------------------Ruhsat---------------------------------------------------------*/}





            <div className="bg-white rounded-3 p-2 mt-4 shadow-sm">

                <div className="row" id="Ruhsat">
                    <div className="text-start col-12 fw-bold text-primary ">
                        Ruhsat
                    </div>

                </div>
                <div className="row mt-1">
                    <div className="text-start col-3">
                        <label className="form-label">Ruhsat Verilis Amaç</label>
                        <select disabled class="form-select form-select-sm" aria-label="Default select example" id="ruhsat_RuhsatVerilisAmacKod" name="ruhsat_RuhsatVerilisAmacKod" onChange={handleInputChange} >
                            <option selected={"" == getDefult("ruhsat_RuhsatVerilisAmacKod")}></option>
                            <option selected={"1" == getDefult("ruhsat_RuhsatVerilisAmacKod")} value="1">YeniYapi</option>
                            <option selected={"2" == getDefult("ruhsat_RuhsatVerilisAmacKod")} value="2">Yenileme</option>
                            <option selected={"3" == getDefult("ruhsat_RuhsatVerilisAmacKod")} value="3">Yeniden</option>
                            <option selected={"4" == getDefult("ruhsat_RuhsatVerilisAmacKod")} value="4">Ek Bina</option>
                            <option selected={"5" == getDefult("ruhsat_RuhsatVerilisAmacKod")} value="5">Kat Ilavesi</option>
                            <option selected={"6" == getDefult("ruhsat_RuhsatVerilisAmacKod")} value="6">Ilave</option>
                            <option selected={"7" == getDefult("ruhsat_RuhsatVerilisAmacKod")} value="7">Gecici</option>
                            <option selected={"8" == getDefult("ruhsat_RuhsatVerilisAmacKod")} value="8">Tadilat</option>
                            <option selected={"9" == getDefult("ruhsat_RuhsatVerilisAmacKod")} value="9">Dolgu</option>
                            <option selected={"10" == getDefult("ruhsat_RuhsatVerilisAmacKod")} value="10">Restorasyon</option>
                            <option selected={"11" == getDefult("ruhsat_RuhsatVerilisAmacKod")} value="11">Guclendirme</option>
                            <option selected={"12" == getDefult("ruhsat_RuhsatVerilisAmacKod")} value="12">Kullanim Degisimi</option>
                            <option selected={"13" == getDefult("ruhsat_RuhsatVerilisAmacKod")} value="13">Fosseptik</option>
                            <option selected={"14" == getDefult("ruhsat_RuhsatVerilisAmacKod")} value="14">Mekanik Tesisat</option>
                            <option selected={"15" == getDefult("ruhsat_RuhsatVerilisAmacKod")} value="15">Elektrik Tesisati</option>
                            <option selected={"16" == getDefult("ruhsat_RuhsatVerilisAmacKod")} value="16">Isim Degisikligi</option>
                            <option selected={"17" == getDefult("ruhsat_RuhsatVerilisAmacKod")} value="17">Istinat Duvari</option>
                            <option selected={"18" == getDefult("ruhsat_RuhsatVerilisAmacKod")} value="18">Bahce Duvari</option>
                            <option selected={"19" == getDefult("ruhsat_RuhsatVerilisAmacKod")} value="19">Diger</option>
                            <option selected={"20" == getDefult("ruhsat_RuhsatVerilisAmacKod")} value="20">Diger Kacak Yapi</option>
                            <option selected={"21" == getDefult("ruhsat_RuhsatVerilisAmacKod")} value="21">Diger Iksa</option>
                            <option selected={"22" == getDefult("ruhsat_RuhsatVerilisAmacKod")} value="22">Diger 775 Sayili Gece kondu Kanunu</option>
                            <option selected={"23" == getDefult("ruhsat_RuhsatVerilisAmacKod")} value="23">Diger Hes</option>
                            <option selected={"24" == getDefult("ruhsat_RuhsatVerilisAmacKod")} value="24">Diger Baz</option>
                        </select>

                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Ruhsat No</label>
                        <input type="text" className="form-control form-control-sm" id="ruhsat_RuhsatNo" name="ruhsat_RuhsatNo" defaultValue={getDefult("ruhsat_RuhsatNo")} key={getDefult("ruhsat_RuhsatNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Ruhsat Onay Tarih</label>
                        <input type="text" className="form-control form-control-sm" id="ruhsat_RuhsatOnayTarih" name="ruhsat_RuhsatOnayTarih" defaultValue={getDefult("ruhsat_RuhsatOnayTarih")} key={getDefult("ruhsat_RuhsatOnayTarih")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Ilk Ruhsat Tarih </label>
                        <input type="text" className="form-control form-control-sm" id="ruhsat_IlkRuhsatTarih" name="ruhsat_IlkRuhsatTarih" defaultValue={getDefult("ruhsat_IlkRuhsatTarih")} key={getDefult("ruhsat_IlkRuhsatTarih")} onChange={handleInputChange} />
                    </div>
                </div>

                <div className="row mt-2">

                    <div className="text-start col-3">
                        <label className="form-label">Ilk Ruhsat No </label>
                        <input type="text" className="form-control form-control-sm" id="ruhsat_IlkRuhsatNo" name="ruhsat_IlkRuhsatNo" defaultValue={getDefult("ruhsat_IlkRuhsatNo")} key={getDefult("ruhsat_IlkRuhsatNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Imar Plan Onay Tarihi</label>
                        <input type="text" className="form-control form-control-sm" id="ruhsat_ImarPlanOnayTarih" name="ruhsat_ImarPlanOnayTarih" defaultValue={getDefult("ruhsat_ImarPlanOnayTarih")} key={getDefult("ruhsat_ImarPlanOnayTarih")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Imar Durum Tarihi </label>
                        <input type="text" className="form-control form-control-sm" id="ruhsat_ImarDurumTarih" name="ruhsat_ImarDurumTarih" defaultValue={getDefult("ruhsat_ImarDurumTarih")} key={getDefult("ruhsat_ImarDurumTarih")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Imar Durum No </label>
                        <input type="text" className="form-control form-control-sm" id="ruhsat_ImarDurumNo" name="ruhsat_ImarDurumNo" defaultValue={getDefult("ruhsat_ImarDurumNo")} key={getDefult("ruhsat_ImarDurumNo")} onChange={handleInputChange} />
                    </div>
                </div>
                <div className="row mt-2">

                    <div className="text-start col-3">
                        <label className="form-label">Zemin Etud Onay Tarih</label>
                        <input type="text" className="form-control form-control-sm" id="ruhsat_ZeminEtudOnayTarih" name="ruhsat_ZeminEtudOnayTarih" defaultValue={getDefult("ruhsat_ZeminEtudOnayTarih")} key={getDefult("ruhsat_ZeminEtudOnayTarih")} onChange={handleInputChange} />
                    </div>

                    <div className="text-start col-3">
                        <label className="form-label">Parselasyon Onay Tarih</label>
                        <input type="text" className="form-control form-control-sm" id="ruhsat_ParselasyonPlanOnayTarih" name="ruhsat_ParselasyonPlanOnayTarih" defaultValue={getDefult("ruhsat_ParselasyonPlanOnayTarih")} key={getDefult("ruhsat_ParselasyonPlanOnayTarih")} onChange={handleInputChange} />
                    </div>

                    <div className="text-start col-3">
                        <label className="form-label">Parsel Kullanma Amac </label>
                        <input type="text" className="form-control form-control-sm" id="ruhsat_ParselKullanmaAmac" name="ruhsat_ParselKullanmaAmac" defaultValue={getDefult("ruhsat_ParselKullanmaAmac")} key={getDefult("ruhsat_ParselKullanmaAmac")} onChange={handleInputChange} />
                    </div>

                    <div className="text-start col-3">
                        <label className="form-label">Parsel Alan (m2) </label>
                        <input type="number" className="form-control form-control-sm" id="ruhsat_ParselAlan" name="ruhsat_ParselAlan" defaultValue={getDefult("ruhsat_ParselAlan")} key={getDefult("ruhsat_ParselAlan")} onChange={handleInputChange} />
                    </div>



                </div>

                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">Tapu Tescil Belge Veren Kurum</label>
                        <input type="text" className="form-control form-control-sm" id="ruhsat_TapuTescilBelgeVerenKurum" name="ruhsat_TapuTescilBelgeVerenKurum" defaultValue={getDefult("ruhsat_TapuTescilBelgeVerenKurum")} key={getDefult("ruhsat_TapuTescilBelgeVerenKurum")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Tapu Tescil Belge Tarih</label>
                        <input type="text" className="form-control form-control-sm" id="ruhsat_TapuTescilBelgeTarih" name="ruhsat_TapuTescilBelgeTarih" defaultValue={getDefult("ruhsat_TapuTescilBelgeTarih")} key={getDefult("ruhsat_TapuTescilBelgeTarih")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Tapu Tescil Belge No</label>
                        <input type="text" className="form-control form-control-sm" id="ruhsat_TapuTescilBelgeNo" name="ruhsat_TapuTescilBelgeNo" defaultValue={getDefult("ruhsat_TapuTescilBelgeNo")} key={getDefult("ruhsat_TapuTescilBelgeNo")} onChange={handleInputChange} />
                    </div>


                    <div className="text-start col-3">
                        <label className="form-label">Çed Rapor Onay Tarihi </label>
                        <input type="text" className="form-control form-control-sm" id="ruhsat_CedRaporOnayTarih" name="ruhsat_CedRaporOnayTarih" defaultValue={getDefult("ruhsat_CedRaporOnayTarih")} key={getDefult("ruhsat_CedRaporOnayTarih")} onChange={handleInputChange} />
                    </div>

                </div>

                <div className="row mt-4">

                    <div className="text-start col-3">
                        <label className="form-label">Planlanan Insaat Baslama Tarih</label>
                        <input type="text" className="form-control form-control-sm" id="ruhsat_PlanlananInsaatBaslamaTarih" name="ruhsat_PlanlananInsaatBaslamaTarih" defaultValue={getDefult("ruhsat_PlanlananInsaatBaslamaTarih")} key={getDefult("ruhsat_PlanlananInsaatBaslamaTarih")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Planlanan Insaat Bitirme Tarih</label>
                        <input type="text" className="form-control form-control-sm" id="ruhsat_PlanlananInsaatBitirmeTarih" name="ruhsat_PlanlananInsaatBitirmeTarih" defaultValue={getDefult("ruhsat_PlanlananInsaatBitirmeTarih")} key={getDefult("ruhsat_PlanlananInsaatBitirmeTarih")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Ruhsat Gecerlilik Tarih</label>
                        <input type="text" className="form-control form-control-sm" id="ruhsat_RuhsatGecerlilikTarih" name="ruhsat_RuhsatGecerlilikTarih" defaultValue={getDefult("ruhsat_RuhsatGecerlilikTarih")} key={getDefult("ruhsat_RuhsatGecerlilikTarih")} onChange={handleInputChange} />
                    </div>

                </div>

                <div className="row mt-2">

                    <div className="text-start col-3">
                        <label className="form-label">Insaat Baslama Tarihi </label>
                        <input type="text" className="form-control form-control-sm" id="ruhsat_InsaatBaslamaTarih" name="ruhsat_InsaatBaslamaTarih" defaultValue={getDefult("ruhsat_InsaatBaslamaTarih")} key={getDefult("ruhsat_InsaatBaslamaTarih")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Insaat Baslama Tarihi </label>
                        <input type="text" className="form-control form-control-sm" id="ruhsat_InsaatBaslamaTutanakSayi" name="ruhsat_InsaatBaslamaTutanakSayi" defaultValue={getDefult("ruhsat_InsaatBaslamaTutanakSayi")} key={getDefult("ruhsat_InsaatBaslamaTutanakSayi")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Insaat Bitirme Tarihi</label>
                        <input type="text" className="form-control form-control-sm" id="ruhsat_InsaatBitirmeTarih" name="ruhsat_InsaatBitirmeTarih" defaultValue={getDefult("ruhsat_InsaatBitirmeTarih")} key={getDefult("ruhsat_InsaatBitirmeTarih")} onChange={handleInputChange} />
                    </div>


                </div>
            </div>






            {/*-------------------------------------------------------------------Yapı Mütahhit Bilgileri-----------------------------------------------*/}
            <div className="bg-white rounded-3 p-2 mt-4 shadow-sm">
                <div className="row" id="YapıMütahhitBilgileri">
                    <div className="text-start col-8 fw-bold text-primary ">
                        Yapı Mütahhit Bilgileri
                    </div>
                    <div className="text-start col-4 text-end">
                        <button type="button" className=" btn btn-success  btn-sm text-decoration-none " style={{ "border-radius": "8px" }} onClick={YambisSorgula}>
                            YAMBIS Sorgula
                        </button>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">Hukuki Varlık Kod </label>

                        <select class="form-select form-select-sm" id="yapiMuteahhit_HukukiVarlikKod" name="yapiMuteahhit_HukukiVarlikKod" defaultValue={getDefult("yapiMuteahhit_HukukiVarlikKod")} key={getDefult("yapiMuteahhit_HukukiVarlikKod")} onChange={handleInputChange} >
                            <option  ></option>
                            <option value="1" selected={getDefult("yapiMuteahhit_HukukiVarlikKod") == "1"}>Tuzel</option>
                            <option value="2" selected={getDefult("yapiMuteahhit_HukukiVarlikKod") == "2"}>Gerçek</option>
                            <option value="3" selected={getDefult("yapiMuteahhit_HukukiVarlikKod") == "3"}>Yabancı</option>
                        </select>

                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">TC Kimlik No </label>
                        <input type="number" className="form-control form-control-sm" id="yapiMuteahhit_TCKimlikNo" name="yapiMuteahhit_TCKimlikNo" defaultValue={getDefult("yapiMuteahhit_TCKimlikNo")} key={getDefult("yapiMuteahhit_TCKimlikNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Ad </label>
                        <input type="text" className="form-control form-control-sm" id="yapiMuteahhit_Ad" name="yapiMuteahhit_Ad" defaultValue={getDefult("yapiMuteahhit_Ad")} key={getDefult("yapiMuteahhit_Ad")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Soyad </label>
                        <input type="text" className="form-control form-control-sm" id="yapiMuteahhit_Soyad" name="yapiMuteahhit_Soyad" defaultValue={getDefult("yapiMuteahhit_Soyad")} key={getDefult("yapiMuteahhit_Soyad")} onChange={handleInputChange} />
                    </div>
                </div>
                <div className="row mt-2">

                    <div className="text-start col-3">
                        <label className="form-label">Unvan </label>
                        <input type="text" className="form-control form-control-sm" id="yapiMuteahhit_Unvan" name="yapiMuteahhit_Unvan" defaultValue={getDefult("yapiMuteahhit_Unvan")} key={getDefult("yapiMuteahhit_Unvan")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Oda Sicil No</label>
                        <input type="text" className="form-control form-control-sm" id="yapiMuteahhit_OdaSicilNo" name="yapiMuteahhit_OdaSicilNo" defaultValue={getDefult("yapiMuteahhit_OdaSicilNo")} key={getDefult("yapiMuteahhit_OdaSicilNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Vergi Daire </label>
                        <input type="text" className="form-control form-control-sm" id="yapiMuteahhit_VergiDaire" name="yapiMuteahhit_VergiDaire" defaultValue={getDefult("yapiMuteahhit_VergiDaire")} key={getDefult("yapiMuteahhit_VergiDaire")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Vergi Kimlik No </label>
                        <input type="number" className="form-control form-control-sm" id="yapiMuteahhit_VergiKimlikNo" name="yapiMuteahhit_VergiKimlikNo" defaultValue={getDefult("yapiMuteahhit_VergiKimlikNo")} key={getDefult("yapiMuteahhit_VergiKimlikNo")} onChange={handleInputChange} />
                    </div>

                </div>
                <div className="row mt-2">

                    <div className="text-start col-3">
                        <label className="form-label">Sigorta Sicil No</label>
                        <input type="text" className="form-control form-control-sm" id="yapiMuteahhit_SigortaSicilNo" name="yapiMuteahhit_SigortaSicilNo" defaultValue={getDefult("yapiMuteahhit_SigortaSicilNo")} key={getDefult("yapiMuteahhit_SigortaSicilNo")} onChange={handleInputChange} />
                    </div>


                    <div className="text-start col-3">
                        <label className="form-label">Yetki Belge No</label>
                        <input type="text" className="form-control form-control-sm" id="yapiMuteahhit_YetkiBelgeNo" name="yapiMuteahhit_YetkiBelgeNo" defaultValue={getDefult("yapiMuteahhit_YetkiBelgeNo")} key={getDefult("yapiMuteahhit_YetkiBelgeNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Sözleşme Tarih</label>
                        <input type="text" className="form-control form-control-sm" id="yapiMuteahhit_SSozlesmeTarih" name="yapiMuteahhit_SSozlesmeTarih" defaultValue={getDefult("yapiMuteahhit_SSozlesmeTarih")} key={getDefult("yapiMuteahhit_SSozlesmeTarih")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Sözleşme No</label>
                        <input type="text" className="form-control form-control-sm" id="yapiMuteahhit_SozlesmeNo" name="yapiMuteahhit_SozlesmeNo" defaultValue={getDefult("yapiMuteahhit_SozlesmeNo")} key={getDefult("yapiMuteahhit_SozlesmeNo")} onChange={handleInputChange} />
                    </div>



                </div>
                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">Bagimsiz Bolum Kimlik No</label>
                        <input type="number" className="form-control form-control-sm" id="yapiMuteahhit_BagimsizBolumKimlikNo" name="yapiMuteahhit_BagimsizBolumKimlikNo" defaultValue={getDefult("yapiMuteahhit_BagimsizBolumKimlikNo")} key={getDefult("yapiMuteahhit_BagimsizBolumKimlikNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-6">
                        <label className="form-label">Acik Adres </label>
                        <input type="text" className="form-control form-control-sm" id="yapiMuteahhit_AcikAdres" name="yapiMuteahhit_AcikAdres" defaultValue={getDefult("yapiMuteahhit_AcikAdres")} key={getDefult("yapiMuteahhit_AcikAdres")} onChange={handleInputChange} />

                    </div>
                </div>
                <div className="row mt-1">
                    <div className="text-start col-12" id="YambisSorgula">

                    </div>

                </div>
            </div>

            {/* -------------------------------------------------------------  Şantiye Şefi Bilgileri--------------*/}
            <div className="bg-white rounded-3 p-2 mt-4 shadow-sm">

                <div className="row" id="ŞantiyeŞefiBilgileri">
                    <div className="text-start col-8 fw-bold text-primary ">
                        Şantiye Şefi Bilgileri
                    </div>
                    <div className="text-end col-4 ">
                        <button type="button" className="btn btn-success  btn-sm text-decoration-none " style={{ "border-radius": "8px" }} onClick={SantiyeSefiSorgula}>
                            Sorgula
                        </button>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="text-start col-4">
                        <label className="form-label">Adı </label>
                        <input type="text" className="form-control form-control-sm" id="santiyeSef_Ad" name="santiyeSef_Ad" defaultValue={getDefult("santiyeSef_Ad")} key={getDefult("santiyeSef_Ad")} onChange={handleInputChange} />


                    </div>
                    <div className="text-start col-4">
                        <label className="form-label">Soyadı </label>
                        <input type="text" className="form-control form-control-sm" id="santiyeSef_Soyad" name="santiyeSef_Soyad" defaultValue={getDefult("santiyeSef_Soyad")} key={getDefult("santiyeSef_Soyad")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-4">
                        <label className="form-label">TC. Kimlik Numarası </label>
                        <input type="text" className="form-control form-control-sm" id="santiyeSef_TC" name="santiyeSef_TC" defaultValue={getDefult("santiyeSef_TC")} key={getDefult("santiyeSef_TC")} onChange={handleInputChange} />
                    </div>
                </div>


                <div className="row mt-2">
                    <div className="text-start col-4">
                        <label className="form-label">Unvanı </label>
                        <input type="text" className="form-control form-control-sm" id="santiyeSef_Unvan" name="santiyeSef_Unvan" defaultValue={getDefult("santiyeSef_Unvan")} key={getDefult("santiyeSef_Unvan")} onChange={handleInputChange} />


                    </div>

                    <div className="text-start col-4">
                        <label className="form-label">Meslek</label><br />

                        <select class="form-select form-select-sm" id="santiyeSef_Meslek" name="santiyeSef_Meslek" onChange={handleInputChange} >
                            <option  ></option>
                            <option value="4419" selected={getDefult("santiyeSef_Meslek") == "4419"}>İnşaat Mühendisi</option>
                            <option value="4420" selected={getDefult("santiyeSef_Meslek") == "4420"}>Makine Mühendisi</option>
                            <option value="4421" selected={getDefult("santiyeSef_Meslek") == "4421"}>Elektrik Mühendisi</option>
                            <option value="4422" selected={getDefult("santiyeSef_Meslek") == "4422"}>Elektrik Elektronik Mühendisi</option>
                            <option value="4423" selected={getDefult("santiyeSef_Meslek") == "4423"}>Mimar</option>
                            <option value="4424" selected={getDefult("santiyeSef_Meslek") == "4424"}>Teknik Öğretmen</option>
                            <option value="4425" selected={getDefult("santiyeSef_Meslek") == "4425"}>Tekniker</option>
                        </select>
                    </div>

                    <div className="text-start col-4">
                        <label className="form-label">Oda Sicil No</label>
                        <input type="text" className="form-control form-control-sm" id="santiyeSef_OdaSicilNo" name="santiyeSef_OdaSicilNo" defaultValue={getDefult("santiyeSef_OdaSicilNo")} key={getDefult("santiyeSef_OdaSicilNo")} onChange={handleInputChange} />
                    </div>

                </div>


                <div className="row mt-2">
                    <div className="text-start col-4">
                        <label className="form-label">Sözleşme Tarihi </label>
                        <input type="text" className="form-control form-control-sm" id="santiyeSef_SozlesmeTarih" name="santiyeSef_SozlesmeTarih" defaultValue={getDefult("santiyeSef_SozlesmeTarih")} key={getDefult("santiyeSef_SozlesmeTarih")} onChange={handleInputChange} />


                    </div>
                    <div className="text-start col-4">
                        <label className="form-label">Seözleşme No</label>
                        <input type="text" className="form-control form-control-sm" id="santiyeSef_SozlesmeNo" name="santiyeSef_SozlesmeNo" defaultValue={getDefult("santiyeSef_SozlesmeNo")} key={getDefult("santiyeSef_SozlesmeNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-4">
                        <label className="form-label">Sigorta Sicil No</label><br />
                        <input type="text" className="form-control form-control-sm" id="santiyeSef_SigortaSicilNo" name="santiyeSef_SigortaSicilNo" defaultValue={getDefult("santiyeSef_SigortaSicilNo")} key={getDefult("santiyeSef_SigortaSicilNo")} onChange={handleInputChange} />
                    </div>
                </div>


                <div className="row mt-2">
                    <div className="text-start col-4">
                        <label className="form-label">Bagimsiz BolumKimlik No</label><br />
                        <input type="text" className="form-control form-control-sm" id="santiyeSef_BagimsizBolumKimlikNo" name="santiyeSef_BagimsizBolumKimlikNo" defaultValue={getDefult("santiyeSef_BagimsizBolumKimlikNo")} key={getDefult("santiyeSef_BagimsizBolumKimlikNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-8">
                        <label className="form-label">Adres</label><br />
                        <input type="text" className="form-control form-control-sm" id="santiyeSef_Adres" name="santiyeSef_Adres" defaultValue={getDefult("santiyeSef_Adres")} key={getDefult("santiyeSef_Adres")} onChange={handleInputChange} />
                    </div>
                </div>



                <div className="row mt-2">
                    <div className="text-start col-12" id="santiyeSefSonuc">
                    </div>
                </div>
            </div>

            {/* -------------------------------------------------------------Bagimsiz Bolum--------------*/}
            <div className="bg-white rounded-3 mt-2 shadow-sm p-2" id="MaksBagimsizBolum">
                <MaksBagimsizBolum rec={rec} key={rec.id} userData={userData} />
            </div>

            {/* ------------------------------------------------------------- Yapi Ozellik Bilgileri--------------*/}

            <div className="bg-white rounded-3 p-2 mt-4 shadow-sm">

                <div className="row" id="YapiOzellikBilgileri">
                    <div className="text-start col-12 fw-bold text-primary">
                        Yapi Ozellik Bilgileri
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">Benzer Yapı Sayı</label>
                        <input type="text" className="form-control form-control-sm" id="yapiOzellik_BenzerYapiSayi" name="yapiOzellik_BenzerYapiSayi" defaultValue={getDefult("yapiOzellik_BenzerYapiSayi")} key={getDefult("yapiOzellik_BenzerYapiSayi")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Bagimsiz Bolum Sayi </label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_BagimsizBolumSayi" name="yapiOzellik_BagimsizBolumSayi" defaultValue={getDefult("yapiOzellik_BagimsizBolumSayi")} key={getDefult("yapiOzellik_BagimsizBolumSayi")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Konut Birim Sayi </label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_KonutBirimSayi" name="yapiOzellik_KonutBirimSayi" defaultValue={getDefult("yapiOzellik_KonutBirimSayi")} key={getDefult("yapiOzellik_KonutBirimSayi")} onChange={handleInputChange} />
                    </div>

                    <div className="text-start col-3">
                        <label className="form-label">Taban Alan </label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_TabanAlan" name="yapiOzellik_TabanAlan" defaultValue={getDefult("yapiOzellik_TabanAlan")} key={getDefult("yapiOzellik_TabanAlan")} onChange={handleInputChange} />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">Yapi Insaat Alan </label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_YapiInsaatAlan" name="yapiOzellik_YapiInsaatAlan" defaultValue={getDefult("yapiOzellik_YapiInsaatAlan")} key={getDefult("yapiOzellik_YapiInsaatAlan")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Toplam Yapı Sayı </label>
                        <input type="text" className="form-control form-control-sm" id="yapiOzellik_ToplamYapiSayi" name="yapiOzellik_ToplamYapiSayi" defaultValue={getDefult("yapiOzellik_ToplamYapiSayi")} key={getDefult("yapiOzellik_ToplamYapiSayi")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Toplam Bagimsiz Bolum </label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_ToplamBBSayi" name="yapiOzellik_ToplamBBSayi" defaultValue={getDefult("yapiOzellik_ToplamBBSayi")} key={getDefult("yapiOzellik_ToplamBBSayi")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Toplam Konut Birimi </label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_ToplamKonutBirimSayi" name="yapiOzellik_ToplamKonutBirimSayi" defaultValue={getDefult("yapiOzellik_ToplamKonutBirimSayi")} key={getDefult("yapiOzellik_ToplamKonutBirimSayi")} onChange={handleInputChange} />
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">Toplam Taban Alan </label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_ToplamTabanAlan" name="yapiOzellik_ToplamTabanAlan" defaultValue={getDefult("yapiOzellik_ToplamTabanAlan")} key={getDefult("yapiOzellik_ToplamTabanAlan")} onChange={handleInputChange} />
                    </div>



                    <div className="text-start col-3">
                        <label className="form-label">Toplam Yapi Insaat Alan </label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_ToplamYapiInsaatAlan" name="yapiOzellik_ToplamYapiInsaatAlan" defaultValue={getDefult("yapiOzellik_ToplamYapiInsaatAlan")} key={getDefult("yapiOzellik_ToplamYapiInsaatAlan")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Yol Kot Alt Kat Sayi </label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_YolKotAltKatSayi" name="yapiOzellik_YolKotAltKatSayi" defaultValue={getDefult("yapiOzellik_YolKotAltKatSayi")} key={getDefult("yapiOzellik_YolKotAltKatSayi")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Yol Kot Ust Kat Sayi</label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_YolKotUstKatSayi" name="yapiOzellik_YolKotUstKatSayi" defaultValue={getDefult("yapiOzellik_YolKotUstKatSayi")} key={getDefult("yapiOzellik_YolKotUstKatSayi")} onChange={handleInputChange} />
                    </div>
                </div>
                <div className="row mt-2">

                    <div className="text-start col-3">
                        <label className="form-label">Toplam Kat Sayi </label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_ToplamKatSayi" name="yapiOzellik_ToplamKatSayi" defaultValue={getDefult("yapiOzellik_ToplamKatSayi")} key={getDefult("yapiOzellik_ToplamKatSayi")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Toplam Yukseklik </label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_ToplamYukseklik" name="yapiOzellik_ToplamYukseklik" defaultValue={getDefult("yapiOzellik_ToplamYukseklik")} key={getDefult("yapiOzellik_ToplamYukseklik")} onChange={handleInputChange} />
                    </div>


                    <div className="text-start col-3">
                        <label className="form-label">Yol Kot Ust Yukseklik </label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_YolKotUstYukseklik" name="yapiOzellik_YolKotUstYukseklik" defaultValue={getDefult("yapiOzellik_YolKotUstYukseklik")} key={getDefult("yapiOzellik_YolKotUstYukseklik")} onChange={handleInputChange} />
                    </div>

                    <div className="text-start col-3">
                        <label className="form-label">Yol Kot Alt Yukseklik </label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_YolKotAltYukseklik" name="yapiOzellik_YolKotAltYukseklik" defaultValue={getDefult("yapiOzellik_YolKotAltYukseklik")} key={getDefult("yapiOzellik_YolKotAltYukseklik")} onChange={handleInputChange} />
                    </div>


                </div>

                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">Ilave Kat Sayı </label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_IlaveKatSayi" name="yapiOzellik_IlaveKatSayi" defaultValue={getDefult("yapiOzellik_IlaveKatSayi")} key={getDefult("yapiOzellik_IlaveKatSayi")} onChange={handleInputChange} />
                    </div>

                    <div className="text-start col-3">
                        <label className="form-label">Ilave Kat Yukseklik </label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_IlaveKatYukseklik" name="yapiOzellik_IlaveKatYukseklik" defaultValue={getDefult("yapiOzellik_IlaveKatYukseklik")} key={getDefult("yapiOzellik_IlaveKatYukseklik")} onChange={handleInputChange} />
                    </div>

                </div>
                <div className="row mt-2" >
                    <div className="text-start col-3">
                        <label className="form-label">Yapı Sınıfı </label>
                        <select class="form-select form-select-sm" id="yapiOzellik_Sinif_Kod" name="yapiOzellik_Sinif_Kod" onChange={handleInputChange} >
                            <option ></option>
                            <option value="1" selected={getDefult("yapiOzellik_Sinif_Kod") == "1"}>I</option>
                            <option value="2" selected={getDefult("yapiOzellik_Sinif_Kod") == "2"}>II</option>
                            <option value="3" selected={getDefult("yapiOzellik_Sinif_Kod") == "3"}>III</option>
                            <option value="4" selected={getDefult("yapiOzellik_Sinif_Kod") == "4"}>IV</option>
                            <option value="5" selected={getDefult("yapiOzellik_Sinif_Kod") == "5"}>V</option>
                        </select>
                    </div>

                    <div className="text-start col-3">
                        <label className="form-label">Grup No</label>
                        <input type="text" className="form-control form-control-sm" id="yapiOzellik_GrupNo" name="yapiOzellik_GrupNo" defaultValue={getDefult("yapiOzellik_GrupNo")} key={getDefult("yapiOzellik_GrupNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Grup</label>
                        <select class="form-select form-select-sm" id="yapiOzellik_Grup_Kod" name="yapiOzellik_Grup_Kod" onChange={handleInputChange} >
                            <option  ></option>
                            <option value="1" selected={getDefult("yapiOzellik_Sinif_Kod") == "1"}>A</option>
                            <option value="2" selected={getDefult("yapiOzellik_Sinif_Kod") == "2"}>B</option>
                            <option value="3" selected={getDefult("yapiOzellik_Sinif_Kod") == "3"}>C</option>
                            <option value="4" selected={getDefult("yapiOzellik_Sinif_Kod") == "4"}>D</option>
                        </select>

                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">M2 Maliyet </label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_M2Maliyet" name="yapiOzellik_M2Maliyet" defaultValue={getDefult("yapiOzellik_M2Maliyet")} key={getDefult("yapiOzellik_M2Maliyet")} onChange={handleInputChange} />
                    </div>


                </div>
                <div className="row mt-2">

                    <div className="text-start col-3">
                        <label className="form-label">Inşaat Maliyeti </label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_Maliyet" name="yapiOzellik_Maliyet" defaultValue={getDefult("yapiOzellik_Maliyet")} key={getDefult("yapiOzellik_Maliyet")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Arsa Deger </label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_ArsaDeger" name="yapiOzellik_ArsaDeger" defaultValue={getDefult("yapiOzellik_ArsaDeger")} key={getDefult("yapiOzellik_ArsaDeger")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Arsa Dahil Yapi Maliyet </label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_ArsaDahilYapiMaliyet" name="yapiOzellik_ArsaDahilYapiMaliyet" defaultValue={getDefult("yapiOzellik_ArsaDahilYapiMaliyet")} key={getDefult("yapiOzellik_ArsaDahilYapiMaliyet")} onChange={handleInputChange} />
                    </div>

                    <div className="text-start col-3">
                        <label className="form-label">Form Duzenlenen Maliyet </label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_FormDuzenlenenKisimMaliyet" name="yapiOzellik_FormDuzenlenenKisimMaliyet" defaultValue={getDefult("yapiOzellik_FormDuzenlenenKisimMaliyet")} key={getDefult("yapiOzellik_FormDuzenlenenKisimMaliyet")} onChange={handleInputChange} />
                    </div>


                </div>


                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">Guclendirme Maliyet</label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_GuclendirmeMaliyet" name="yapiOzellik_GuclendirmeMaliyet" defaultValue={getDefult("yapiOzellik_GuclendirmeMaliyet")} key={getDefult("yapiOzellik_GuclendirmeMaliyet")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Guclendirme Maliyet Oran </label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_GuclendirmeMaliyetOran" name="yapiOzellik_GuclendirmeMaliyetOran" defaultValue={getDefult("yapiOzellik_GuclendirmeMaliyetOran")} key={getDefult("yapiOzellik_GuclendirmeMaliyetOran")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">SigortaBedel</label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_SigortaBedel" name="yapiOzellik_SigortaBedel" defaultValue={getDefult("yapiOzellik_SigortaBedel")} key={getDefult("yapiOzellik_SigortaBedel")} onChange={handleInputChange} />
                    </div>
                </div>

                <div className="row mt-2">

                    <div className="text-start col-3">
                        <label className="form-label">Yıkılacak Alan </label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_YikilacakAlan" name="yapiOzellik_YikilacakAlan" defaultValue={getDefult("yapiOzellik_YikilacakAlan")} key={getDefult("yapiOzellik_YikilacakAlan")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Yıkılacak Kat Adet</label>
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_YikilacakKatAdet" name="yapiOzellik_YikilacakKatAdet" defaultValue={getDefult("yapiOzellik_YikilacakKatAdet")} key={getDefult("yapiOzellik_YikilacakKatAdet")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Yıkılacak Kat Alan</label><br />
                        <input type="number" className="form-control form-control-sm" id="yapiOzellik_YikilacakKatAlan" name="yapiOzellik_YikilacakKatAlan" defaultValue={getDefult("yapiOzellik_YikilacakKatAlan")} key={getDefult("yapiOzellik_YikilacakKatAlan")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Parsel Dışı Otopark</label><br />
                        <input type="checkbox" className="form-check-input" id="yapiOzellik_ParselDisiOtopark" name="yapiOzellik_ParselDisiOtopark" defaultChecked={getDefult("yapiOzellik_ParselDisiOtopark") == true} key={getDefult("yapiOzellik_ParselDisiOtopark")} onChange={handleInputChange} key={rec.yapiOzellik_ParselDisiOtopark} />
                    </div>
                </div>

            </div>
            {/* ------------------------------------------------------------- Tasiyici Sistem Bilgileri--------------*/}

            < div className="bg-white rounded-3 mt-2 shadow-sm p-2" id="Maksisitma">
                <Maksisitma rec={rec} key={rec.id} userData={userData} />
            </div >
            <div className="bg-white rounded-3 mt-2 shadow-sm p-2" id="MaksisitmaYakit">
                <MaksisitmaYakit rec={rec} key={rec.id} userData={userData} />
            </div>
            <div className="bg-white rounded-3 mt-2 shadow-sm p-2" id="MaksSuTemin">
                <MaksSuTemin rec={rec} key={rec.id} userData={userData} />
            </div>
            <div className="bg-white rounded-3 mt-2 shadow-sm p-2" id="MaksSuYakit">
                <MaksSuYakit rec={rec} key={rec.id} userData={userData} />
            </div>
            <div className="bg-white rounded-3 mt-2 shadow-sm p-2" id="MaksIcmesu">
                <MaksIcmesu rec={rec} key={rec.id} />
            </div>
            <div className="bg-white rounded-3 mt-2 shadow-sm p-2" id="MaksAtiksu">
                <MaksAtiksu rec={rec} key={rec.id} />
            </div>

            <div className="bg-white rounded-3 mt-2 shadow-sm p-2" id="MaksTasiyici">
                <MaksTasiyici rec={rec} key={rec.id} userData={userData} />
            </div>
            <div className="bg-white rounded-3 mt-2 shadow-sm p-2" id="MaksTesisat">
                <MaksTesisat rec={rec} key={rec.id} />
            </div>
            <div className="bg-white rounded-3 mt-2 shadow-sm p-2" id="MaksOrtakAlan">
                <MaksOrtakAlan rec={rec} key={rec.id} />
            </div>
            <div className="bg-white rounded-3 mt-2 shadow-sm p-2" id="MaksDuvardolgu">
                <MaksDuvardolgu rec={rec} key={rec.id} />
            </div>
            <div className="bg-white rounded-3 mt-2 shadow-sm p-2" id="MaksDoseme">
                <MaksDoseme rec={rec} key={rec.id} />
            </div>
            <div className="bg-white rounded-3 mt-2 shadow-sm p-2" id="MaksProje">
                <MaksProje rec={rec} key={rec.id} adres={selectedAdres} />
            </div>
            <div className="bg-white rounded-3 mt-2 shadow-sm p-2" id="MaksYapiDenetim">
                <MaksYapiDenetim rec={rec} key={rec.id} />
            </div>

            {/* ------------------------------------------------------------- Yapı Denetim Kurulus --------------*/}
            <div className="bg-white rounded-3 p-2 mt-2 shadow-sm" id="YapıDenetimKuruluşBilgileri">

                <div className="row">
                    <div className="text-start col-12 fw-bold text-primary ">
                        Yapı Denetim Kuruluş Bilgileri
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="text-start col-4">
                        <label className="form-label"> Ad </label>
                        <input type="text" className="form-control form-control-sm" id="yapiDenetimKurulus_Ad" name="yapiDenetimKurulus_Ad" defaultValue={getDefult("yapiDenetimKurulus_Ad")} key={getDefult("yapiDenetimKurulus_Ad")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-4">
                        <label className="form-label">Soyad</label>
                        <input type="text" className="form-control form-control-sm" id="yapiDenetimKurulus_Soyad" name="yapiDenetimKurulus_Soyad" defaultValue={getDefult("yapiDenetimKurulus_Soyad")} key={getDefult("yapiDenetimKurulus_Soyad")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-4">
                        <label className="form-label">TC Kimlik No </label>
                        <input type="text" className="form-control form-control-sm" id="yapiDenetimKurulus_TC" name="yapiDenetimKurulus_TC" defaultValue={getDefult("yapiDenetimKurulus_TC")} key={getDefult("yapiDenetimKurulus_TC")} onChange={handleInputChange} />
                    </div>
                </div>



                <div className="row mt-2">
                    <div className="text-start col-4">
                        <label className="form-label">Kuruluş Ad </label>
                        <input type="text" className="form-control form-control-sm" id="yapiDenetimKurulus_KuruluAd" name="yapiDenetimKurulus_KuruluAd" defaultValue={getDefult("yapiDenetimKurulus_KuruluAd")} key={getDefult("yapiDenetimKurulus_KuruluAd")} onChange={handleInputChange} />
                    </div>

                    <div className="text-start col-4">
                        <label className="form-label">Vergi Kimlik No</label>
                        <input type="text" className="form-control form-control-sm" id="yapiDenetimKurulus_VergiKimlikNo" name="yapiDenetimKurulus_VergiKimlikNo" defaultValue={getDefult("yapiDenetimKurulus_VergiKimlikNo")} key={getDefult("yapiDenetimKurulus_VergiKimlikNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-4">
                        <label className="form-label">İzin Belge No ve Sınıf</label><br />
                        <input type="text" className="form-control form-control-sm" id="yapiDenetimKurulus_izinBelgeNo" name="yapiDenetimKurulus_izinBelgeNo" defaultValue={getDefult("yapiDenetimKurulus_izinBelgeNo")} key={getDefult("yapiDenetimKurulus_izinBelgeNo")} onChange={handleInputChange} />
                    </div>
                </div>
                <div className="row mt-2">

                    <div className="text-start col-4">
                        <label className="form-label">Bağımsız Bolum Kimlik No </label>
                        <input type="text" className="form-control form-control-sm" id="yapiDenetimKurulus_BBkimlikNo" name="yapiDenetimKurulus_BBkimlikNo" defaultValue={getDefult("yapiDenetimKurulus_BBkimlikNo")} key={getDefult("yapiDenetimKurulus_BBkimlikNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-8">
                        <label className="form-label">Açık Adres </label>
                        <input type="text" className="form-control form-control-sm" id="yapiDenetimKurulus_AcikAdres" name="yapiDenetimKurulus_AcikAdres" defaultValue={getDefult("yapiDenetimKurulus_AcikAdres")} key={getDefult("yapiDenetimKurulus_AcikAdres")} onChange={handleInputChange} />
                    </div>
                </div>
            </div >


            {/* ------------------------------------------------------------- MuhtelifGelir --------------*/}
            <div className="bg-white rounded-3 mt-2 shadow-sm p-2" id="MaksMuhtelifGelir">
                <MaksMuhtelifGelir rec={rec} key={rec.id} />
            </div>

            {/* ------------------------------------------------------------- Toprak --------------*/}
            <div className="bg-white rounded-3 p-2 mt-2 shadow-sm">

                <div className="row" id="YapıToprakBilgileri">
                    <div className="text-start col-12 fw-bold text-primary ">
                        Yapı Toprak Bilgileri
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="text-start col-4">
                        <label className="form-label"> Dolgu Miktar </label>
                        <input type="text" className="form-control form-control-sm" id="toprak_dolguMiktar" name="toprak_dolguMiktar" defaultValue={getDefult("toprak_dolguMiktar")} key={getDefult("toprak_dolguMiktar")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-4">
                        <label className="form-label">Kazi Miktar</label>
                        <input type="text" className="form-control form-control-sm" id="toprak_kaziMiktar" name="toprak_kaziMiktar" defaultValue={getDefult("toprak_kaziMiktar")} key={getDefult("toprak_kaziMiktar")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-4">
                        <label className="form-label">Kullanilmayan Kazi Miktar </label>
                        <input type="text" className="form-control form-control-sm" id="toprak_KullanilmayanKaziMiktar" name="toprak_KullanilmayanKaziMiktar" defaultValue={getDefult("toprak_KullanilmayanKaziMiktar")} key={getDefult("toprak_KullanilmayanKaziMiktar")} onChange={handleInputChange} />
                    </div>
                </div>



                <div className="row mt-2">
                    <div className="text-start col-12">
                        <label className="form-label">Kullanilmayan Kazı Doküldüğü Yer </label>
                        <input type="text" className="form-control form-control-sm" id="toprak_kullanilmayanKaziDokYer" name="toprak_kullanilmayanKaziDokYer" defaultValue={getDefult("toprak_kullanilmayanKaziDokYer")} key={getDefult("toprak_kullanilmayanKaziDokYer")} onChange={handleInputChange} />
                    </div>
                </div>
            </div >

            {/* ------------------------------------------------------------- Gorevli--------------*/}

            <div className="bg-white rounded-3 mt-2 shadow-sm p-2" id="MaksGorevli">
                <MaksGorevli rec={rec} key={rec.id} userData={userData} />
            </div>


            {/* ------------------------------------------------------------- Diger Husus--------------*/}
            < div className="bg-white rounded-3 p-2 mt-2 shadow-sm" >

                <div className="row" id="YapiBelgeDigerHusus">
                    <div className="text-start col-12 fw-bold text-primary ">
                        Yapi Belge Diger Husus
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">Daire Adet </label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeDigerHusus_DaireAdet" name="yapiBelgeDigerHusus_DaireAdet" defaultValue={getDefult("yapiBelgeDigerHusus_DaireAdet")} key={getDefult("yapiBelgeDigerHusus_DaireAdet")} onChange={handleInputChange} />


                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Dukkan Adet </label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeDigerHusus_DukkanAdet" name="yapiBelgeDigerHusus_DukkanAdet" defaultValue={getDefult("yapiBelgeDigerHusus_DukkanAdet")} key={getDefult("yapiBelgeDigerHusus_DukkanAdet")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Diger Kullanim Adet </label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeDigerHusus_DigerKullanimAdet" name="yapiBelgeDigerHusus_DigerKullanimAdet" defaultValue={getDefult("yapiBelgeDigerHusus_DigerKullanimAdet")} key={getDefult("yapiBelgeDigerHusus_DigerKullanimAdet")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Ilk Yapı Kullanma Izin Belge</label><br />
                        <input type="checkbox" className="form-check-input" id="yapiBelgeDigerHusus_IlkYapiKullanmaIzinBelge" name="yapiBelgeDigerHusus_IlkYapiKullanmaIzinBelge" defaultChecked={getDefult("yapiBelgeDigerHusus_IlkYapiKullanmaIzinBelge") == true} key={getDefult("yapiBelgeDigerHusus_IlkYapiKullanmaIzinBelge")} onChange={handleInputChange} key={rec.yapiBelgeDigerHusus_IlkYapiKullanmaIzinBelge} />
                    </div>
                </div>


                <div className="row mt-2">
                    <div className="text-start col-6">
                        <label className="form-label">Diger Hususlar </label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeDigerHusus_DigerHususlar" name="yapiBelgeDigerHusus_DigerHususlar" defaultValue={getDefult("yapiBelgeDigerHusus_DigerHususlar")} key={getDefult("yapiBelgeDigerHusus_DigerHususlar")} onChange={handleInputChange} />


                    </div>
                    <div className="text-start col-6">
                        <label className="form-label">Raporda Yer Alacak Diger Tum Hususlar</label>
                        <input type="text" className="form-control form-control-sm" id="yapiBelgeDigerHusus_DigerHususlarTumAciklama" name="yapiBelgeDigerHusus_DigerHususlarTumAciklama" defaultValue={getDefult("yapiBelgeDigerHusus_DigerHususlarTumAciklama")} key={getDefult("yapiBelgeDigerHusus_DigerHususlarTumAciklama")} onChange={handleInputChange} />
                    </div>

                </div>

            </div >


            {/* ------------------------------------------------------------- Iş Yeri --------------*/}
            < div className="bg-white rounded-3 p-2 mt-2 shadow-sm" >

                <div className="row" id="YapiİşYeriBilgileri">
                    <div className="text-start col-12 fw-bold text-primary ">
                        Yapi İş Yeri Bilgileri
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">Isyeri Beyan Var Mi </label> <br/>
                        <input type="checkbox" className="form-check-input" id="yapiIsyeri_IsyeriBeyanVarMi" name="yapiIsyeri_IsyeriBeyanVarMi" defaultChecked={getDefult("yapiIsyeri_IsyeriBeyanVarMi") == true} key={getDefult("yapiIsyeri_IsyeriBeyanVarMi")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label"> Defter Tip </label>

                        <select class="form-select form-select-sm" id="yapiIsyeri_DefterTipKod" name="yapiIsyeri_DefterTipKod" onChange={handleInputChange} >
                            <option  ></option>
                            <option value="1" selected={getDefult("yapiIsyeri_DefterTipKod") == "1"}>Bilanço</option>
                            <option value="2" selected={getDefult("yapiIsyeri_DefterTipKod") == "2"}>Diğer defter</option>
                            <option value="3" selected={getDefult("yapiIsyeri_DefterTipKod") == "3"}>Deftere tabi değil</option>
                            <option value="4" selected={getDefult("yapiIsyeri_DefterTipKod") == "4"}>Kamu idaresi</option>
                        </select>

                        
                   </div>
                    <div className="text-start col-3">
                        <label className="form-label">Sirket Tur </label>
                        <select class="form-select form-select-sm" id="yapiIsyeri_SirketTurKod" name="yapiIsyeri_SirketTurKod" onChange={handleInputChange} >
                            <option  ></option>
                            <option value="1" selected={getDefult("yapiIsyeri_SirketTurKod") == "1"}>Limited</option>
                            <option value="2" selected={getDefult("yapiIsyeri_SirketTurKod") == "2"}>Anonim</option>
                            <option value="3" selected={getDefult("yapiIsyeri_SirketTurKod") == "3"}>Kollektif</option>
                            <option value="4" selected={getDefult("yapiIsyeri_SirketTurKod") == "4"}>Komandit</option>
                            <option value="5" selected={getDefult("yapiIsyeri_SirketTurKod") == "5"}>Gerçek Kişi (Geçici ve Şahıs Şirketleri)</option>
                            <option value="6" selected={getDefult("yapiIsyeri_SirketTurKod") == "6"}>Ortak Girişim</option>
                            <option value="7" selected={getDefult("yapiIsyeri_SirketTurKod") == "7"}>Adi Ortaklık</option>
                            <option value="8" selected={getDefult("yapiIsyeri_SirketTurKod") == "8"}>Yapı Kooperatifleri</option>
                            <option value="9" selected={getDefult("yapiIsyeri_SirketTurKod") == "9"}>İktisadi İşletmeler (Dernek, Vakıf vb)</option>
                        </select>
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Sigortali Sayi</label>
                        <input type="text" className="form-control form-control-sm" id="yapiIsyeri_SigortaliSayi" name="yapiIsyeri_SigortaliSayi" defaultValue={getDefult("yapiIsyeri_SigortaliSayi")} key={getDefult("yapiIsyeri_SigortaliSayi")} onChange={handleInputChange} />
                    </div>

                </div>
                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">Mersis No </label>
                        <input type="text" className="form-control form-control-sm" id="yapiIsyeri_MersisNo" name="yapiIsyeri_MersisNo" defaultValue={getDefult("yapiIsyeri_MersisNo")} key={getDefult("yapiIsyeri_MersisNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Telefon</label>
                        <input type="text" className="form-control form-control-sm" id="yapiIsyeri_Telefon" name="yapiIsyeri_Telefon" defaultValue={getDefult("yapiIsyeri_Telefon")} key={getDefult("yapiIsyeri_Telefon")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label"> EPosta</label>
                        <input type="text" className="form-control form-control-sm" id="yapiIsyeri_EPosta" name="yapiIsyeri_EPosta" defaultValue={getDefult("yapiIsyeri_EPosta")} key={getDefult("yapiIsyeri_EPosta")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Faks</label>
                        <input type="text" className="form-control form-control-sm" id="yapiIsyeri_Faks" name="yapiIsyeri_Faks" defaultValue={getDefult("yapiIsyeri_Faks")} key={getDefult("yapiIsyeri_Faks")} onChange={handleInputChange} />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">Kayit No</label>
                        <input type="text" className="form-control form-control-sm" id="yapiIsyeri_KayitNo" name="yapiIsyeri_KayitNo" defaultValue={getDefult("yapiIsyeri_KayitNo")} key={getDefult("yapiIsyeri_KayitNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">Tescil No </label>
                        <input type="text" className="form-control form-control-sm" id="yapiIsyeri_TescilNo" name="yapiIsyeri_TescilNo" defaultValue={getDefult("yapiIsyeri_TescilNo")} key={getDefult("yapiIsyeri_TescilNo")} onChange={handleInputChange} />
                    </div>
                </div>


                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">EBildirge Sözleşme Var Mi </label><br />
                        <input type="checkbox" className="form-check-input" id="yapiIsyeri_EBildirgeYetkiliBeyanVarMi" name="yapiIsyeri_EBildirgeYetkiliBeyanVarMi" defaultChecked={getDefult("yapiIsyeri_EBildirgeYetkiliBeyanVarMi") == true} key={getDefult("yapiIsyeri_EBildirgeYetkiliBeyanVarMi")} onChange={handleInputChange} key={rec.yapiBelgeDigerHusus_IlkYapiKullanmaIzinBelge} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label"> EBildirge Yetkili Unvan </label>
                        <select class="form-select form-select-sm" id="yapiIsyeri_EBildirgeYetkiliUnvanKod" name="yapiIsyeri_EBildirgeYetkiliUnvanKod" onChange={handleInputChange} >
                            <option  ></option>
                            <option value="1" selected={getDefult("yapiIsyeri_EBildirgeYetkiliUnvanKod") == "1"}>Yönetim Kurulu Başkanı</option>
                            <option value="2" selected={getDefult("yapiIsyeri_EBildirgeYetkiliUnvanKod") == "2"}>Yönetim Kurulu Başkan Vekili</option>
                            <option value="3" selected={getDefult("yapiIsyeri_EBildirgeYetkiliUnvanKod") == "3"}>Yönetim Kurulu Üyesi</option>
                            <option value="4" selected={getDefult("yapiIsyeri_EBildirgeYetkiliUnvanKod") == "4"}>Müdür</option>
                            <option value="5" selected={getDefult("yapiIsyeri_EBildirgeYetkiliUnvanKod") == "5"}>Genel Müdür</option>
                            <option value="6" selected={getDefult("yapiIsyeri_EBildirgeYetkiliUnvanKod") == "7"}>Şube Yetkilisi</option>
                            <option value="7" selected={getDefult("yapiIsyeri_EBildirgeYetkiliUnvanKod") == "12"}>Finans Müdürü</option>
                            <option value="8" selected={getDefult("yapiIsyeri_EBildirgeYetkiliUnvanKod") == "15"}>Yönetim Kurulu Üyesi Olmayan Yetkili</option>
                            <option value="9" selected={getDefult("yapiIsyeri_EBildirgeYetkiliUnvanKod") == "17"}>Müdür (Müdürler Kurulu Başkanı)</option>
                            <option value="10" selected={getDefult("yapiIsyeri_EBildirgeYetkiliUnvanKod") == "19"}>Gemi Müdürü</option>
                            <option value="11" selected={getDefult("yapiIsyeri_EBildirgeYetkiliUnvanKod") == "20"}>Temsilci</option>
                            <option value="12" selected={getDefult("yapiIsyeri_EBildirgeYetkiliUnvanKod") == "21"}>Yönetici</option>
                            <option value="13" selected={getDefult("yapiIsyeri_EBildirgeYetkiliUnvanKod") == "22"}>Şube Müdürü</option>
                            <option value="14" selected={getDefult("yapiIsyeri_EBildirgeYetkiliUnvanKod") == "24"}>Yönetmen</option>
                            <option value="15" selected={getDefult("yapiIsyeri_EBildirgeYetkiliUnvanKod") == "26"}>Şube Yönetmeni </option>
                            <option value="16" selected={getDefult("yapiIsyeri_EBildirgeYetkiliUnvanKod") == "27"}>Şube Temsilcisi</option>
                            <option value="17" selected={getDefult("yapiIsyeri_EBildirgeYetkiliUnvanKod") == "31"}>Yetkili</option>
                            <option value="18" selected={getDefult("yapiIsyeri_EBildirgeYetkiliUnvanKod") == "32"}>Konkordato Komiseri</option>
                            <option value="19" selected={getDefult("yapiIsyeri_EBildirgeYetkiliUnvanKod") == "34"}>Kayyım</option>

                        </select>

                        <input type="text" className="form-control form-control-sm" id="yapiIsyeri_EBildirgeYetkiliUnvanKod" name="yapiIsyeri_EBildirgeYetkiliUnvanKod" defaultValue={getDefult("yapiIsyeri_EBildirgeYetkiliUnvanKod")} key={getDefult("yapiIsyeri_EBildirgeYetkiliUnvanKod")} onChange={handleInputChange} />
                    </div>

                    <div className="text-start col-3">
                        <label className="form-label">EBildirge Yetkili TCKimlik No </label>
                        <input type="text" className="form-control form-control-sm" id="yapiIsyeri_EBildirgeYetkiliTCKimlikNo" name="yapiIsyeri_EBildirgeYetkiliTCKimlikNo" defaultValue={getDefult("yapiIsyeri_EBildirgeYetkiliTCKimlikNo")} key={getDefult("yapiIsyeri_EBildirgeYetkiliTCKimlikNo")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">EBildirge Yetkili Ad </label>
                        <input type="text" className="form-control form-control-sm" id="yapiIsyeri_EBildirgeYetkiliAd" name="yapiIsyeri_EBildirgeYetkiliAd" defaultValue={getDefult("yapiIsyeri_EBildirgeYetkiliAd")} key={getDefult("yapiIsyeri_EBildirgeYetkiliAd")} onChange={handleInputChange} />
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">EBildirge Yetkili Soyad</label>
                        <input type="text" className="form-control form-control-sm" id="yapiIsyeri_EBildirgeYetkiliSoyad" name="yapiIsyeri_EBildirgeYetkiliSoyad" defaultValue={getDefult("yapiIsyeri_EBildirgeYetkiliSoyad")} key={getDefult("yapiIsyeri_EBildirgeYetkiliSoyad")} onChange={handleInputChange} />
                    </div>

                    <div className="text-start col-3">
                        <label className="form-label">EBildirge Yetkili Telefon</label>
                        <input type="text" className="form-control form-control-sm" id="yapiIsyeri_EBildirgeYetkiliTelefon" name="yapiIsyeri_EBildirgeYetkiliTelefon" defaultValue={getDefult("yapiIsyeri_EBildirgeYetkiliTelefon")} key={getDefult("yapiIsyeri_EBildirgeYetkiliTelefon")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">EBildirge Yetkili Cep Telefon</label>
                        <input type="text" className="form-control form-control-sm" id="yapiIsyeri_EBildirgeYetkiliCepTelefon" name="yapiIsyeri_EBildirgeYetkiliCepTelefon" defaultValue={getDefult("yapiIsyeri_EBildirgeYetkiliCepTelefon")} key={getDefult("yapiIsyeri_EBildirgeYetkiliCepTelefon")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">yEBildirge Yetkili EPosta </label>
                        <input type="text" className="form-control form-control-sm" id="yapiIsyeri_EBildirgeYetkiliEPosta" name="yapiIsyeri_EBildirgeYetkiliEPosta" defaultValue={getDefult("yapiIsyeri_EBildirgeYetkiliEPosta")} key={getDefult("yapiIsyeri_EBildirgeYetkiliEPosta")} onChange={handleInputChange} />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="text-start col-3">
                        <label className="form-label">EBildirge Yetkili Vek Bitis Tarih</label>
                        <input type="text" className="form-control form-control-sm" id="yapiIsyeri_EBildirgeYetkiliVekBitisTarih" name="yapiIsyeri_EBildirgeYetkiliVekBitisTarih" defaultValue={getDefult("yapiIsyeri_EBildirgeYetkiliVekBitisTarih")} key={getDefult("yapiIsyeri_EBildirgeYetkiliVekBitisTarih")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">EBildirge Yetkili Acik Adres </label>
                        <input type="text" className="form-control form-control-sm" id="yapiIsyeri_EBildirgeYetkiliAcikAdres" name="yapiIsyeri_EBildirgeYetkiliAcikAdres" defaultValue={getDefult("yapiIsyeri_EBildirgeYetkiliAcikAdres")} key={getDefult("yapiIsyeri_EBildirgeYetkiliAcikAdres")} onChange={handleInputChange} />
                    </div>
                    <div className="text-start col-3">
                        <label className="form-label">EBildirge Yetkili BBK No</label><br />
                        <input type="text" className="form-control form-control-sm" id="yapiIsyeri_EBildirgeYetkiliBBKNo" name="yapiIsyeri_EBildirgeYetkiliBBKNo" defaultValue={getDefult("yapiIsyeri_EBildirgeYetkiliBBKNo")} key={getDefult("yapiIsyeri_EBildirgeYetkiliBBKNo")} onChange={handleInputChange} />
                    </div>
                </div>
            </div >

            {/* ----------------------------------------------------------------------------------------------------------------------*/}














        </div >
    );


}