import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import RevisionReqPanel from './RevisionReqPanel.js';
import ApplicationList from "./ApplicationList.js";
import * as UserPermConstants from '../User/UserPermConstants';
import { useGlobalState, logout, getDenetimSure, calcBusinessDays, isEmpty, showConfirmMessage, showErrorMessage, sendpost } from '../GlobalState.js';
import UserLabel from "../User/UserLabel.js";
import { ajaxFail } from '../GlobalState.js';


export default function DenetimPaneli(props) {

    const [appData, setData] = useState(props.appData);
    const [denetimlist, setDenetimlist] = useState(props.denetimlist);
    const [apptypeList, setApptypeList] = useState(props.apptypeList);
    const [rec, setRec] = useState(props.rec);
    const [userName, setUserName] = useGlobalState('userName');
    const [role, setRole] = useGlobalState('role');
    const [myusername, setusername] = useGlobalState('userName');
    const [mission, setMission] = useState(props.mission);
    const [commentAfterStart, setCommentAfterStart] = useState(false);


    var colname = mission.toLowerCase() + "Kontrol";


    window.scroll(0, 0)
    useEffect(() => {

    }, [])



    const getApp = () => {
        fetch("getapp", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: appData.id
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setData(value);
                })
            }
        });
    }

    const saveApp = () => {
        fetch("saveapplication", {
            method: 'POST',
            headers: {
                'Accept': 'application/json', 'Content-Type': 'application/json'
            },
            body: JSON.stringify(appData)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                window.location = "/";
            }
        });
    }

    const kontrolubaslat = () => {
        showConfirmMessage("Kontrolu başlatmak istediğinize emin misiniz?", "", "Evet", "Hayır", () => {
            fetch("kontrolubaslat", {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                body: JSON.stringify(rec.id + " " + appData.updateCount)
            }).then(async response => {
                if (!response.ok) {
                    response.text().then(value => {
                        if (value.includes("EskiVeri")) {
                            showErrorMessage("Başvuru Başka Kullanıcı Tarafından Guncellenmeştir. Listeye Dönerek Güncel Bilgilere Erişebilirsiniz");
                        }
                    })
                }
                else {
                    response.json().then(async data => {
                        if (data != null) {
                            if (data.id == -1)
                                showErrorMessage("Başvuru Başka Kullanıcı Tarafından Guncellenmeştir. Listeye Dönerek Güncel Bilgilere Erişebilirsiniz");
                            else {
                                data.Completed = data.completed;
                                data.Started = data.started;
                                setRec({ ...rec, ...data });
                                getApp();
                            }
                        }
                    });
                    props.refreshParent();

                }
            });
        });
    }

    const duzeltmeiste = () => {
        if (commentAfterStart == false) {
            showErrorMessage("Düzeltme isteği göndermek için en az bir düzeltme notu eklenmelidir")
            return;
        }

        showConfirmMessage("Başvuruyu müellife göndererek düzeltme istediğinize emin misiniz?", "", "Evet", "Hayır", () => {

            fetch("duzeltmeiste", {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                body: JSON.stringify(rec.id + " " + appData.updateCount)
            }).then(async response => {
                if (!response.ok) {
                    response.text().then(value => {
                        if (value.includes("EskiVeri")) {
                            showErrorMessage("Başvuru Başka Kullanıcı Tarafından Guncellenmeştir. Listeye Dönerek Güncel Bilgilere Erişebilirsiniz");
                        }
                    })
                }
                else {
                    response.json().then(async data => {
                        if (data != null) {
                            if (data.id == -1)
                                showErrorMessage("Başvuru Başka Kullanıcı Tarafından Guncellenmeştir. Listeye Dönerek Güncel Bilgilere Erişebilirsiniz");
                            else {
                                data.Completed = data.completed;
                                data.Started = data.started;
                                setRec({ ...rec, ...data });
                                getApp();
                            }
                        }
                    });
                    props.refreshParent();
                }
            });
        });
    }


    const getDenetimUpdateCount = () => {
        if (props.denetimlist == null || props.denetimlist.length == 0)
            return "";
        var k = props.denetimlist.find(rec => rec.activeDenetim == rec.sira);
        if (k == null)
            return "";
        var r = props.apptypeList.find(x => x.id == k.denetimID)
        if (r == null || r.duzeltmeSayi == null || r.duzeltmeSayi == 0) return "";
        return "/" + r.duzeltmeSayi;
    }

    const duzeltmeKotasıDoldumu = () => {
        if (props.denetimlist == null || props.denetimlist.length == 0)
            return false;
        var k = props.denetimlist.find(x => rec.denetimID == x.denetimID);
        if (k == null)
            return false;
        var r = props.apptypeList.find(x => x.name == k.Name)
        if (r != null && r.duzeltmeSayi != null && appData.duzeltmeSayi != null)
            if (Number(r.duzeltmeSayi) < Number(appData.duzeltmeSayi))
                return true;
        if (r == null || r.duzeltmeDeadline == null || r.duzeltmeDeadline == "" || appData.sonDuzeltmeTalebiTarihi == null) return false;
        var d = Number(r.duzeltmeDeadline);
        var diffDays = d - calcBusinessDays(new Date(Date.now()), new Date(appData.sonDuzeltmeTalebiTarihi.split(" ")[0]))
        return diffDays < 0;
    }



    const ForwardToOtherUser = () => {
        var targetkontrolor = document.getElementById("mission").value
        var message = document.getElementById("havaleMessage").value

        if (isEmpty(message) ) {
            showErrorMessage("Havale açıklaması yazılmalıdır")
            return;
        }
        if (targetkontrolor == -1) {
            showErrorMessage("Havale edilecek kontolör seçilmelidir")
            return;
        }
        var req = {appID:rec.appID,userName:rec.userName}
        req.id = rec.id;
        req.sira = appData.updateCount
        req.denetimID = targetkontrolor;
        req.message = message;


        showConfirmMessage("Başvuru " + rec.kname + " " + rec.kfamily + "'e ve daha sonra tekrar size havale edilecektir.", "", "Evet, Devam Et", "İptal", () => {
            sendpost("insertmapping", req, "myspinner", (resp) => {

                resp.json().then(async data => {
                    if (data != null) {
                        if (data.id == -1)
                            showErrorMessage("Başvuru Başka Kullanıcı Tarafından Guncellenmeştir. Listeye Dönerek Güncel Bilgilere Erişebilirsiniz");
                        else {
                            data.Completed = data.completed;
                            data.Started = data.started;
                            setRec({ ...rec, ...data });
                            props.refreshParent();
                        }
                    }
                });

            }, (resp) => {
                resp.text().then(value => {
                    showErrorMessage(value);
                })
            })


        });
    }



    const sendRevision = (rec) => {
            showConfirmMessage("Düzeltmeyi tamamlamak istediğinize emin misiniz?", "", "Evet", "Hayır", () => {
                fetch("submitrevision", {
                    method: 'POST',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'text/json' },
                    body: JSON.stringify(rec)
                }).then(async response => {
                    if (!response.ok) {
                        response.text().then(value => {

                        })
                    }
                    else {
                        response.json().then(async data => {
                            setRec({ ...rec, ...data });
                        });
                        props.refreshParent();
                    }
                });
            });
        }


        const showControlButtons = (rec) => {
            if (rec.userName != myusername) {
                return <div className="row mt-2 mb-2"><div className="col-12 mt-5 text-danger"> </div></div>
            }

            if (rec.activeDenetim != rec.sira) {
                return <div className="row mt-2 mb-2"><div className="col-12 mt-5 text-danger"> Kontrol tamamlanmıştır veya henüz kontrol sırası gelmemiştir. </div></div>
            }

            if (appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Tahakkuk_Bekleniyor ||
                appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Ucret_Bekleniyor
                || appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Onay_Bekliyor) {
                return <div className="col-12 mt-5 text-danger">Başvuru durumundan dolayı kontrol başlatılamaz</div>
            }

            {/* <button className="btn btn-warning  ms-2 btn-sm" onClick={appret} disabled={rec.Started != null || rec.Completed != null}>Geri Gönder</button>*/ }
            {/* <button className="btn btn-danger  ms-2 btn-sm" onClick={appiade}>İade Et</button>*/ }

            return <div>
                <div className="row mt-2 mb-2">
                    <div className="col-12 d-flex align-items-center" >
                        <div className="btn-group flex-wrap" role="group" aria-label="Basic example">
                        <button className="btn btn-primary border rounded-2 btn-sm flex-shrink-0" onClick={kontrolubaslat} disabled={rec.Started != null || rec.duzeltmeDurum == true}>Kontrolu Başlat</button>
                            <button className="btn btn-danger border rounded-2 ms-1 btn-sm flex-shrink-0" onClick={duzeltmeiste} disabled={rec.Started == null || rec.Completed != null}>Düzeltme Iste</button>
                            <button className="btn btn-primary  border rounded-2 btn-sm ms-1 flex-shrink-0" onClick={apponayla} disabled={rec.Started == null || rec.Completed != null}>Kontrolu Tamamla</button>
                            <button className="btn btn-primary border rounded-2 btn-sm ms-1" onClick={ForwardToOtherUser} disabled={rec.Started == null || rec.Completed != null}>Kontrolu Tamamla ve Havale Et</button>
                        </div>
                    </div>
                </div>
                <div className="row border-top  pt-3 mt-2">
                    <div className="col-md-8 col-12 mt-1" >
                        <textarea type="text" maxlength="1000" style={{ "background-color": "transparent", height: "30px" }} className="form-control form-control-sm " placeholder="Havale Açıklaması" id="havaleMessage" disabled={rec.Started == null || rec.Completed != null} />
                      </div>
                    <div className="col-md-4 col-12 mt-1" >
                        <select className="form-select form-select-sm" id="mission" name="denetimId" disabled={rec.Started == null || rec.Completed != null}>
                            <option value={-1} > Kontrolör </option>
                            {denetimlist.map(rec => <option value={rec.id }>{rec.Name} ({rec.kname} {rec.kfamily})
                            </option>)}
                        </select>
                    </div>
                    <div className="col-md-4 col-12 mt-1" >
                     </div>
                </div>
            </div>

        }







        const apponayla = () => {

            showConfirmMessage("Kontrolu tamamlamak istediğinize emin misiniz?", "", "Evet", "Hayır", () => {
                fetch("kontrolutamamla", {
                    method: 'POST',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'text/json' },
                    body: JSON.stringify(rec.id + " " + appData.updateCount)
                }).then(async response => {
                    if (!response.ok) {
                        response.text().then(value => {

                        })


                    }
                    else {
                        response.json().then(async data => {
                            if (data != null) {
                                if (data.id == -1)
                                    showErrorMessage("Başvuru Başka Kullanıcı Tarafından Guncellenmeştir. Listeye Dönerek Güncel Bilgilere Erişebilirsiniz");
                                else {
                                    data.Completed = data.completed;
                                    data.Started = data.started;
                                    setRec({ ...rec, ...data });
                                    getApp();
                                }
                            }
                        });
                        props.refreshParent();
                    }
                });
            });

        }




        const appiade = () => {
            showConfirmMessage("Başvuruyu redd edrek, müellife iade etmek istediğinize emin misiniz?", "", "Evet", "Hayır", () => setTimeout(() => {
                var comment = window.prompt("Lütfen iade etme nedenini açıklayınız")
                if (comment == null || comment.length == 0) {
                    showErrorMessage("Açıklama Boş Olamaz");
                    return;
                }

                appData.basvuruDurumu = UserPermConstants.Basvuru_Durumu_Iade_Edildi;
                appData.iadeNedeni = comment;
                appData.iadeEden = userName;
                saveApp();
            }, 100));

        }
        const commentSent = (rec) => {



        }






        return (
            <div  >
                {/*fix this has error <fieldset disabled={appData[colname] == true || rec.userName != myusername}>*/}
                <fieldset >
                    <div className="row " disabled={rec.userName != myusername}>
                        <div className="col-6">
                            <h5 className="text-primary ">{mission} Paneli </h5>
                            {rec.duzeltmeDurum == true &&
                                <span className="text-danger fw-bold">({appData.duzeltmeSayi}{getDenetimUpdateCount()} Düzeltme İstendi)</span>
                            }
                            {isEmpty(rec.Completed) &&
                                <div>
                                    <div className="text-muted" >{getDenetimSure(rec, apptypeList, appData, "Kalan Süre:")}</div>
                                </div>}
                        </div>
                        {rec.duzeltmeDurum == true && appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Duzeltme_Bekleniyor &&
                            <div className="col-6 text-success text-end fw-bold" >
                                {duzeltmeKotasıDoldumu() == true && role.indexOf(UserPermConstants.Iade_Basvuruyu_Gonderme) == -1 ?
                                    <h6 className="text-danger ">Düzeltme Sınırı Aşıldı</h6>
                                    :
                                    <button type="button" className="btn btn-sm btn-success" onClick={() => { sendRevision(rec); }} >
                                        Düzeltmeyi Gönder
                                    </button>}
                            </div>
                        }


                        {rec.Completed != null &&
                            <div className="col-6 text-success text-end fw-bold" >
                                Kontrol Tamamlanmıştır.
                            </div>}
                    </div>
                    <div className="row ">
                        <div className="col-4">
                            Havale No:{rec.id}
                        </div>
                        <div className="col-4">
                            Havale Eden: {rec.mapperUserName}
                        </div>
                        <div className="col-4">
                            Tarih: {rec.mappingDate}
                        </div>

                    </div>
                    {rec.userName == myusername && <div className="row ">
                        <div className="col-4">
                            Açıklama: {rec.message}
                        </div>
                    </div>}

                    <div className="row ">
                        <div className="col-12">
                            {isEmpty(rec.Started) == false &&
                                <span className="text-secondary ">Kontrol Başlama: {rec.Started}</span>
                            }
                            {rec.duzeltmeDurum == true &&
                                <span className="ms-2 text-secondary ">Düzeltme Talebi: {rec.duzeltmeIste}</span>
                            }
                            {isEmpty(rec.Completed) == false &&
                                <span className="ms-2 text-secondary ">Kontrol Tamamlanma: {rec.Completed}</span>
                            }
                        </div>


                    </div>
                    <div className="row mt-2 mb-2">
                        <div className="col-12">
                            {rec.Message == null ? "" : rec.mapperUserName + ": " + rec.Message}
                        </div>
                    </div>
                    <div className="row mt-2 mb-2">

                        <div className="col-12 " >
                            <RevisionReqPanel mappingRec={rec} appData={appData} user={rec.userName} setCommentAfterStart={setCommentAfterStart} />
                        </div>
                    </div>

                    {showControlButtons(rec)}


                </fieldset >
            </div >
        );
    }



