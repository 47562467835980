import React, { useState, useEffect } from 'react';
import Application from "./Application.js"
import { ajaxFail, removeTurkcLow, calcBusinessDays } from '../GlobalState.js';
import $ from 'jquery';

export default function IadeListesi(props) {
    const surebg = ["#c9f7f5", "#e1f0ff", "#fff4de", "#ffe2e5"]
    const suretxt = ["text-info", "text-primary", "text-warning", "text-danger"]
    const [applist, setApplist] = useState(null);
    const [denetimlist, setDenetimlist] = useState([]);
    var sortField = null;
    const me = <IadeListesi changeComponent={props.changeComponent} />
    useEffect(() => {
        fetchDenetimList();

    }, [])

    const myCompare = (a, b) => {
        if (a[sortField] < b[sortField]) {
            return -1;
        }
        if (a[sortField] > b[sortField]) {
            return 1;
        }
        return 0;
    }




    const fetchDenetimList = () => {
        var refreshButton = document.getElementById("refreshButton");
        if (refreshButton != null) {
            refreshButton.classList.remove("fa", "fa-refresh")
            refreshButton.classList.add("spinner-grow", "spinner-grow-sm")
        }

        fetch("denetimlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setDenetimlist(value)
                    fetchlist(value);
                })
            }
        });
    }


    const getColorIdx = (diffDays, rec) => {
        var d = denetimlist.find(x => x.id == rec.denetimID);
        var i = diffDays > d * 2 / 3 ? 0 : diffDays > d / 3 ? 1 : diffDays > 0 ? 2 : 3;
        return i;
    }


    const getDuzeltmeKalanSure = (a, denetimlist) => {
        var r = denetimlist.find(x => x.name == a.basvuruTipi)
        if (r == null || r.duzeltmeDeadline == null || r.duzeltmeDeadline == "" || a.sonDuzeltmeTalebiTarihi==null) return null;
        var d = Number(r.duzeltmeDeadline);
        var diffDays = d - calcBusinessDays(new Date(Date.now()), new Date(a.sonDuzeltmeTalebiTarihi.split(" ")[0]))
        if (diffDays > 0) return null;
        var i = diffDays > d * 2 / 3 ? 0 : diffDays > d / 3 ? 1 : diffDays > 0 ? 2 : 3;

        return <div className={"ms-2 ps-2 pe-2 text-center fw-bold " + suretxt[i]} style={{ "background-color": surebg[i], "border-radius": 5 }}>{diffDays}</div>
    }
    const getUpdateCount = (a) => {
        var r = denetimlist.find(x => x.name == a.basvuruTipi)
        if (r == null || r.duzeltmeSayi == null || r.duzeltmeSayi == 0) return null;
        return "/" + r.duzeltmeSayi;
    }






    const fetchlist = (denetimlist) => {
        fetch("applicationlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            var refreshButton = document.getElementById("refreshButton");
            if (refreshButton != null && refreshButton.classList.contains("fa-refresh") == false) {
                refreshButton.classList.remove("spinner-grow", "spinner-grow-sm")
                refreshButton.classList.add("fa", "fa-refresh")
            }
            if (!response.ok) {
                ajaxFail(response);
            }
            else {

                response.json().then(async applist => {
                    applist.forEach(r => r.gecikme = getDuzeltmeKalanSure(r, denetimlist))
                    setApplist(applist);
                });
            }
        });
    }


    const getapp = (id) => {
        fetch("getapp", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(id)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    props.changeComponent(<Application appData={data} changeComponent={props.changeComponent} backButton={me} />)
                });
            }
        });
    }



    const filterTable = (event) => {
        const target = event.target;
        const value = target.value.toLowerCase();
        $("#myTable tr").filter(function () {
            var keywords = value.split(" ");

            for (let index = 0; index < keywords.length; ++index)
                if (removeTurkcLow($(this).text()).indexOf(removeTurkcLow(keywords[index])) == -1)
                    return $(this).toggle(false);
            return $(this).toggle(true);
        });
    }

    const sortList = (fildname) => {
        sortField = fildname;
        var temp = [...applist]; //clone the array to update the component on setApplist
        temp.sort(myCompare);
        setApplist(temp);
    }


    if (applist == null)
        return <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center" ><div className="spinner-border" ></div></div>


    if (applist.length == 0)
        return <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center text-danger" >Kayıt Yok</div>



    return (<div>
        <div>
            <div className="row">
                <div className="col-12 pb-2">
                    <h5 className="text-primary">İade Edilen Başvurular</h5 >
                </div>
            </div>
            <div className="row " >
                <div className="col-12 col-md-3"></div>
                <div className="col-12 col-md-6">
                    <input className="form-control form-control-sm" id="myInput" type="text" placeholder="Arama" onKeyUp={filterTable} />

                </div>

                <div className="col-12 col-md-3 text-end pt-2">
                    <a onClick={fetchDenetimList} href="#" className="text-primary"> <i className="fa fa-refresh" id="refreshButton"></i></a>
                </div>
            </div>
            <div className="row " id="havalepanel">


                <div className="col-12">
                    <table className='table  table-sm' aria-labelledby="tabelLabel">
                        <thead>
                            <tr >


                                <th className='text-start'>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("appID")}>  Başvuru No </button>
                                </th>
                                <th className='text-start'>
                                    Taşınmaz Sahibi
                                </th>
                                <th className='text-start'>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("basvuruYapan")}>  Başvuru Yapan </button>

                                </th>
                                <th className='text-start'>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("basvuruTipi")}>  Başvuru Tipi </button>

                                </th>
                                <th className='text-start'>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("mevki")}> Mevki </button>

                                </th>
                                <th className='text-start'>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("mahalle")}>  Mahalle </button>

                                </th>
                                <th className='text-start'>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("ada")}>   Ada Parsel </button>

                                </th>
                                <th className='text-start'>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("basvuruTarihi")}>  Başvuru Tarihi </button>

                                </th>
                                <th className='text-start'>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("gecikme")}>  Geciken Süre </button>

                                </th>
                                <th className='text-start'>
                                    <button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList("duzeltmeSayi")}>  Duzeltme Sayısı </button>

                                </th>
                                <th className='text-start'></th>

                            </tr>
                        </thead>
                        <tbody id="myTable " className="text-center">
                            {applist.filter(r => r.gecikme!=null).map((rec, idx) => <tr>
                                <td> {rec.id}  </td>
                                <td>
                                    {rec.arsaSahibi}
                                </td>
                                <td> 
                                    {rec.basvuruYapan}
                                </td>
                                <td> {rec.basvuruTipi}
                                </td>
                                <td> {rec.mevki}  </td>
                                <td>  {rec.mahalle} </td>
                                <td> {rec.ada}</td>
                                <td> {rec.basvuruTarihi}</td>
                                <td>
                                    <div className={"ps-3 pe-3 p-2 text-center fw-bolder " + suretxt[getColorIdx(rec.gecikme, rec)]} style={{ "background-color": surebg[getColorIdx(rec.gecikme, rec)], "border-radius": 5 }}>{rec.gecikme}</div>
                                </td>
                                <td> {rec.duzeltmeSayi}{getUpdateCount(rec)}</td>
                                <td>
                                    <a href="#" onClick={() => getapp(rec.id)} className=" text-decoration-none "><i className="fa fa-edit align-middle" style={{ "font-size": "24px" }}></i></a>

                                </td></tr>
                            )}

                        </tbody>
                    </table>
                </div>
            </div >
        </div >
    </div>
    );


}



