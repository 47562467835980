export const Gorebildigi_Basvurulari_Editleme = "Görebildiği Başvuruları Düzenleme"
export const Yeni_Basvuru_Yapabilme = "Yeni Başvuru Yapabilme"
export const Yeni_Basvuruda_Zorunlu_Alanlari_Bos_Birakabilme = "Yeni Başvuruda Zorunlu Alanları Boş Bırakabilme"
export const Adres_Defterini_Gorme = "Adres Defterini Görme"
export const Adres_Defterini_Duzenleme = "Adres Defterini Düzenleme"
export const Kontrolor_Istatistiklerini_Gorme = "Kontrolör İstatistiklerini Görme"
export const Proje_Istatistiklerini_Gorme = "Proje İstatistiklerini Görme"
export const Muellif_Istatistiklerini_Gorme = "Müellif İstatistiklerini Görme"
export const Mekansal_Istatistikleri_Gorme = "Mekansal İstatistikleri Görme"
export const Zamansal_Istatistikleri_Gorme = "Zamansal İstatistikleri Görme"
export const Anlik_Istatistikleri_Gorme = "Anlık İstatistikleri Görme"
export const Yonetmelik_Yukleme = "Yönetmelik Yükleme"
export const Basvuru_Tipleri = "Başvuru Yönetimi"
export const Basvuru_Formu = "Başvuru Yönetimi"
export const Basvuru_Liste = "Tüm Başvuruları Görme"
export const Ekran_Yonetimi = "Ekran Yönetimi"
export const Dosyalar = "Dosya Yönetimi"
export const Dosya_Alani_Yaratma = "Dosya Alanı Yaratma"

export const Dosya_Yukleme = "Dosya Yükleme"
export const Dosya_Gizleme = "Dosya Gizleme"
export const Dosya_Degistirme_Silme = "Dosya Değiştirme / Silme"
export const Ada_Parsel_Girisi = "Ada / Parsel Girişi"
export const Surecler = "Süreç Yönetimi"
export const Kullaniciyla_Kart_Paylasimi = "Başvuru Paylaşma (Kart)"
export const Link_ile_Kart_Paylasimi = "Başvuru Paylaşma (Link)"
export const Manuel_Bildirim_Gonderme = "Manuel Bildirim Gönderme"
export const Yeni_Kullanici_Kaydetme = "Yeni Kullanıcı Kaydetme"
export const Yeni_Kullanici_Kaydinda_Zorunlu_Alanlari_Bos_Birakabilme = "Yeni Kullanıcı Kaydında Zorunlu Alanları Boş Bırakabilme"
export const Kullanici_Listesini_Gorme_Profil_Bilgilerini = "Kullanıcı Listesini Görme (Profil Bilgilerini)"
export const Kullanici_Bilgilerini_Editleme_Roller_Denetim_Yetkileri_Haric = "Kullanıcı Bilgilerini Düzenleme"
/*export const Kullanici_Rollerini_Editleme = "Kullanıcı Rol Yönetimi"*/
export const Kullanici_Denetim_Yetkilerini_Editleme = "Kullanıcı Yetkilerini Düzenleme"
export const Kullanici_Silme = "Kullanıcı Silme"
export const Basvuru_Silme = "Başvuru Silme"
export const Kullanici_Tipleri = "Kullanıcı Tipleri"
export const Kullanici_Kayit_Formu = "Kullanıcı Kayıt Formu"
export const Kullanici_Rolleri = "Kullanıcı Rolleri"
export const Is_Yuku = "İş Yükü"
export const Kullnici_Kara_Liste = "Kullanıcıyı Kara Listeye Alma"
export const Duyuru_Yonetimi = "Duyuru Yönetimi"
export const Tarife_Cetveli = "Tarife Cetveli"
export const Basvuru_Standartlari = "Başvuru Standartları"
export const Varsayilan_Takip_Ayarlari = "Varsayılan Takip Ayarları"
export const Kurum_Bilgileri = "Kurum Ayarları"
export const Api_Yonetimi = "Api Yönetimi"
export const Pop_Up_Yonetimi = "Pop-Up Yönetimi"
export const Yardim_Ekranlari = "Yardım Menusu"
export const Ayarlar_Gorme = "Ayarları Gorme"
export const Gizli_Alanlari_Gorme = "Başvuru Formunda Gizli Alanları Görme"
export const Gizli_Basvuru_Gorme = "Gizli Başvuru Turlerini Görme"
export const Basvurudan_Sonra_Sabit_Editleme = "Başvurudan Sonra Sabit Soruları Düzenleme"
export const Basvurudan_Sonra_Dinamik_Editleme = "Başvurudan Sonra Dinamik Soruları Düzenleme"
export const Sifre_Degistirme = "Kullanıcı Şifresini Değiştirme"
export const Tum_Geciken_Basvurular = "Tüm Geciken Başvuruları Görme"

export const Basvuru_Vekili_Update = "Başvuru Vekilini Değiştirme"
export const Dosyalari_Eimzaya_Acima = "İmzaya Açma (Dosyalar Sekmesinden)"

export const Basvuru_Onaylama = "Başvuru Kabul (Dosya Kontrol)"
export const Eksiklik_Girme = "Eksiklik Girme"
export const Havale_Yapma = "Havale Yapma"
export const Ruhsat_Onaylama = "Ruhsat Onaylama"
export const Imzaya_Açma = "İmzaya Açma (E-İmza Sekmesinden)"
export const Imza_Silme = "İmza Silme"
export const Imza_Veri_Girisi = "İmza Veri Girişi"
export const Imza_Vize_Kontrol = "İmza Vize Kontrol"
export const Imza_Yerlestirme = "İmza Yerleştirme"
export const Imza_Guncelleme = "İmza Güncelleme"
export const Imza_Sertifikali = "Sertifikalı Belge İndirme"


export const Basvuru_Onaylama_Kontrolorden_Sonraki_Biti_Onayi = "Başvuru Onaylama"
export const Ruhsat_Kesme = "Ruhsat Kesme"
export const Son_Imza = "Sabit İmzacı"

export const Iade_Basvuruyu_Gonderme = "İade Listesindeki Başvuruyu Gönderme"

export const Vekalet_Onay = "İmza Vekalet Onayı"

export const Kontrolor_Dosyasi_Yukleme = "Kontrolör Dosyası Yükleme"
export const Ilgili_Basvurulari_Gorme = "İlgili Başvuruları Görme"
export const Kalan_Sure_Gorme = "Kalan Kontrol Süreyi Görme"

export const Kadastral_Ayarlar = "Kadastral Veri"
export const Edevletsiz_Basvuru = "E-Devletsiz Başvuru Yapabilme"
export const EImzali_Gonderme = "E-imzalı Başvuru Gönderme"
export const Sabitlenen_Profil_Degistirme = "Sabitlenen Profil Verilerini Düzenleme"
export const MAKS_Veri_Girisi = "Maks Veri Girişi"
export const Gizli_Aktiviteleri_Gorme = "Gizli Geçmiş Aktiviteleri Görme"
export const Basvuru_Arsiv = "Başvuruyu Arşive Alma"
export const Basvuru_Arsiv_Cikar = "Başvuruyu Arşivden Çıkarma"

export const Eimza_Geri_Alma = "Silinen İmzayı Geri Alma"

export const Basvuru_Durumu_Taslak = 1
export const Basvuru_Durumu_Onay_Bekliyor = 2
export const Basvuru_Durumu_Havale_Bekliyor = 3
export const Basvuru_Durumu_Kontrol_Sirada_Bekliyor = 4
export const Basvuru_Durumu_Kontrol_Ediliyor = 5
export const Basvuru_Durumu_Duzeltme_Bekleniyor = 6
export const Basvuru_Durumu_Kontrol_Tamamlandi = 7
export const Basvuru_Durumu_Onay_Verildi = 8
export const Basvuru_Durumu_Ruhsat_Verildi = 9
export const Basvuru_Durumu_Iade_Edildi = 10
export const Basvuru_Durumu_Ucret_Bekleniyor = 11
export const Basvuru_Durumu_Tahakkuk_Bekleniyor = 12
export const Basvuru_Durumu_EIMZA_Bekleniyor = 13
export const Basvuru_Durumu_GeriGonderildi = 14
export const Basvuru_Durumu_Harc_Odeme_Kontrolu_Bekliyor = 15;

export const EIMZA_IPTAL = "İptal Edildi"
export const EIMZA_BEKLENIYOR = "İmza Bekleniyor"
export const EIMZA_TAMAM = "İmza Süreci Tamamlandı"


export const BASKA_BASVURU_GONDERME = "Başka başvuru gönderme"
export const HARC_DOSYALARI = "Harç Detaylar"